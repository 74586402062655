.sourceButton {
  /* Button resets */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  /* End button resets */

  flex: 0 1 auto;
  cursor: pointer;
}

.sourceButton:hover .sourceInner {
  background-color: var(--dig-color__secondary__surface);
}

.sourceInner {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: var(--spacing__unit--0_5);
  align-items: center;
  border: 0.5px solid var(--dig-color__border__subtle);
  border-radius: var(--spacing__unit--1);

  background: var(--dig-color__background__base);
  padding: var(--spacing__unit--1) var(--spacing__unit--2)
    var(--spacing__unit--1) var(--spacing__unit--1);
}

.iconContainer {
  width: 20px;
  height: 20px;
}

.sourceTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--dig-color__text__base);
  font-weight: 400;
}

.sourceIcon {
  width: var(--dig-spacing__dimension__16);
  height: var(--dig-spacing__dimension__16);
}
