.selectorStacksCard {
  /** Exported to be targeted by the Card's CSS */
}

.card {
  visibility: visible;
  opacity: 1;
  transition: opacity visibility var(--duration__micro)
    var(--easing__transition);
}

.card.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity visibility var(--duration__micro)
    var(--easing__transition);
}

.buttonContainer {
  display: inline-grid;
  grid-template-columns: 1fr;
}

.buttonContainer [aria-hidden='true'] {
  opacity: 0;
  pointer-events: none;
}

.buttonContainer > * {
  grid-row-start: 1;
  grid-column-start: 1;
}
