.wrapper {
  --dash-tile-card-grid__card-height: 158px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  column-gap: var(--dig-spacing__dimension__16);
  row-gap: var(--dig-spacing__dimension__16);

  /**
   * To ensure only the expected number of rows is visible, use max-height transition
   * to allow grid to shrink if all content fits on a single row while still enabling
   * transition animations when expanding/collapsing
   */
  max-height: var(--dash-tile-card-grid__card-height);
  transition: max-height var(--duration__micro) var(--easing__transition);

  /** 
   * No overflow is needed because TileCard itself will set visibility based on
   * whether it should be visible or not. This allows TileCard's focus ring to
   * remain fully visible when active.
   */
}

@media (prefers-reduced-motion) {
  .wrapper {
    transition: none;
  }
}

.expanded {
  max-height: calc(
    var(--dash-tile-card-grid__max-rows, 2) *
      (
        var(--dash-tile-card-grid__card-height) +
          var(--dig-spacing__dimension__16)
      ) - var(--dig-spacing__dimension__16)
  );
}
