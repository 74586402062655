:global(#webpack-dev-server-client-overlay) {
  display: none;
}

.container {
  /* height is set to avoid having body height set to 0 */
  height: 100vh;
  min-height: var(--full-inner-height);
  width: var(--full-inner-width);
  display: flex;
  flex-direction: column;
}
