.background {
  width: 100vw;
  height: 100vh;
  background-color: var(--dig-color__background__subtle);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.splashContainer {
  border-radius: var(--dash-radius__component);
  width: 540px;
  max-width: 100%;
  height: 440px;
  background-color: var(--dig-color__background__raised);
  box-shadow: var(--dig-utilities__boxshadow__raised);
}

.wordmarkContainer {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing__unit--3);
  padding-top: 0;
  margin-top: -64px;
}

.workmarkContainer {
  display: flex;
  align-items: center;
}

.heroContainer {
  height: 314px;
}

.heroImage {
  display: block;
  width: 100%;
  border-top-right-radius: var(--dash-radius__component);
  border-top-left-radius: var(--dash-radius__component);
}

.loginTitle {
  margin: 0;
  margin-top: var(--spacing__unit--2);
}

.callToAction {
  padding: var(--spacing__unit--4) 0 var(--spacing__unit--2) 0;
  display: flex;
  flex-direction: column;
  width: 342px;
  align-items: center;
}

.loggingInProgress {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
}

.loggingInError {
  margin-bottom: var(--spacing__unit--1);
}

button.loginButton {
  border-radius: var(--dig-radius__xlarge);
  width: 100%;
}

.container {
  position: fixed;
  bottom: var(--spacing__unit--4);
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.snackbar {
  border-radius: var(--dash-radius__level-2);
  margin-bottom: var(--spacing__unit--2);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  position: absolute;
  bottom: var(--spacing__unit--2);
  max-width: 540px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing__unit--3);
}

.copyright,
.version {
  color: var(--dig-color__text__subtle);
}
