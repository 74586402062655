.container {
  min-width: 240px;
  width: 100%;
  height: 100%;
  flex: 1 1 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--dig-color__background__base);
}

.rightPaneExpandedBright {
  background: rgba(247, 246, 245, 0.5);
}

.titleContainer {
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: var(--dig-spacing__micro__large);
  padding-bottom: var(--dig-spacing__micro__small);
  border-bottom: 1px solid var(--dig-color__border__subtle);
  gap: 16px;
}

.titleActions {
  display: flex;
  gap: 4px;
  align-items: center;
}

.titleAndChip {
  display: flex;
  align-items: center;
}

.title {
  cursor: default;
  font-weight: 500;
  margin: 0;
}

.titleChip {
  display: flex;
  margin-left: 8px;
}

.sourceCountSpacer {
  height: 3px;
  width: 3px;
  margin: 8px;
  background: var(--dig-color__text__subtle);
}

.sourceCount {
  color: var(--dig-color__text__subtle);
  margin-bottom: 2px;
}

.sourceCount:hover {
  text-decoration: underline;
  cursor: pointer;
}
