@value header from "./Header.module.css";
@value standardBottomBreakout, largeBottomBreakout from "./Contents.module.css";

.card {
  --card-border-radius: var(--dig-radius__large);
  --card-emphasis-ring-width: 3px;
  --card-background: var(--dig-color__background__subtle);
  --card-background-link-hover: var(--dig-color__opacity__surface);
  --card-background-link-active: var(--dig-color__opacity__surface--state-1);
  --loader-animation-height: 0px;

  padding: var(--dig-spacing__macro__small) 0;
  position: relative;
  isolation: isolate;
  border-radius: var(--card-border-radius);
  background: var(--card-background);
}

.card.variantOutline {
  --card-background: var(--dig-color__background__base);

  border: 1px solid var(--dig-color__border__subtle);
}

@keyframes rotate {
  0% {
    transform: translate(-50%, calc(-1 * var(--loader-animation-height) / 2))
      rotate(0deg);
  }

  50% {
    transform: translate(-50%, calc(-1 * var(--loader-animation-height) / 2))
      rotate(180deg);
  }

  100% {
    transform: translate(-50%, calc(-1 * var(--loader-animation-height) / 2))
      rotate(360deg);
  }
}

.card.loading {
  overflow: hidden;
}

.card.loading::before {
  z-index: -2;
  content: "";
  position: absolute;

  /** Calculated dynamically based on content size within the card */
  height: var(--loader-animation-height);
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-1 * var(--loader-animation-height) / 2));

  /** Creates a rotating gradient that is always centered in the card */
  background-image: conic-gradient(
    transparent 270deg,
    var(--dig-color__border__bold) 360deg
  );
  animation: rotate 2s cubic-bezier(0.33, 0.13, 0.51, 0.81) infinite;
}

.card.loading::after {
  z-index: -1;
  content: "";
  position: absolute;
  background: var(--card-background);
  inset: 1px;
  border-radius: calc(var(--card-border-radius) - 2px);
}

/** Use a ::before element for link state backgrounds so we can cleanly layer opacity colors. */
.isLink::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  overflow: hidden;
  z-index: -1;
}

.isLink:hover::before {
  background-color: var(--card-background-link-hover);
}

.isLink:active::before {
  background-color: var(--card-background-link-active);
}

.isThemed {
  --card-background: var(--dig-color__primary__surface);
  --card-background-link-hover: var(--dig-color__primary__surface--state-1);
  --card-background-link-active: var(--dig-color__primary__surface--state-2);
}

.card:has(.header) {
  padding-top: var(--dig-spacing__micro__xlarge);
}

.card:has(.standardBottomBreakout) {
  padding-bottom: var(--dig-spacing__micro__large);
}

.card:has(.largeBottomBreakout) {
  padding-bottom: var(--dig-spacing__micro__small);
}

.card:focus-visible::before {
  --card-focus-ring-offset: 0px;

  content: "";
  position: absolute;
  inset: calc(-1 * var(--card-focus-ring-offset));
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  z-index: -1;

  /** https://twitter.com/aleksliving/status/1765005317106995488/photo/1 */
  border-radius: calc(
    var(--card-border-radius) + var(--card-focus-ring-offset)
  );
}

/** What a mess... We're making requests to get these officially supported though. */
/* stylelint-disable-next-line selector-class-pattern */
:global(.dig-Card-Container--selected)::after {
  /** Right now DIG styles the selected effect with an outline */
  display: none;
}

/** Bumping specificity. Similarly, we'll look to get better support here in DIG. */
/* stylelint-disable-next-line selector-class-pattern */
:global(.dig-Card-Container--selected):global(.dig-Card-Container--selected) {
  box-shadow: 0 0 0 var(--card-emphasis-ring-width)
    var(--dig-color__secondary__base);
}

/* stylelint-disable-next-line selector-class-pattern */
.card:global(.dig-Card-Container--selected):focus-visible::before {
  /**
   * Move the focus ring outside of the card so it's not butting up against the selected ring. One
   * emphasis width to move to the outer edge of the selected ring plus a 1px gap.
   */
  --card-focus-ring-offset: calc(1px + var(--card-emphasis-ring-width));
}
