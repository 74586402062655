.container {
  border-radius: var(--dash-radius__component);
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1_5);
  background-color: var(--dig-color__background__base);
  color: var(--dig-color__text__base);
  height: 72px;
  transition: 0.4s ease height;
  position: relative;
}

.openContainer {
  gap: 0;
  animation: hideScroll 0.4s;
  height: 475px;
}

.finishedContainer {
  justify-content: center;
}

.buttonContainer {
  cursor: pointer;
  padding: var(--spacing__unit--3);
  background-color: transparent;
  border: none;
  outline: none;
  margin-bottom: 2px;
  text-align: left;
}

.buttonContainer:focus {
  border-radius: var(--dash-radius__component);
  background-color: var(--dig-color__background__subtle);
}

.successContainer {
  text-align: center;
  gap: var(--spacing__unit--1);
  padding: var(--spacing__unit--2);
  padding-bottom: var(--spacing__unit--1);
}

.animationContainer {
  flex: 1 1 auto;
}

.successText {
  margin-bottom: var(--spacing__unit--2);
  color: var(--dig-color__text__subtle);
}

.successButton {
  width: 148px;
  margin: 0 auto;
}

.animation {
  height: 150px;
  /* The animation has extra padding and we are accounting for it within negative margin */
  margin: -26px 0 -8px 0;
}

.dismissContainer {
  position: absolute;
  /* Ensure the dismiss button is displayed over the animation */
  z-index: 10;
  top: 16px;
  right: 10px;
}

.buttonContainerWithProgress {
  padding: var(--spacing__unit--2);
}

.openButtonContainer {
  padding-bottom: var(--spacing__unit--2);
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--1_5);
}

.openContainer .chevron,
.progressBar,
.dismissButtonContainer {
  display: none;
}

.chevron,
.progressBarOpen {
  display: inline-block;
  animation: fadeIn 1s;
}

.openContainer .dismissButtonContainer {
  display: inline-block;
  animation: fadeIn 1.15s;
}

.buttonText {
  flex: 1 1 auto;
  font-size: var(--dig-type__size__text__medium);
  color: var(--dig-color__text__base);
}

.listContainer {
  padding: var(--spacing__unit--1);
  padding-top: 0;
  gap: var(--spacing__unit--1);
  flex: 1 1 auto;
}

.dismissButtonContainer {
  position: absolute;
  bottom: var(--spacing__unit--2);
  left: var(--spacing__unit--2);
}

/* Shrink content for mobile */
@media (max-width: 599px) {
  .buttonText {
    font-size: var(--dig-type__size__text__medium);
    white-space: nowrap;
  }
  .rocketIcon {
    display: none;
  }
  .listContainer {
    position: absolute;
    bottom: var(--spacing__unit--5);
    top: 72px;
    overflow: auto;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hideScroll {
  from,
  to {
    overflow: hidden;
  }
}
