/**
 * Remove all UA styling from buttons and a tags
 */
:where(.reset) {
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
