.textareaWrapper {
  text-align: right;
  border: 1px solid var(--dig-color__border__bold);
  border-radius: var(--spacing__unit--1_5);
  background: var(--dig-color__background__base);

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--spacing__unit--2);
  margin-top: 0;
}

.textareaWrapper:hover {
  border-color: var(--dig-color__secondary__base);
}

.textareaWrapper:focus-within {
  outline: 2px solid var(--dig-color__primary__base);
  border-color: var(--dig-color__secondary__base);
}

.styledTextArea {
  /** Textarea resets */
  border: none;
  outline: none;
  background: none;
  width: 100%;
  resize: none;
  cursor: text;
  color: var(--dig-color__text__base);
  box-sizing: border-box;

  /** apply some DIG normalization */
  font-family: var(--type__body__standard--fontfamily);
  font-size: var(--type__body__standard--fontsize);
  font-weight: var(--type__body__standard--fontweight);
  line-height: var(--type__body__standard--lineheight);

  padding: var(--dig-spacing__micro__small) 0 var(--dig-spacing__micro__medium)
    0;
}

.styledTextArea::placeholder {
  color: var(--dig-color__text__subtle);
}

/** Overrides to normalize scrollbar */
.styledTextArea::-webkit-scrollbar-thumb {
  border-top-color: #9c9c9c;
}

.styledTextArea::-webkit-scrollbar-track {
  border-bottom-color: transparent !important;
}

.styledTextArea::-webkit-scrollbar-corner {
  border-bottom-color: transparent !important;
}

.attachmentContainer {
  text-align: left;
  padding-top: var(--spacing__unit--2);
}

.buttonContainer {
  margin: var(--dig-spacing__micro__medium) 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--dig-spacing__micro__large);
}

.styledButton {
  margin: 4px 0 10px 0;
  border-color: transparent;
  border-radius: var(--spacing__unit--0_5);
  background-color: var(--dig-color__disabled__base);
}

.styledButton:not(:disabled) {
  background-color: var(--dig-color__primary__base);
}

.styledButton:not(:disabled):hover {
  background-color: var(--color__button__primary__hover);
}

.styledButton svg {
  color: var(--dig-color__background__base);
}

.styledCancelButton {
  margin: 4px 0 10px 0;
  border-color: transparent;
  border-radius: var(--spacing__unit--0_5);
  background-color: var(--dig-color__disabled__base);
}
