.FileUploader {
  display: flex;
  align-items: center;
}

.FileUploaderButton {
  background: transparent;
  margin-bottom: var(--dig-spacing__micro__xsmall);
}

.FileUploaderButtonText {
  font-family: var(--dig-type__family__action);
  font-size: var(--dig-type__size__action__medium);
  margin-right: var(--dig-spacing__micro__xsmall);
}

.FileUploaderInput {
  display: none;
}

.FileUploaderSpinner {
  margin-right: var(--dig-spacing__micro__small);
  margin-bottom: var(--dig-spacing__micro__small);
}

.FileUploaderButtonSuccess {
  color: var(--dig-color__primary__base);
}
