.sectionContainer {
  display: flex;
  align-items: center;
  margin-bottom: var(--dig-spacing__micro__large);
  width: 100%;
  gap: var(--dig-spacing__micro__medium);
}

.divider {
  flex: 1 1 auto;
  background: var(--dig-color__border__subtle);
  height: 1px;
}
