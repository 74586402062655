.header {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: -8px; /* negate padding within logo */
  width: 100%;
}

.header > svg {
  /* Prevent logo from resizing */
  flex: 0 0 auto;
}

.stepper {
  margin-left: 40px;
  flex: 1 1 auto;
}
