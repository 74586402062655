.labelRoot {
  overflow: hidden;
}

/**
 * Ensure the polymorphic component is fully reset so a button or a don't end up taking UA styling
 */
:where(.labelRoot) {
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
}

.overflowEllipsis {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
}

.isInteractive {
  cursor: pointer;
}

.isInteractive:hover {
  text-decoration: underline;
}
