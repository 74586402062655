/**
 * Empty style so that the parent card can style itself based on the presence of a header.
 *
 * Technically you could place the header anywhere in the component and cause those styles but we're
 * gonna have faith and assume folks put it at the top.
 */
.header {
}

.revealableHeaderAction {
  opacity: 0;
  transition: opacity var(--duration__micro-fast) var(--easing__transition);
}

.headerActionRevealer {
}

/**
 * Create a actions revealer class that can control the visibility of the actions.
 *
 * This will be the outer Card component so the actions will appear when the user is interacting
 * with any piece of the card.
 */
:where(
    .headerActionRevealer:hover,
    .headerActionRevealer:focus-visible,
    .headerActionRevealer:has(:focus-visible)
  )
  .revealableHeaderAction {
  opacity: 1;
}

/** TODO: There's a DIG request out for this change. */
.digTitleXSmall {
  font-size: 14px;
  line-height: 18px;
}
