.appChip {
  align-items: center;
  background: var(--dig-color__background__base);
  border-radius: var(--dig-spacing__micro__small);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0px var(--dig-spacing__micro__large);
  height: 72px;
  cursor: pointer;
  border: none;
}

.appInfo {
  align-items: center;
  display: flex;
  gap: var(--dig-spacing__micro__small);
  padding-right: var(--dig-spacing__micro__small);
}

.grey {
  background: var(--dig-color__background__subtle);
}
