.connectorsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--dig-spacing__macro__small);
  width: 850px;
}

.connectorsContainer.isMobile {
  width: 410px;
}

.connector {
  width: 410px;
}
