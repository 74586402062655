.scrollContainer {
  min-height: 100%;
  max-height: 100%;
  display: grid;
  overflow-y: auto;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing__unit--1_5);
  position: relative;
}

.title {
  margin: 0 0 var(--spacing__unit--1) 0;
}

.subtitle {
  text-align: center;
  margin: 0;
}

.spotIcon {
  color: var(--dig-color__secondary__base);
  opacity: 0.4;
  height: 88px;
  width: 88px;
}

.titleContainer,
.disclaimerText {
  text-align: center;
}
