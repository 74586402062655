.resultContainer {
  font-family: var(--dig-type__bodyfontstack);
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  cursor: default;
  overflow-x: hidden;
  overflow: hidden;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  height: 66px;

  display: grid;
  align-items: center;
  gap: var(--dig-spacing__dimension__16);
  grid-template-columns: auto 1fr var(--dig-spacing__dimension__76); /* space for the CMD+Enter/CTRL+Enter on "search dash" result */

  color: var(--dig-color__text__base);
  background-color: var(--dig-color__background__base);
}

.resultContainer.mouseActive {
  /* because otherwise typescript has a cow that this class doesn't exist... */
}

.resultContainer.mouseActive:hover {
  background-color: var(--dig-color__opacity__surface);
}

.resultContainer.fullWidthSearchbar {
  padding: var(--dig-spacing__micro__small) var(--dig-spacing__micro__large)
    var(--dig-spacing__micro__small) var(--dig-spacing__macro__small);
  overflow: hidden;
}

.resultContainer.selected {
  background-color: var(--dig-color__opacity__surface);
}

.resultContainer.darkMode.selected {
  background-color: var(--dig-color__opacity__surface);
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--dig-spacing__dimension__32);
  height: var(--dig-spacing__dimension__32);
}

.rowAccessory {
  margin-left: auto;
  display: block;
}

.resultContainer:not(:hover) .onlyShowOnHover,
.resultContainer:hover .onlyShowNotHovered {
  display: none;
}

.metadataTitleContainer {
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
