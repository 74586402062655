.card {
  visibility: visible;
  opacity: 1;
  transition: opacity visibility var(--duration__micro)
    var(--easing__transition);
}

.card.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity visibility var(--duration__micro)
    var(--easing__transition);
}
