.sectionHeader {
  width: calc(100% - var(--spacing__unit--1) * 2);
  margin: 0 var(--spacing__unit--1);
  position: relative;
}

.sectionHeaderInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--1);
  width: 100%;
  justify-content: space-between;
}

.sectionHeaderInnerLeft,
.sectionHeaderInnerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--1);
  height: 53px; /* ensure name editor doesn't jump the page */
}

.sectionHeaderInnerLeft {
  position: relative;
  min-width: 0;
  display: flex;
}

.sectionHeaderInnerLeftTitle {
  min-width: 0;
  flex: 1 1 auto;
  cursor: default;
}

.augustRevisionTitle {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--0_5);
}

.ellipsisOverflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sectionHeaderMenu:not(.sectionHeaderVisible),
.sectionHeaderInnerRight:not(.sectionHeaderVisible) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

/* March CSS styles */
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: calc(
    var(--dash-grid-card__header-padding-left) + var(--spacing__unit--0_5)
  );
  padding-right: calc(
    var(--dash-grid-card__header-padding-right) + var(--spacing__unit--0_5)
  );
  scroll-margin-top: calc(
    var(--app-top-nav__height, 80px) + var(--dig-spacing__micro__large)
  );
}

.augustHeaderContainer {
  padding: 0 var(--dig-spacing__micro__large);
}

.headerContainer > * {
  flex-shrink: 0;
}

.titleContainer {
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
  margin-right: var(--spacing__unit--1);
  overflow: hidden;
  flex-shrink: 1;
}

.titleContainer [class^='dig']:where(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.primaryTitle {
  overflow: hidden;
}

.titleContainer:focus-within,
.primaryTitle:focus-within {
  overflow: visible;
}

.titleSizeSmall {
  font-size: var(--dig-type__size__title__small);
}

.collapseBtn svg {
  transform: rotate(0deg);
}

.expandBtn svg {
  transform: rotate(180deg);
}

.actionsContainer {
  display: flex;
  gap: 10px;
}
