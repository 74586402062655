.listItem {
  border-radius: 4px;
  border: 1px solid transparent;
  word-break: break-word;
}

.flexCenter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing__unit--2);
}

.iconExtraMargin {
  margin-right: var(--spacing__unit--1);
  height: var(--spacing__unit--4);
}

.onboardingVariantListItem {
  margin: var(--dig-spacing__micro__xsmall) 0;
  cursor: pointer;
}

.onboardingVariantListItem:hover {
  border-radius: var(--dig-spacing__micro__xsmall);
  border: 1px solid var(--dig-color__opacity__surface--state-1);
}

.connectedSubtitle {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);
}

.connectedText {
  /* Need to push text down to make it look center-aligned. */
  transform: translateY(1px);
}

.successColor {
  color: var(--dig-color__success__base--state-1);
}

.errorColor {
  color: var(--dig-color__alert__base--state-1);
}
