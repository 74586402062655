.markdownRoot {
  position: relative;
}

.markdownContainer {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: 1.5em;
  transition: max-height 0.5s ease;
  word-break: break-word;
}

.markdownContainer:hover {
  transition: max-height 0.5s ease;
  animation-direction: alternate;
}

.markdownContainerCollapsed {
  pointer-events: auto;
  user-select: text;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.markdownContainerCollapsed::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    transparent,
    var(--dig-color__background__base)
  );
  pointer-events: none; /* Ensure it doesn't block interactions */
  height: calc(1.5em * 3);
}

.showMoreContainer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.markdownContainer h1 {
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__xlarge);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--dig-type__lineheight__label__large);
}

.markdownContainer h2,
.markdownContainer h3,
.markdownContainer h4,
.markdownContainer h5,
.markdownContainer h6 {
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--dig-type__lineheight__title__large);
}

.markdownContainer p {
  padding: var(--dig-spacing__micro__small) 0;
  margin: 0;
}

.markdownContainer p:last-child {
  padding-bottom: 0;
}

.markdownContainer strong {
  font-weight: var(--dig-type__weight__strong);
}

.markdownContainer ul,
.markdownContainer ol {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 24px;
  padding-left: 0;
}

.markdownContainer ul li {
  padding-left: 0px;
}

.markdownContainer blockquote {
  margin: 0;
  padding: var(--dig-spacing__micro__medium) 0;
}

.markdownContainer table {
  width: 100%;
  border-collapse: collapse;
}

.markdownContainer th,
.markdownContainer td {
  border: 1px solid var(--dig-color__border__subtle);
  padding: var(--dig-spacing__micro__small);
}

/* pre is codeblock container */
.markdownContainer pre {
  padding: 0.5em;
  margin: 0.5em 0;
  overflow: auto;
  border-left: var(--dig-spacing__micro__xsmall) solid
    var(--dig-color__border__subtle);
  white-space: pre-wrap;
}

/* styling code text */
.markdownContainer code {
  padding: 0;
  margin: 0;
  font-family: var(--dig-type__family__monospace);
  font-weight: var(--dig-type__weight__monospace);
  line-height: var(--dig-type__lineheight__label__small);
  font-size: var(--dig-type__size__text__small);
  background-color: var(--dig-color__background__subtle);
  border: 0.5px solid var(--dig-color__border__subtle);
  border-radius: 5px;
  padding: 2px 4px;
}

/* styling code text inside code block */
.markdownContainer pre code {
  background: var(--dig-color__background__base);
  color: var(--dig-color__text__subtle);
  font-weight: var(--dig-type__weight__base);
  border: unset;
  border-radius: inherit;
  padding: inherit;
}
