div.container {
  border-radius: var(--dash-radius__component);
  border: 1px solid var(--dig-color__border__subtle);
}

.assetContainer {
  background-color: var(--dig-color__background__subtle);
  border-top-right-radius: var(--dash-radius__component);
  border-top-left-radius: var(--dash-radius__component);
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: var(--spacing__unit--2);
}

.assetContainer img {
  width: 100%;
  margin-bottom: -48px;
}

.contentContainer {
  padding: var(--spacing__unit--3);
  padding-top: var(--spacing__unit--1);
}

.message {
  color: var(--dig-color__text__subtle);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  padding-top: var(--spacing__unit--1);
}

@media (max-width: 863px) and (min-width: 599px) {
  div.container {
    display: flex;
    flex-direction: row-reverse;
  }

  .contentContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .assetContainer {
    flex: 0 0 50%;
    background-color: var(--dig-color__background__subtle);
    border-bottom-right-radius: var(--dash-radius__component);
    border-top-right-radius: var(--dash-radius__component);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
