/* Placeholder ancestor class for responsive size mode. */
.small {
  z-index: auto;
}

.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 1024px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: row;
  padding: var(--spacing__unit--2);
  gap: var(--spacing__unit--2);
  min-height: 0;
  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  width: 100%;
}

.containerV2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: var(--spacing__unit--2) var(--spacing__unit--3);
  width: 100%;
}

.small .container {
  flex-direction: column;
  height: unset;
  max-width: 600px;
  justify-content: flex-start;
}

.header {
  display: flex;
  flex-direction: column;
  max-width: 380px;
  min-width: 380px;
  gap: var(--spacing__unit--2);
}

.headerBottomSpacing {
  padding-bottom: var(--dig-spacing__macro__large);
}

.body {
  max-width: 520px;
  display: flex;
  min-width: 0;
  width: 100%;
  padding-right: var(--spacing__unit--1);
  flex-direction: column;
  gap: var(--spacing__unit--2);
}

.bodyV2 {
  display: flex;
  min-width: 0;
  width: 100%;
  padding-right: var(--spacing__unit--1);
  flex-direction: column;
  gap: var(--spacing__unit--2);
}

.small .header,
.small .body {
  max-width: 100%;
  min-width: 100%;
}

.small .body {
  overflow-y: visible;
}

.publicPreviewFooter {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: var(--dig-spacing__macro__small) var(--dig-spacing__macro__medium)
    var(--dig-spacing__macro__small) var(--dig-spacing__macro__medium);
  justify-content: space-between;
  align-items: center;

  background: var(--dig-color__secondary__base);
}

.publicPreviewFooter .buttonsContainer {
  display: flex;
  align-items: flex-start;
  gap: var(--dig-spacing__dimension__10);
}

.addBoxWrapper {
  margin: var(--dash-grid-card__row_gap) var(--dig-spacing__dimension__16);
}
