.table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  table-layout: fixed;
  width: fit-content;
  margin: 46px 25px 30px 50px;
  position: relative;
}

.tableSelection *::selection {
  background-color: transparent;
}

.tableSelected {
}

.tableCell {
  border: 1px solid var(--dig-color__border__base);
  width: 75px;
  vertical-align: top;
  text-align: start;
  padding: var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__small);
  outline: none;
}

.table tr:first-of-type .tableCell {
  /* we want the first row to always be a header row rather than letting user choose an arbitrary row */
  background-color: var(--dig-color__background__subtle);
}

.tableCellHeader {
}

.tableCellSelected {
  background-color: var(--dig-color__highlight__blue);
}

.tableCellPrimarySelected {
}

.tableScrollableWrapper {
  overflow-x: auto;
  position: relative;
  /* margin needed to accomodate for margin of the controls (negating .table's margin) */
  margin: 0 -50px 0 -50px;
}
