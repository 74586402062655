.iconRoot :is(svg, img) {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.iconSmall {
  width: 16px;
  height: 16px;
}

.iconMedium {
  width: 20px;
  height: 20px;
}
