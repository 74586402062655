.Divider {
  width: 100%;
  height: 1px;
  margin: 6px 0;
}

.SectionLabel {
  padding-top: 12px;
  padding-bottom: 8px;
}

.SourcesEmpty {
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__large);
}

.SearchTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: var(--dig-spacing__micro__small) 0px;
}

.SourcesEmptyMargin {
  margin: 0 var(--dig-spacing__micro__xlarge);
}

.SourceRows {
  background: var(--dig-color__background__base);
}

.SourceRowsBordered > div {
  border-radius: var(--dig-radius__large);
  border: 1px solid var(--dig-color__border__subtle);
  margin-bottom: var(--dig-spacing__micro__small);
}

.SourceRowCloseIcon {
  color: var(--dig-color__text__subtle);
}
