.addButton {
  height: 37px;
}

.addButton > span {
  height: 100%;
}

.addButton.emphasized {
  background-color: var(--dig-color__primary__base);
}

.addButton.emphasized:hover,
.addButton.emphasized:hover *:before,
.addButton.emphasized.menuIsOpen,
.addButton.emphasized.menuIsOpen *:before {
  background-color: var(--dig-color__primary__base--state-1);
}

.addItemButtonText {
  margin-left: 4px;
}

.addItemMenuContainer > *,
.addItemMenuContainer > * > * {
  width: 100%;
}
