.card {
  visibility: visible;
  opacity: 1;
  transition: opacity visibility var(--duration__micro)
    var(--easing__transition);
}

.card.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity visibility var(--duration__micro)
    var(--easing__transition);
}

@media (prefers-reduced-motion) {
  .card {
    transition: none;
  }
}

.accessoryButton {
  position: absolute;
  top: var(--dig-spacing__macro__xsmall);
  right: var(--dig-spacing__macro__xsmall);
  z-index: -1;
}

.accessoryButton.interactiveAccessory {
  z-index: 2;
}

.title {
  /**
   * Set a minimum height equal to the number of lines before truncation
   * to enforce a consistent height for all cards, and allow metadata to
   * bottom align in the card
   */
  word-break: break-word;
  min-height: calc(
    max(2, var(--dash-tile-card__title__linecount, 1)) *
      var(--dig-type__lineheight__text__medium)
  );
}
