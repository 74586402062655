.headerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1_5);
  padding-left: var(--dash-grid-card__header-padding-left);
  padding-right: var(--dash-grid-card__header-padding-right);
  cursor: pointer;
}

.headerContainer [class^='dig']:where(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.stackHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1);
}

.rightHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1_5);
}

.hiddenHeaderContent {
  display: none;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--0_5);
}

.shareState {
  display: flex;
  gap: var(--spacing__unit--0_5);
}

.titleContainer {
  width: 100%;
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
  margin: 0px;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  word-break: break-word;
}
