.container {
  display: flex;
  gap: var(--spacing__unit--1);
  align-items: center;
}

.container.isMobile {
  margin-top: var(--spacing__unit--1);
  flex-wrap: wrap;
}

.customTooltip {
  position: relative;
  padding: var(--spacing__unit--2);
}

.dismissButton {
  position: absolute;
  top: var(--spacing__unit--1);
  right: var(--spacing__unit--1);
  color: var(--dig-color__text__subtle);
}
