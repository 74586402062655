.roundedBorder,
.menuRoundedBorder > * {
  margin-right: var(--spacing__unit--1);
}

.pointerEventReset {
  pointer-events: auto;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 4px;
  color: var(--dig-color__text__subtle);
}

.sideIcon {
  width: 16px;
  height: 16px;
  translate: -2px;
}

/* No choice but to override the DIG style here. */
.outerWrapper :global(.dig-TextInputContainer):has(input:focus),
.outerWrapper :global(.dig-TextInputContainer):has(input:focus-visible) {
  box-shadow: none;
}

.outerWrapper {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  padding: var(--dig-spacing__micro__small);
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1);
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__xlarge)
    var(--dig-spacing__micro__small) var(--dig-spacing__micro__xlarge);
  border-radius: 2px 12px 12px 12px;
  background: var(--dig-color__background__base);
  border: 1.25px solid var(--dig-color__border__subtle);
  pointer-events: auto;
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.descriptionContainer:hover {
  background: var(--dig-color__background__subtle);
}

.actionButtonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing__unit--1);
}

.descriptionContainer .actionButtonsContainer {
  visibility: hidden;
}

.descriptionContainer:hover .actionButtonsContainer {
  visibility: visible;
}

.textInputWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large);
  border-radius: 2px 12px 12px 12px;
  background: var(--dig-color__background__base);
  border: 1.25px solid var(--dig-color__border__subtle);
  width: 100%;
}

.lowerContainer {
  display: flex;
  gap: var(--spacing__unit--2);
  justify-content: space-between;
}

.footnoteContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
}

.contentText {
  word-break: break-word;
}

.summaryQuote {
  padding: 0 12px;
  border-left: 1px solid black;
  margin: 5px 4px;
  white-space: normal;
}

.summaryQuote .summaryQuoteText {
  padding: 0;
  margin: 0;
  font-style: italic;
}
