.blurContainer {
  position: relative;
  transition: filter 0.3s ease-in-out;
}

.blurred-low {
  filter: blur(3px);
}

.blurred-medium {
  filter: blur(6.5px);
}

.blurred-high {
  filter: blur(25px);
}

.interactionBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: not-allowed;
}

.noSelect {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
