.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--dash-grid-card__header-padding-left);
  padding-right: var(--dash-grid-card__header-padding-right);
}

.augustRevisionSectionContainer {
  background: none;
  gap: 0;
  padding-left: 0;
  padding-right: 0;
}
