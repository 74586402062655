.container {
  background-color: var(--dig-color__background__base);
  border-top: 1px solid var(--dig-color__background__base);
  box-shadow: 0 0 12px 0 #0000001a;
  padding: 0 var(--dig-spacing__micro__xlarge);
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__large);
  height: 100%;
}

.text {
  color: var(--dig-color__text__subtle);
  line-height: var(--dig-type__lineheight__text__large);
}
