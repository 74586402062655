.calendarChipContainer {
  padding: var(--spacing__unit--2) 0 var(--spacing__unit--3) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1);
}

.calendarChip {
  flex: 0 0 72px;
  max-width: none;
}

.calendarChip:hover {
  background-color: var(--dig-color__opacity__surface);
}

.calendarSkeletonChip {
  width: 100%;
}
