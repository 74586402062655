.allStacksList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1_5);
}

.fullWidth {
  width: 100%;
  justify-content: space-between;
}

.allStacksListItem {
  position: relative;
}

.actionMenuComponent {
  position: relative;
  z-index: 2;
  margin-left: calc(-1 * var(--dig-spacing__micro__small));
}

.accessIcon {
  display: flex;
  align-items: center;
}
