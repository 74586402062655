div.container {
  padding-top: var(--spacing__unit--2);
  padding-bottom: var(--spacing__unit--2);
  background-color: var(--dig-color__primary__surface);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding-left: var(--spacing__unit--1_5);
}

.headerContainer h2 {
  margin: 0;
}

.appCardTitle {
  padding: var(--spacing__unit--1);
}

.iconContainer {
  padding: var(--spacing__unit--1_5) var(--spacing__unit--1);
}

.contentContainer {
  padding: var(--spacing__unit--1_5);
  padding-top: 0;
}

.suggestedChip {
  width: 100%;
  margin-bottom: var(--spacing__unit--1);
  height: 46px;
  max-width: none;
}

.suggestedChip:hover {
  background-color: var(--dig-color__opacity__surface);
}

.suggestedSkeletonChip {
  margin-bottom: var(--spacing__unit--1);
}

.allAppsButton {
  margin-top: var(--spacing__unit--1);
}
