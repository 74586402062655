.privacyToggleWrapper {
  position: fixed;
  right: 20px;
  bottom: 16px;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0px 4px 12px 0px
    var(--dig-color__shadow__elevated, rgba(0, 0, 0, 0.1));
}

.privacyToggle {
  width: var(--dig-spacing__macro__large, 40px);
  height: var(--dig-spacing__macro__large, 40px);
  background-color: var(--dig-color__background__base);
}
