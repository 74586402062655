.augustRevision {
  border: 1px solid var(--dig-color__border__subtle);
  --dash-radius__component: var(--dig-radius__large);
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1_5);
}

.defaultPadding {
  padding-left: var(--spacing__unit--1);
  padding-right: var(--spacing__unit--1);
}

.textInputContainer {
  margin-left: var(--spacing__unit--0_5);
  margin-right: var(--spacing__unit--0_5);
  margin-bottom: var(--spacing__unit--0_5);
}

.sharingContactsContainer {
  display: flex;
  flex-direction: column;
}

.sharingContactContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  cursor: pointer;
  border-radius: var(--dash-radius__component);
}

.sharingContactContainer:hover,
.sharingContactContainer:focus {
  background-color: var(--dig-color__opacity__surface);
}

.avatarNameContainer {
  display: flex;
  gap: var(--spacing__unit--1_5);
  align-items: center;
}

.rightDecoration {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.membersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing__unit--1_5);
}

.membersContainer > * {
  flex-shrink: 0;
}

.memberText {
  flex-shrink: 1;
}

.membersContainer > :first-child {
  margin-right: auto;
}
