.emojiPickerContainer {
  z-index: 10;
  position: absolute;
  top: 60px;
  left: var(--spacing__unit--2);
}

.emojiPickerContainerAugRev {
  z-index: 10;
  position: absolute;
  top: 54px;
}
