.rootContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.leftPanel {
  width: 50%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  background-color: var(--dig-color__background__base);
  padding: var(--dig-spacing__macro__xlarge) 106px 0 106px;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: scroll;
}

:global(.digBreakpointMedium) .leftPanel,
:global(.digBreakpointSmall) .leftPanel,
:global(.digBreakpointXsmall) .leftPanel {
  padding: var(--dig-spacing__macro__xlarge) 66px 0 66px;
}

.title {
  margin-top: 0;
}

.rightPanel {
  width: 50%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-unit-15, --spacing__unit--1_5);
  background-color: var(--dig-color__background__subtle);
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 50px 66px 100px 66px;
  scroll-behavior: smooth;
}

/* Shared by single-step onboarding variants */
.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__macro__small);
  width: 100%;
}

.roundedCorners {
  border-radius: var(--spacing__unit--2);
}

.continueCta {
  --dig-color__primary-cta__base: var(--dig-color__selection__base);
  --dig-color__primary-cta__base--state-1: var(--dig-color__selection__base);
  --dig-color__primary-cta__base--state-2: var(--dig-color__selection__base);
  background-color: var(--dig-color__selection__base);
}

.privacyFooter {
  display: flex;
  color: var(--dig-color__text__subtle);
  margin-bottom: 64px;
}

.bannerPrivacyPolicy {
  padding: var(--dig-spacing__micro__large);
  border-radius: var(--spacing__unit--2);
  background-color: var(--dig-color__primary__on-base);
}

.bannerPrivacyPolicy:global(.dig-Banner--basic.dig-Banner--withIcon) {
  padding-left: var(--dig-spacing__micro__large);
}

/* Mobile styling */
.isMobile {
  /* additional class added to .rootContainer when mobile size */
  display: flex;
}

.isMobile.rootContainer {
  flex-direction: column;
}

.isMobile .leftPanel,
.isMobile .rightPanel {
  height: auto;
  width: 100%;
  gap: var(--dig-spacing__macro__small);
  padding: var(--dig-spacing__micro__large) var(--spacing__unit--2_5) 0
    var(--spacing__unit--2_5);
}

.isMobile .rightPanel {
  /* leave enough space for fixed button on bottom */
  padding-bottom: 100px;
}

.isMobile .title {
  margin-bottom: var(--dig-spacing__micro__medium);
}

.isMobile .privacyFooter {
  display: none;
}

.isMobile .buttonsContainer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  margin-left: -20px;
}

.isMobile .skipCta {
  width: 100%;
}
