.FormatToolbar {
  display: flex;
  align-items: center;
  background: var(--dig-color__background__base);
  vertical-align: middle;
}

.FormatToolbarInverse {
  display: flex;
  align-items: center;
  background: var(--color__inverse__faint__background);
  vertical-align: middle;
}

.FormatButton {
  padding: 4px;
  box-sizing: content-box;
}

.FormatButtonInverse {
  color: var(--color__inverse__standard__text);
  padding: 4px;
  box-sizing: content-box;
}

.Divider {
  width: 1px;
  height: 32px;
  background: var(--color__inverse__standard__border);
  margin: 0 8px;
}

.FormatButtonActive {
  background: rgb(255, 255, 255, 0.25);
}

.FormatButtonActiveDark {
  background: rgb(0, 0, 0, 0.25);
}
