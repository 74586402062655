.ChangelogButtonRow {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  border-radius: var(--dig-radius__medium);
}

.ChangelogButtonRow:hover {
  border-radius: var(--dig-radius__medium);
  background-color: var(--dig-color__opacity__surface);
  color: var(--dig-color__text__base);
  cursor: pointer;
}

.ChangelogButtonContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 16px;
  padding: var(--dig-spacing__micro__small);
  justify-content: center;
  gap: 8px;
}

.ChangelogButtonIcon {
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
}

.ChangelogButtonTitle {
  flex: 1 0 0;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 16px;
  line-height: var(--dig-type__lineheight__label__small);
  color: var(--dig-color__text__subtle);
  margin-bottom: 2px;
}
