.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 285px;
  margin: 0 auto;
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__micro__large)
    var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large);
  gap: var(--dig-spacing__dimension__6);

  border-radius: var(--dig-radius__medium);
  border: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
}

.buttonsContainer {
  display: flex;
  padding: var(--dig-spacing__micro__small) 0 var(--dig-spacing__micro__xsmall)
    0;
  align-items: center;
  gap: var(--dig-spacing__dimension__6);
  align-self: stretch;
}
