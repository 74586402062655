.favIcon {
  height: var(--spacing__unit--2);
  width: var(--spacing__unit--2);
  flex-shrink: 0;
}

.favIconSpacing {
  padding: var(--spacing__unit--0_5);
  box-sizing: content-box;
}

.favIconLarge {
  height: 24px !important;
  width: 24px !important;
}

.favIconXLarge {
  height: 28px !important;
  width: 28px !important;
}

.digIconFaviconStandin {
  min-height: var(--spacing__unit--3);
  min-width: var(--spacing__unit--3);
  color: var(--dig-color__text__base);
  flex-shrink: 0;
}
