.droppableEmptySection {
  position: relative;
}

.droppableEmptySectionSpacing {
  padding: calc(var(--dash-grid__row_gap, var(--spacing__unit--2)) / 2) 0;
}

.augustRevisionDroppableEmptySectionSpacing {
  padding-top: var(--dig-spacing__micro__xsmall);
  padding-bottom: var(--dig-spacing__micro__xsmall);
}

.droppableEmptySectionNoSpacing {
  margin: -8px 0;
}

.droppableNewSectionWhileDraggingOver {
  height: 72px;
  border-radius: 16px;
  border: 2px solid var(--dig-color__primary__base);
  background: var(--dig-color__primary__on-base);
}

.droppableBottomSection {
  height: 16px;
}

.draggableSuggestion {
  position: relative;
  user-select: none;
  cursor: grab;
  box-sizing: border-box;
  pointer-events: all;
  -webkit-user-drag: element;
}

.draggableButton {
  cursor: grab;
  pointer-events: all;
}

.draggableButton:active {
  cursor: grabbing;
}

.dragDropSectionItem {
  position: relative;
}

.dragPreview {
  background-color: var(--dig-color__background__subtle);
  border-radius: var(--dash-radius__level-3);
  pointer-events: none;
  z-index: 100;
  cursor: grab;
}

.isDragging {
  height: 24px;
}

.isNotDragging {
  height: 16px;
}

.augustRevisionDroppableSectionDivider {
  background-color: var(--dig-color__border__subtle);
  height: 1px;
  border: none;
  position: absolute;
  width: calc(100% - var(--dig-spacing__micro__large) * 2);
  margin: 0;
  left: var(--dig-spacing__micro__large);
}

.sectionDropTargetHovering {
  border: 2px solid var(--dig-color__primary__base);
  border-radius: var(--dash-radius__level-3);
}
