.listItem {
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
}

.flexCenter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing__unit--2);
}

.iconExtraMargin {
  margin-right: var(--spacing__unit--1);
  height: var(--spacing__unit--4);
}
