.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.icons {
  align-self: center;
}

.noConnectorsContent {
  margin: var(--spacing__unit--3) auto var(--spacing__unit--5);
  max-width: 360px;
  padding: 0 var(--spacing__unit--2);
}
