.magicFlagTooltip {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: var(--spacing__unit--1);
  padding: var(--spacing__unit--1) 0;
}

.magicFlagTooltip > * {
  display: flex;
}

.magicFlagTooltip > *:nth-child(odd) {
  justify-content: left;
}

.magicFlagTooltip > *:nth-child(even) {
  justify-content: right;
}

.magicFlagIcon {
  color: var(--dig-color__alert__base--state-2);
}
