.coloredFavicon {
  height: 86px;
  width: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.17);
  box-shadow: 0px 1.529px 3.059px 0px rgba(99, 112, 131, 0.12);
  background-color: #f7f5f2;
}
