.container {
  display: flex;
  align-items: center;

  min-width: 100%;
  width: 0;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.container.isMobile {
  flex-wrap: wrap;
}

.container > div {
  display: flex;
  align-items: center;
  position: relative;
}

.container > div:not(:last-child)::after {
  content: '·';
  margin-left: var(--spacing__unit--0_5); /* spacing before the dot */
  margin-right: var(--spacing__unit--0_5); /* spacing after the dot */
}
