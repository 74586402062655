.endCapHandle {
  position: absolute;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--dig-color__background__subtle);
  cursor: pointer;
}

.endCapHandle:hover {
  background: var(--dig-color__primary__base);
  color: var(--dig-color__background__base);
}

.endCapHandle.addColumn {
  width: 21px; /* 20px + 1px border */
  flex-direction: column;
  border-radius: 0px 8px 8px 0px;
  border-left: 1px solid var(--dig-color__border__base);
  margin-left: -1px;
}

.endCapHandle.addRow {
  height: 20px;
  flex-direction: row;
  border-radius: 0px 0px 8px 8px;
}

.dividerHandle {
  --divider-zone-width-radius: 2px;
  position: absolute;
  color: var(--dig-color__primary__base);
  display: flex;
  align-items: center;
  gap: 2px;
  z-index: 1;
}

.columnDividerHandle {
  width: calc(var(--divider-zone-width-radius) * 2 + 1px);
  top: 0;
  margin-top: -22px; /* 20px + 2px gap */
  flex-direction: column;
}

.rowDividerHandle {
  height: calc(var(--divider-zone-width-radius) * 2 + 1px);
  left: 0;
  margin-left: -22px; /* 20px + 2px gap */
  flex-direction: row;
}

.dividerHandle .addButtonContainer {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.columnDividerHandle .addButtonContainer {
}

.rowDividerHandle .addButtonContainer {
}

.dividerHandle .dot {
  background: var(--dig-color__border__base);
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
}

.dividerHandle:has(.addButtonContainer:hover) .dot {
  display: none;
}

.dividerHandle .addButton {
  display: none;
  width: 20px;
  height: 20px;
  background: var(--dig-color__primary__base);
  color: var(--dig-color__background__base);
}

.dividerHandle:has(.addButtonContainer:hover) .addButton {
  display: block;
}

.dividerHandle .addMarkerLine {
  flex: 1 1 auto;
  background: var(--dig-color__primary__base);
  display: none;
}

.dividerHandle:has(.addButtonContainer:hover) .addMarkerLine {
  display: block;
}

.columnDividerHandle .addMarkerLine {
  width: 3px;
  height: 100%;
}

.rowDividerHandle .addMarkerLine {
  width: 100%;
  height: 3px;
}

.actionHandle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dig-color__background__subtle);
  border: none;
  cursor: pointer;
}

.actionHandle .icon {
  display: none;
  flex: 0 0 auto;
}

.actionHandle:hover {
  background: var(--dig-color__opacity__surface--state-2);
}

.actionHandle:hover .icon {
  display: block;
  color: var(--dig-color__border__bold);
}

.actionHandle.rowActionHandle {
  border-bottom: 1px solid var(--dig-color__border__base);
  margin-top: 0.5px;
}

.actionHandle.columnActionHandle {
  border-right: 1px solid var(--dig-color__border__base);
  margin-left: 0.5px;
}

.actionHandle.columnActionHandle .icon {
  transform: rotate(-90deg);
}
