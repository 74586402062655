.userNameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--1);
}

.navigationAvatarFooter {
  border-top: 1px solid var(--dig-color__border__subtle);
  padding: var(--spacing__unit--1) var(--spacing__unit--2);
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.footerLink {
  cursor: pointer;
  color: var(--dig-color__text__subtle);
  transition: var(--duration__micro) var(--easing__transition) color;
}

.footerLink:hover {
  color: var(--dig-color__text__base);
}
