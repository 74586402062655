.spotlightContainer {
  width: 100%;
  height: 300px;
  background-color: var(--dig-color__primary__surface);
  border-radius: var(--RadiusXLarge);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.spotlightContainer img {
  width: 100%;
  animation: fadeIn 0.5s;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  height: 72px;
}

.pageIndex {
  color: var(--dig-color__text__subtle);
  flex: 1 1 auto;
}

.dismissButton {
  position: absolute;
  top: var(--spacing__unit--1);
  right: var(--spacing__unit--1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
