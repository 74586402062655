.modalSpotlight {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  border-top-right-radius: var(--dig-spacing__micro__small);
  border-top-left-radius: var(--dig-spacing__micro__small);
}

.spotlightContainer {
  align-items: center;
  background: #8bb6fe;
  display: flex;
  justify-content: center;
  padding: 46px 0 44px;
  width: 600px;
}

:global(.digBreakpointXsmall) .spotlightContainer {
  border-top-left-radius: var(--dig-spacing__micro__small);
  border-top-right-radius: var(--dig-spacing__micro__small);
  width: auto;
  padding: 33px 36px 35px;
}

.spotlightContent {
  background: var(--dig-color__background__base);
  border-radius: 20px;
  height: 247px;
  width: 386px;
  padding: var(--dig-spacing__dimension__4) var(--dig-spacing__dimension__8);
}

:global(.digBreakpointXsmall) .spotlightContent {
  max-width: 304px;
  height: 194px;
  padding-bottom: 16px;
}

.modalTitle {
  padding-bottom: calc(var(--spacing__base_unit) * 2);
}

.modalText {
  margin-bottom: calc(var(--spacing__base_unit) * 3);
}

.grayBar {
  width: 264px;
  height: 9px;
  border-radius: var(--dig-radius__medium);
  background-color: var(--color__black--15);
}

:global(.digBreakpointXsmall) .grayBar {
  width: 65%;
}

.frownIcon {
  width: 111px;
  height: 111px;
}

:global(.digBreakpointXsmall) .frownIcon {
  width: 94px;
  height: 94px;
}

.noResults {
  margin-top: var(--dig-spacing__dimension__8);
}

:global(.digBreakpointXsmall) .noResults {
  margin: 0;
}

.featureContainer {
  display: flex;
  padding: calc(var(--spacing__base_unit) * 1.5)
    calc(var(--spacing__base_unit) * 3);
}

.featureIcon {
  width: 36px;
  height: 36px;
  margin-right: calc(var(--spacing__base_unit) * 2);
}

.featureTitle {
  margin-right: var(--spacing__base_unit);
}

:global(.digBreakpointXsmall) .featureTitle {
  margin-bottom: var(--spacing__base_unit);
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

:global(.digBreakpointXsmall) .modalFooter {
  flex-direction: column;
  gap: var(--spacing__base_unit);
}

button.skipButton {
  font-family: 'Atlas Grotesk Web', sans-serif;
  font-size: var(--dig-type__size__text__medium);
}

:global(.digBreakpointXsmall) button.skipButton {
  order: 2;
}

button.addAppsButton {
  border-radius: var(--dig-radius__xlarge);
}

:global(.digBreakpointXsmall) button.addAppsButton {
  order: 1;
}
