.SourcesFromSettingsConversationPane {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  padding-top: 12px;
  align-items: flex-start;
  flex: 3 0 0;
  align-self: stretch;
  background: rgba(247, 246, 245, 0.5);
  height: 590px;
  border-radius: 0 0 var(--dig-radius__large) var(--dig-radius__large);
}

.SourcesFromSettingsConversationPaneMobile {
  height: calc(100% - 12px);
}

.LoadingMessageRow {
  border-radius: var(--dig-radius__large);
  display: flex;
  gap: 12px;
  width: max-content;
  color: var(--dig-color__text__base);
}

@keyframes bubbleAppear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ConversationMessageRowMessageAnimation {
  opacity: 0;
  animation: bubbleAppear 0.4s ease forwards;
}

.ConversationMessageRowMessageActionIconContainer {
  background: var(--dig-color__background__base);
  border-radius: 50%;
  width: 24px;
  border: 1px solid var(--dig-color__border__subtle);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.ConversationMessageRowMessageActionIcon {
  flex: 0 0 auto;
  color: var(--dig-color__text__subtle);
}
