.spellingSuggestionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: var(--dig-spacing__macro__xsmall);
}

.spellingSuggestionText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--dig-color__text__subtle);
}

.spellingSuggestionButton {
  cursor: pointer;
  text-decoration: underline;
  color: var(--dig-color__text__base);
  margin-left: var(--dig-spacing__micro__xsmall);
  font-weight: 500;

  transition: color 0.2s ease-in-out;
}

.spellingSuggestionButton:hover {
  color: var(--dig-color__text__subtle);
}
