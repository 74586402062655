.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  background: var(--dig-color__background__base);
  color: var(--dig-color__text__base);
  width: calc(100% - var(--global-nav-collapsed-width));
  max-width: 700px;
  right: 0;
  max-height: 100vh;
  height: var(--event-log-container-height);
  z-index: 999;
  border-left: 1px solid var(--dig-color__border__subtle);
  border-top: 1px solid var(--dig-color__border__subtle);
}

.container.flyoutOpen {
  right: unset;
  left: 0;
}

.dragHandleContainer {
  text-align: center;
}

.dragHandle {
  cursor: ns-resize;
}

.header {
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  align-items: center;
  padding: 0 10px;
}

.headerActions {
  display: flex;
  gap: var(--dig-spacing__dimension__4);
  flex-direction: row;
  justify-content: flex-end;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.listContainer {
  flex: 1 1 auto;
  overflow: auto;
}

.listTitle {
  margin-top: var(--spacing__unit--1);
  margin-bottom: var(--spacing__unit--1);
}

.minimized {
  height: var(--event-log-minimum-height);
  width: 250px;
}

.minimized .header {
  grid-template-columns: 1fr 70px;
}

.minimized .dragHandleContainer {
  display: none;
}
