.SelectionToolbar {
  position: absolute;
  background: var(--color__inverse__elevated__background);
  box-shadow: var(--boxshadow__elevation1);
  padding: 1px 6px;
  display: flex;
  gap: 2px;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.NoSelectionToolbar {
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--color__inverse__elevated__background);
  box-shadow: var(--boxshadow__elevation1);
  padding: 1px 8px;
  display: flex;
  gap: 2px;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.SelectionHidden {
  opacity: 0;
  pointer-events: none;
}

.SelectionActionButton {
  color: var(--color__inverse__standard__text);
  padding: 4px;
  box-sizing: content-box;
}

.ActionsDivider {
  width: 1px;
  height: 32px;
  background: var(--color__inverse__standard__border);
  margin: 0 8px;
}
