.responsesContainer {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  padding: 0 var(--dig-spacing__micro__large);
  align-self: baseline;
}

.responsesContainer.win32Scrollbar {
  /* Make space between scrollbar and content. */
  padding-right: 0;

  /* Always leave a scrollbar gap to make the UI consistent either with or
     without a scrollbar present. */
  scrollbar-gutter: stable;
}

.responseContainer {
  padding: var(--dig-spacing__micro__large) 0;
  border-bottom: 0.5px solid var(--dig-color__border__subtle);
}

.responseContainer:last-child {
  border-bottom: none;
}

.responseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--dig-spacing__micro__medium);
}

.responseSpinner {
  margin-right: var(--dig-spacing__micro__medium);
}

.responseQuestions {
  opacity: 0;
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--duration__surface-slow) var(--easing__linear);
}

.responseQuestions.active {
  opacity: 1;
  height: auto;
  max-height: 150px;
}
