.sharingContact {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border-radius: 4px;
}

.sharingContactHover:hover {
  cursor: pointer;
  background-color: var(--color__opacity--1);
}

.sharingContactTextContainer {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.faintText {
  margin-top: -4px;
  color: var(--color__faint__text);
}

.standardText {
  color: var(--color__standard__text);
}
