.feedbackFormContainer {
  padding-bottom: var(--dig-spacing__micro__xlarge);
}

.feedbackFormButtonContainer {
  margin: var(--dig-spacing__micro__medium) 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--dig-spacing__micro__large);
}

.feedbackTags {
  padding-bottom: var(--spacing__unit--4);
}

.styledText {
  margin: var(--spacing__unit--2) 0;
  display: block;
}

.styledChip {
  margin-top: var(--spacing__unit--0_5);
  margin-right: var(--spacing__unit--0_5);
}
