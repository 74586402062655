.button {
  border-radius: var(--dig-radius__large);
  border: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
  display: flex;
  align-items: center;
  max-width: 232px;
  padding: var(--dig-spacing__micro__small);
}

.button:hover {
  border-color: var(--dig-color__border__base);
  cursor: pointer;
}

.button:focus {
  outline: 2px solid var(--dig-color__utility__focus-ring);
  border-color: var(--dig-color__border__bold);
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: var(--dig-spacing__micro__small);
}

.iconContainer img {
  max-height: 32px;
  max-width: 32px;
}

.textContainer {
  flex-direction: column;
  justify-content: center;
  text-align: start;
  text-overflow: ellipsis;
  white-space: initial;
  max-width: 180px;
  margin-right: var(--dig-spacing__micro__small);
  display: flex;
  -webkit-box-orient: vertical;
}
