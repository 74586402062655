.mainContent {
  display: flex;
  align-items: center;
  position: relative;
}

.unreadBadge {
  position: absolute;
  left: 0;
}

.label {
  flex-grow: 1;
}

.actions {
  position: relative;
  z-index: 2;
}

.actions.mobileActions {
  display: flex;
  justify-content: center;
  padding-top: var(--dig-spacing__micro__medium);
}
