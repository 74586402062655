div.container {
  padding-top: var(--spacing__unit--1);
  padding-bottom: var(--spacing__unit--1);
  gap: 0;
  background: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__subtle);
}

.contentContainer {
  padding: var(--spacing__unit--1_5);
  padding-top: 0;
  padding-bottom: var(--spacing__unit--2);
  text-align: center;
}

.messageContainer {
  padding: var(--spacing__unit--2);
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--2);
  opacity: 0.7;
}

.actionButton {
  height: 40px;
}

.actionButton > span {
  height: 100%;
}
