.safariModalLeft {
  background-color: white;
  flex: 1;
}

.safariModalRight {
  background-color: var(--color__core__secondary) !important;
  flex: 1;
}

.safariModalRightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.safariVideo {
  width: 304px;
  border-radius: 4px;
  display: none;
}

.safariVideoLoading {
  width: 304px;
  height: 217px;
  border-radius: 4px;
}

.safariVideoLoaded {
  display: block;
}

.safariStepOne {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.safariStepOneBold {
  margin-left: 4px;
  margin-right: 10px;
}

.safariInstructionNumber {
  color: var(--dig-color__text__subtle);
}

.safariDisconnectTitle {
  margin-bottom: 40px;
}

.safariDisconnectLeftStack {
  margin-top: 50px;
}

.safariDisconnectButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
