.headerContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--2);
  padding: 0;
  width: 100%;
}

.headerContainerAug {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--dig-spacing__micro__xlarge);
  margin-top: 35px;
  padding-left: var(--dig-spacing__micro__large);
}

.headerMain {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: var(--spacing__unit--1);
  min-width: 0;
  border-radius: var(--dash-radius__component);
  padding: var(--spacing__unit--2);
  background-color: var(--dig-color__primary__surface);
}

.headerMainRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  position: relative;
  z-index: 1;
}

.headerContainerAug:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 76px;
  background-color: var(--dig-color__primary__surface);
  z-index: 0;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
}

.headerIconContainer {
  max-height: 48px;
}

.headerMain * {
  --dash-radius__component: var(--dash-radius__level-2);
}

.headerActions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
}

.shortChip {
  padding-left: 0;
  padding-right: var(--spacing__unit--0_5);
}

.stackIcon {
  border: 3px solid var(--dig-color__background__base);
  margin: -3px;
  box-sizing: content-box;
}

.stackName {
  font-size: var(--type__title__large--fontsize);
  margin: 0;
  margin-top: calc(-1 * var(--spacing__unit--0_5));
  display: inline-flex;
  align-items: flex-end;
  gap: var(--spacing__unit--0_5);
  text-overflow: ellipsis;
  max-width: 100%;
  word-break: break-word;
}

.stackInfoAndMembers {
  margin-top: var(--spacing__unit--1);
}

.stackTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.stackTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--1);
}

.stackTitleArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__small);
}

.immediateActions {
  align-self: flex-start;
}

.subtleText {
  color: var(--dig-color__text__subtle);
}

.createdByAvatar {
  margin: 0 var(--dig-spacing__micro__xsmall);
}
