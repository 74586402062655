.sectionsNavDragDrop {
  display: flex;
  flex-direction: column;
}

.sectionSpacer {
  height: var(--spacing__unit--1);
}

.navigationContainer {
  padding: var(--spacing__unit--2);
  border-radius: var(--dash-radius__component);
  border: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__raised);
}

.navigationContainer * {
  --dash-radius__component: var(--dash-radius__level-2);
}

.navigationHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing__unit--1);
  height: var(--spacing__unit--4);
}

.noMargin {
  margin: 0;
  text-transform: capitalize;
}

.noLineHeight {
  line-height: 0;
}

.smallFont {
  font-size: var(--type__body__standard--fontsize);
}

.noSections {
  text-align: center;
  margin: var(--spacing__unit--4) 0;
}

.navigationItem {
  padding: var(--spacing__unit--0_5);
  border-radius: var(--dash-radius__component);
  cursor: pointer;
  background: transparent;
  width: calc(100% - var(--spacing__unit--1));
  display: block;
  border: none;
  text-align: left;
  margin-left: calc(-1 * var(--spacing__unit--0_5));
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigationItem:hover,
.sectionsNavItemIsDragging .navigationItem {
  background-color: var(--dig-color__opacity__surface);
}

.navigationItem:hover .draggableButton {
  visibility: visible;
}

.sectionsNavItemIsDragging .navigationItemAugust,
.navigationItemAugust:hover {
  background-color: var(--dig-color__opacity__surface);
}

.navigationItemAugust:hover .draggableButton {
  visibility: visible;
}

.navigationItemAugust {
  border-radius: var(--dash-radius__component);
}

.activeNavigationItem {
  color: var(--dig-color__primary__text);
  background-color: var(--dig-color__opacity__surface);
}

.marchNavigationItem {
  box-sizing: border-box;
  width: 100%;
  padding: var(--dash-link-list__link__padding-top)
    var(--dash-link-list__link__padding-right)
    var(--dash-link-list__link__padding-bottom)
    var(--dash-link-list__link__padding-left);
  margin-left: 0;
}

.fancyEditInput {
  margin-bottom: var(--spacing__unit--1);
}

.fancyEditInput.augustRevision {
  padding-left: 4px;
}

.suggestionsHeader {
  margin-bottom: var(--spacing__unit--1);
}

.suggestionsLinks {
  margin: 0 -8px;
}

.roundedBorder,
.menuRoundedBorder > * {
  margin-right: var(--spacing__unit--1);
}

.pointerEventReset {
  pointer-events: auto;
}

.suggestionsHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addSectionButtonV2 > span {
  justify-content: flex-start;
}

.draggableButton {
  cursor: grab;
  pointer-events: all;
  visibility: hidden;
}

.draggableButton:active {
  cursor: grabbing;
}

.draggableSectionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionsTitle {
  padding-left: var(--dig-spacing__micro__xsmall);
}

.emptySectionsStateText {
  color: var(--dig-color__text__subtle);
  margin-bottom: var(--dig-spacing__micro__small);
  text-align: center;
}
