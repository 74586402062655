.source {
  display: flex;
  flex-direction: row;
  padding: 0 var(--dig-spacing__micro__large) var(--dig-spacing__macro__xsmall)
    var(--dig-spacing__micro__large);
  overflow: hidden;
}

.sourceButton {
  /* Button resets */
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  /* End button resets */

  flex: 0 1 auto;
  display: flex;
  gap: var(--dig-spacing__dimension__8);
  align-items: center;
  cursor: pointer;

  overflow: hidden;
  border: 0.5px solid var(--dig-color__border__subtle);
  border-radius: var(--spacing__unit--1);
  padding: var(--spacing__unit--1) var(--spacing__unit--2);
}

.sourceButton:hover {
  background-color: var(--dig-color__secondary__surface);
}

.iconContainer {
  width: var(--dig-spacing__micro__medium);
  height: var(--dig-spacing__micro__medium);
}

.sourceTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.sourceIcon {
  width: var(--dig-spacing__dimension__16);
  height: var(--dig-spacing__dimension__16);
}

.sourceButton span {
  width: fit-content;
  height: fit-content;
}
