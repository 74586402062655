.pageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contentContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  padding: 0 var(--dig-spacing__macro__medium);
  max-width: min(1100px, 100%);
  margin: 0 auto;
}

.modalSubtitle {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__macro__small);
  padding: var(--dig-spacing__micro__xlarge) 0 var(--dig-spacing__micro__small)
    0;
}

.modalContentContainer {
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__medium);
}

.searchInputContainer {
  padding: var(--dig-spacing__micro__small) var(--dig-spacing__micro__medium);
}

.selectedContentContainer {
  padding: var(--dig-spacing__micro__small);
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

.searchInputContainer > span {
  border: 0;
  box-shadow: none !important;
}

.searchInputContainer input {
  padding-left: var(--dig-spacing__micro__large) !important;
}

.stacksListContainer {
  padding: var(--dig-spacing__micro__small);
  height: 250px;
  overflow-y: auto;
  border-top: 1px solid var(--dig-color__border__subtle);
}

.dig-Select-trigger {
  border-radius: var(--dig-radius__medium) !important;
  border: 1px solid var(--dig-color__border__bold) !important;
  padding: var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__small) !important;
}

.modalPermissions {
  padding-top: var(--dig-spacing__micro__xlarge);
  display: flex;
  gap: var(--dig-spacing__micro__small);
  flex-direction: column;
  width: 270px;
}

.publishedListTable {
  height: auto;
  border-collapse: separate !important;
  border-radius: var(--dig-radius__medium);
  margin: var(--dig-spacing__macro__xlarge) 0;
}

.publishListContentTitle {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
}

.publishListContentPermission {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);
}

.selectedStackItemContainer {
  padding: var(--dig-spacing__micro__small);
}

.modalContentContainerDiv {
  height: 380px;
}

@media (max-width: 1000px) {
  .hideInSmallScreen {
    display: none;
  }
}
