.container {
  padding-bottom: var(--dig-spacing__micro__large);
}

.listHeader {
  display: flex;
  flex-direction: column;
}

.listHeaderTitle {
  margin-block-end: 8px;
  margin-block-start: 8px;
}

.lineSeparator {
  border-top: 1px solid var(--color__standard__border);
  height: 1px;
}
