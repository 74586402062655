.filterBarContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--dig-spacing__micro__small);
  box-sizing: border-box;
}

.filterBarContainer.isMobile {
  padding: 0 var(--spacing__unit--2);
  justify-content: flex-start;
  gap: var(--spacing__unit--1);
}

.filterChipContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__medium);
}

.filterChipContainer.isMobile {
  flex-wrap: wrap;
}

.clearAllButton {
  margin-left: var(--dig-spacing__micro__small);
}
