.ConversationMessageFollowUpSuggestions {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  padding: 0px 12px 10px 12px;
  position: relative;
  max-width: 760px;
  width: 100%;
  box-sizing: border-box;
  animation: fadeIn 0.2s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ConversationMessageScrollEnd {
  padding-right: 0px;
}

.ConversationMessageFollowUpSuggestionButton {
  min-width: fit-content;
  border: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
}

.ConversationMessageFollowUpArrow {
  display: flex;
  padding: 4px 16px 4px 18px;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  right: -18px;
  background: linear-gradient(
    90deg,
    rgba(246, 245, 243, 0) 0%,
    var(--dig-color__background__base) 49.1%
  );
}

.ConversationMessageFollowUpArrowPaneExpanded {
  background: linear-gradient(90deg, rgba(246, 245, 243, 0) 0%, #fbfafa 49.1%);
}

.ConversationMessageFollowUpArrowDark {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #313030 43.1%);
}

.ConversationMessageFollowUpArrowPaneExpandedDark {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #1a1918 43.1%);
}

.ConversationMessageSpacer {
  min-width: 10px;
  height: 1px;
}
