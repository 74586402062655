.profilingQuestion {
  text-align: center;
  gap: var(--dig-spacing__micro__large);
  margin: var(--dig-spacing__micro__large) 0;
}

.profilingQuestionContainer {
  opacity: 0;
  transform: translateY(10px);
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

.profilingQuestionContainer.visible {
  opacity: 1;
  transform: translateY(0);
}

.profilingQuestionContainer {
  opacity: 0;
  transform: translateY(10px);
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

.profilingQuestionContainer.visible {
  opacity: 1;
  transform: translateY(0);
}

.questionsContainer {
  gap: var(--dig-spacing__micro__medium);
  text-align: center;
  flex-wrap: wrap;
  margin: 0 auto;
}
