.container {
  display: flex;
  flex-direction: row;
  border-radius: var(--dash-radius__component);
  border: 1px solid var(--dig-color__border__subtle);
  height: 669px;
  overflow: hidden;
}

.textContentContainer,
.assetContainer {
  flex: 0 0 50%;
}

.textContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 64px;
  box-sizing: border-box;
}

.assetContainer {
  background-color: var(--dig-color__background__subtle);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.assetContainer img {
  width: 95%;
}

.title,
.message {
  color: var(--dig-color__text__subtle);
}

p.message {
  margin: 0;
  padding-bottom: var(--spacing__unit--2);
}

/* Shrink content for mobile */
@media (max-width: 599px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }

  .textContentContainer {
    padding: var(--spacing__unit--2);
    text-align: left;
    justify-content: inherit;
  }

  .assetContainer {
    padding-top: var(--spacing__unit--2);
  }

  .assetContainer img {
    margin-bottom: -64px;
  }
}
