.progressBar {
  border-radius: var(--dash-radius__component);
  height: 8px;
  width: 100%;
  background-color: var(--dig-color__disabled__surface);
  border: none;
}

.progressBar::-webkit-progress-bar {
  border-radius: var(--dash-radius__component);
  background-color: var(--dig-color__disabled__surface);
}

.progressBar::-webkit-progress-value {
  border-radius: var(--dash-radius__component);
  background-color: var(--dig-color__primary__base);
}

.progressBar::-moz-progress-bar {
  border-radius: var(--dash-radius__component);
  background-color: var(--dig-color__primary__base);
}

.progressBar::-moz-progress-value {
  border-radius: var(--dash-radius__component);
}
