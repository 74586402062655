.subtitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--dig-spacing__macro__medium);
}

.subtitleContainer.isMobile {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--dig-spacing__dimension__8);
}

.loadMoreButtonContainer {
  display: flex;
  justify-content: center;
}
