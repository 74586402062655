@value avatarRoot from "./Avatar.module.css";
@value iconRoot from "./Icon.module.css";
@value uiIconRoot from "./UIIcon.module.css";
@value labelRoot from "./Label.module.css";

.itemRoot {
  /**
   * --metadata-gap is defined in the parent element.
   */
  --item-gap: var(--metadata-gap, 0);
  --icon-gap: var(--dig-spacing__dimension__4);
}

/**
 * If any of the children have focus-visible, apply the focus ring to the item.
 */
.itemRoot:has(:focus-visible) {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
}

.hasBullet:not(:first-of-type)::before {
  content: "•";
  position: relative;
  left: calc(var(--item-gap) * -1);
}

.sizeSmall {
  --avatar-gap: var(--dig-spacing__dimension__6);
}

.sizeMedium {
  --avatar-gap: var(--dig-spacing__dimension__8);
}

.iconRoot + .labelRoot,
.uiIconRoot + .labelRoot {
  margin-left: var(--icon-gap);
}

.labelRoot + .iconRoot,
.labelRoot + .uiIconRoot {
  margin-right: var(--icon-gap);
}

.avatarRoot + .labelRoot {
  margin-left: var(--avatar-gap);
}

.labelRoot + .avatarRoot {
  margin-right: var(--avatar-gap);
}
