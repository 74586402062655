.container {
  border-radius: var(--dash-radius__component);
  display: flex;
  flex-direction: column;
  background-color: var(--dig-color__background__subtle);
  transition: 0.4s ease height;
  margin-bottom: var(--spacing__unit--1);
}

.titleRow {
  padding: var(--spacing__unit--1_5) var(--spacing__unit--2);
  padding-right: var(--spacing__unit--1);
}

.title {
  flex: 1 1 auto;
  font-size: 12px;
}

.listContainer {
  padding: 0 var(--spacing__unit--2) var(--spacing__unit--2_5)
    var(--spacing__unit--2);
  gap: var(--spacing__unit--2);
}

.dismissButton {
  width: var(--spacing__unit--2);
  height: var(--spacing__unit--2);
}
