.wrapper {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--4);
  background-color: var(--dig-color__background__subtle);
  border-radius: var(--dash-radius__component);
}

.primaryImage {
  padding: var(--spacing__unit--2);
  padding-right: 0; /* using gap to separate the image and content */
}

.content {
  padding: var(--spacing__unit--2);
  padding-left: 0; /* using gap to separate the image and content */
}

.primaryImage img {
  height: 100%;
}

.title {
  margin: 0;
}

.description {
  margin-bottom: 0;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--2);
  margin-top: var(--spacing__unit--2);
}

.dismissButton {
  color: var(--dig-color__text__subtle);
}

/* Shrink content for mobile */
@media (max-width: 599px) {
  .wrapper {
    display: block;
    gap: 0;
  }
  .primaryImage img {
    width: 100%;
    max-height: none;
  }

  .primaryImage,
  .content {
    padding: var(--spacing__unit--3);
  }

  .primaryImage {
    padding-bottom: 0;
  }

  .actionsContainer {
    flex-direction: column;
  }

  .actionsContainer button {
    height: 48px;
  }
}
