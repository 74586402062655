.ChangelogModalBody {
  padding: var(--dig-spacing__macro__medium);
  padding-right: 0px;
  overflow: hidden;
  box-sizing: border-box;
}

.ChangelogModalContent {
  min-height: 315px;
  max-height: 500px;
}

.ChangelogModalTitle {
  line-height: var(--dig-type__lineheight__title__medium);
  padding: 0px;
  margin: 0px;
}

.ChangelogModalSubtitle {
  color: var(--dig-color__text__subtle);
  font-size: var(--dig-type__size__text__medium);
  line-height: var(--dig-type__lineheight__paragraph__medium);
}

.ChangelogModalRequestAFeature {
  margin-top: var(--dig-spacing__micro__small);
  font-size: var(--dig-type__size__action__small);
}

.ChangelogModalHeader {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: flex-start;
  padding-bottom: var(--dig-spacing__micro__xlarge);
  gap: var(--dig-spacing__micro__small);
}

.ChangelogList {
  padding: 0px;
  padding-top: var(--dig-spacing__macro__medium);
  padding-bottom: var(--dig-spacing__macro__medium);
  padding-right: var(--dig-spacing__macro__medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--dig-spacing__macro__xlarge);
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: scroll;
  max-height: 315px;
}

.ChangelogItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 0px;
}

.ChangelogItemDate {
  color: var(--dig-color__accent__orange);
  font-style: normal;
  font-weight: 500;
  line-height: var(--dig-type__lineheight__label__small);
}

.ChangelogItemTitle {
  padding-top: var(--dig-spacing__micro__xsmall);
  font-style: normal;
  font-weight: 500;
  line-height: var(--dig-type__lineheight__title__small);
}

.ChangelogItemBody {
  padding-top: var(--dig-spacing__micro__small);
  padding-bottom: var(--dig-spacing__micro__small);
  color: var(--dig-color__text__subtle);
  line-height: var(--dig-type__lineheight__paragraph__medium);
}

.ModalWindow :global(.dig-Modal-close-btn) {
  border-radius: var(--dig-radius__small);
  top: 12px;
  right: 12px;
}
