.voiceRow {
  width: 360px;
}

.voiceRow :global(.dig-Menu-row-accessory) {
  margin-right: 0;
}

.voiceRow :global(.dig-Menu-row-content) {
  margin-left: 8px;
}

.voiceRow :global(.dig-Menu-row-subtitle) {
  margin-top: 4px;
}

.teamMenu :global(.dig-Menu-row-content) {
  margin-left: var(--dig-spacing__macro__large);
}

.teamMenuRow {
  width: 300px;
}

.editVoiceButton {
  margin-right: 16px;
}
