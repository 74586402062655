.container {
  position: fixed;
  z-index: 100;
  bottom: var(--spacing__unit--3);
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 var(--spacing__unit--3);
  pointer-events: none;
  right: 0;
  left: 0;
}

.snackbar {
  border-radius: var(--dash-radius__level-2);
  background: var(--dig-color__warning__surface--state-2);
  width: 100%;
  max-width: 900px;
  pointer-events: initial;
}

.urlButton {
  border-color: var(--dig-color__secondary__base);
}
