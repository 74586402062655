/* we're assuming the css-vars defined in IconLayout.module.css are available here */
/* stylelint-disable csstools/value-no-unknown-custom-properties */
/* stylelint-disable selector-class-pattern */
.root {
  --outer-dimensions: 20px;
  --inner-dimensions: 12px;
  --badge-position: br;

  display: grid;

  /**
   * Without this the badge will not positioned correctly if the Layout component is a button or anchor.
   */
  width: 100%;
  height: 100%;

  /**
   * Prevent this from capturing a hover so that the hover effect in "withShade" isn't interrupted.
   */
  pointer-events: none;

  /**
   * This comes from the root element grid, should this be shared across files somehow?
   */
  grid-area: var(--badge-position);
}

.root-position-top-right {
  --badge-position: tr;
  --badge-position-align: start;
  --badge-position-justify: end;
  --badge-offset-x: var(--layout-badge-offset);
  --badge-offset-y: calc(var(--layout-badge-offset) * -1);
}

.root-position-top-left {
  --badge-position: tl;
  --badge-position-align: start;
  --badge-position-justify: start;
  --badge-offset-x: calc(var(--layout-badge-offset) * -1);
  --badge-offset-y: calc(var(--layout-badge-offset) * -1);
}

.root-position-bottom-right {
  --badge-position: br;
  --badge-position-align: end;
  --badge-position-justify: end;
  --badge-offset-y: var(--layout-badge-offset);
  --badge-offset-x: var(--layout-badge-offset);
}

.root-position-bottom-left {
  --badge-position: bl;
  --badge-position-align: end;
  --badge-position-justify: start;
  --badge-offset-y: var(--layout-badge-offset);
  --badge-offset-x: calc(var(--layout-badge-offset) * -1);
}

.content {
  width: var(--outer-dimensions);
  height: var(--outer-dimensions);
  display: grid;
  place-self: var(--badge-position-align) var(--badge-position-justify);
  transform: translate(var(--badge-offset-x, 0), var(--badge-offset-y, 0));
  place-items: center;
  box-shadow: inset 0 0 0 1px var(--dig-color__border__subtle);
}

/**
 * With an unstyled approach we do not attempt to specifically manipulate the position of the badge content.
 */
.not-styled .content {
  box-shadow: none;
  transform: none;
  place-self: center;
  width: auto;
  height: auto;
}

/* Double specificity here incase we need to beat any other 0 1 0 selectors */
.is-styled .content.content :is(img, svg) {
  aspect-ratio: 1;
  width: var(--inner-dimensions);
  height: var(--inner-dimensions);
  display: block;
  object-fit: contain;
}
