.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.text {
  max-width: 320px;
  text-align: center;
}
