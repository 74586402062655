.contentLayout {
  display: grid;
  grid-template-columns: 1fr minmax(312px, 664px) 1fr;
  box-sizing: border-box;
  column-gap: var(--spacing__unit--2);
  padding: var(--spacing__unit--2);
  padding-left: var(--dig-spacing__macro__small);
  width: 100%;
  height: 100%;
}

.contentLayout.largeGrid {
  grid-template-columns: 1fr minmax(312px, 1100px) 1fr;
}

.contentLayout.isFullWidth {
  display: flex;
  padding: var(--spacing__unit--2);
}

.contentLayout.isMobile {
  padding: var(--spacing__unit--2) 0;
  padding-left: 0;
  column-gap: 0;
}

.resultsContainer {
  width: 100%;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--2);
}

.resultsContainer.isFullWidth {
  align-items: center;
}

.canvas {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
