.box {
  /* Define vars for use in other places. */
  --add-item-box-border-radius: 10px;
  --add-item-focus-ring-border-size: 2px;
  --add-item-focus-ring-border-size-half: calc(
    var(--add-item-focus-ring-border-size) / 2
  );

  border-radius: var(--add-item-box-border-radius);
  border: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
}

.box:hover {
  box-shadow: var(--dig-utilities__boxshadow__raised);
}

/* No choice but to override the DIG style here. */
.box :global(.dig-TextInputContainer):has(input:focus),
.box :global(.dig-Link):focus,
.box :global(.dig-IconButton):focus {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.box :global(.dig-TextInputContainer):has(input:focus-visible) {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.box :global(.dig-Link):focus-visible,
.box :global(.dig-IconButton):focus-visible {
  box-shadow: 0 0 0 var(--add-item-focus-ring-border-size)
    var(--dig-color__border__base);
}

.header {
  border-top-left-radius: var(--add-item-box-border-radius);
  border-top-right-radius: var(--add-item-box-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: calc(16px - var(--add-item-focus-ring-border-size-half));
  padding-bottom: calc(8px - var(--add-item-focus-ring-border-size-half));
  padding-left: calc(16px - var(--add-item-focus-ring-border-size-half));
  padding-right: calc(16px - var(--add-item-focus-ring-border-size-half));
  margin: var(--add-item-focus-ring-border-size-half);
}

.searchResults {
  border-top: 1px solid var(--dig-color__border__subtle);
  overflow-x: hidden;
  overflow-y: auto;
}

.searchTextInputWrapper {
  box-sizing: border-box;
  padding: calc(8px - var(--add-item-focus-ring-border-size-half))
    calc(15px - var(--add-item-focus-ring-border-size-half));
  margin: var(--add-item-focus-ring-border-size-half);
  border-top-left-radius: var(--add-item-box-border-radius);
  border-top-right-radius: var(--add-item-box-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: calc(100% - var(--add-item-focus-ring-border-size));

  --dash-radius__component: var(--dash-radius__level-3);
}

.searchIcon {
  margin-right: var(--spacing__unit--1_5);
}

.noSearchResults {
  display: flex;
  height: 48px;
  align-items: center;
  margin-left: 48px;
}

.recommendedHeader {
  padding-top: calc(8px - var(--add-item-focus-ring-border-size-half));
  padding-left: calc(16px - var(--add-item-focus-ring-border-size-half));
  padding-right: calc(16px - var(--add-item-focus-ring-border-size-half));
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.recommendedHeaderText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}
