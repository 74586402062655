.noEventsListItem {
  border-left: 2px solid var(--dig-color__border__subtle);
  padding: var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__large);
}

.noEvents .illustrationContainer {
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__small)
    var(--dig-spacing__micro__large) var(--dig-spacing__micro__small);
}

.noEvents .textContainer {
  padding-bottom: var(--dig-spacing__micro__large);
}

.syncing .iconContainer {
  /* Vertically align the sync text with the left-hand icon */
  line-height: 0;
}

.syncing .skeletonListItem {
  padding: var(--dig-spacing__micro__small);
  border-radius: var(--dig-radius__small);
}

.syncing .skeletonTextBox {
  padding: var(--dig-spacing__micro__xsmall) 0px
    var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__large);
  border-left: var(--dig-spacing__dimension__2) solid
    var(--dig-color__primary__surface--state-1);
}

.syncing .skeletonTextBox .top {
  margin-top: 0;
}

.syncing .skeletonTextBox .bottom {
  margin-bottom: 0;
}

.allDoneIllustration {
  width: 64px;
}

/* dynamic-color selector to get it to work in dark mode */
.allDoneIllustration :global([class^='dynamic-color'] path[fill]) {
  fill: currentcolor;
}

.allDoneIllustration :global([class^='dynamic-color'] path[stroke]) {
  stroke: currentcolor;
}
