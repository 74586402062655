.inviteFormBody {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__medium);
  padding: var(--dig-spacing__macro__xsmall) var(--dig-spacing__macro__small);
}

.inviteModalFooter {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: var(--dig-spacing__micro__small);
  height: 80px;
}
