.modalHeader {
  padding: 32px 24px 16px;
}

.modalContent {
  padding: 16px 24px 60px;
}

.previewText {
  margin-top: 12px;
}

.addQueryButton {
  flex-shrink: 0;
}
