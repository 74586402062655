.SourcesSearchContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SourcesSearchContainerBordered {
  border-radius: var(--dig-radius__medium);
  border: 1px solid var(--dig-color__border__subtle);
}

.SourcesSearchInput {
  flex: 0 0 auto;
}

.SourcesSearchContainerBordered .SourcesSearchInput {
  border-bottom: 1px solid var(--dig-color__border__subtle);
  padding: 3px;
}

.SourcesSearchInput :global(.dig-TextInputContainer) {
  gap: var(--dig-spacing__micro__xlarge);
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__macro__small)
    var(--dig-spacing__micro__medium);
  border-radius: var(--dig-radius__large);
  border: none;
  background: var(--dig-color__background__base);
}

.SourcesSearchContainerBordered
  .SourcesSearchInput
  :global(.dig-TextInputContainer) {
  padding: 8px var(--dig-spacing__micro__medium) 8px
    var(--dig-spacing__macro__small);
  border-radius: var(--dig-radius__small);
  border: 3px solid transparent;
}

.SourcesSearchContainerBordered
  .SourcesSearchInput
  :global(.dig-TextInputContainer):has(input:focus) {
  border: 3px solid var(--dig-color__utility__focus-ring);
}

.SourcesSearchContainerBordered
  .SourcesSearchInput
  :global(.dig-TextInputContainer):hover {
  border: 3px solid transparent;
}

.SourcesSearchInput :global(.dig-TextInputContainer):has(input:focus) {
  border-color: none;
  box-shadow: none;
  outline: none;
}

.SourcesSearchInput
  :global(.dig-TextInputContainer)
  :global(.dig-TextInputAccessory) {
  align-self: center;
}

.SourcesSearchInput .SourcesSearchInputField:global(.dig-TextInput-input) {
  font-size: var(--dig-type__size__text__large);
}

.SourcesSearchInputField::placeholder {
  color: var(--dig-color__text__subtle);
  font-size: var(--dig-type__size__text__large);
}

.SourcesSearchInputBorder {
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

.SourcesSearchInputIcon {
  color: var(--dig-color__text__base);
  margin-right: var(--dig-spacing__micro__small);
}

.SourcesSearchProgressBar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.SourcesSearchResults {
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
}

.SourceSearchResultRows {
}

.SourcesSearchContainerBordered .SourceSearchResultRows {
  padding: 4px 0;
}

.SourceSearchResultRow {
  display: flex;
  gap: 6px;
  padding: 8px;
  align-items: center;
}

.SourceSearchResultIcon {
  padding: 4px 4px 4px 0;
}

.SourceSearchResultLabels {
  min-width: 0;
  flex: 1 1 auto;
}

.SourceSearchResultTitle {
  min-width: 0;
  flex: 1 1 auto;
}

.SourceSearchResultSubtitle {
}

.SourceSearchResultAddButton {
  flex: 0 0 auto;
}

.SourcesSectionLabelAbove {
  padding: var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__xlarge)
    var(--dig-spacing__micro__small);
}

.SourcesSectionLabel {
  padding: var(--dig-spacing__micro__small) var(--dig-spacing__micro__xlarge) 0;
}
