.divider {
  border-bottom: 2px solid var(--dig-color__border__base);
  width: 90%;
  margin-right: auto;
}

.feedbackChipContainer {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--dig-spacing__dimension__12);
}

.feedbackChip {
  margin: 0 var(--dig-spacing__dimension__8) var(--dig-spacing__dimension__8) 0;
}

.sourcesContainer {
  display: flex;
  flex-direction: column;
}

.sourceRow {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin: var(--dig-spacing__dimension__6) 0;
  align-items: center;
}

.feedback {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 8px;
}
