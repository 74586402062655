.FullScreenComposeEditorPane {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--dig-color__background__base);
}

.CloseEditorPaneButton {
  background: none;
  border: none;
  cursor: pointer;
}
