@keyframes slideOpen {
  from {
    max-height: 0;
  }
  to {
    max-height: 440px;
  }
}

@keyframes hideScroll {
  from,
  to {
    overflow: hidden;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:root {
  --banner-height: 41px;
}

/* have to include this override here since overlay is portaled so outside of SearchHeader scope */
.overlay.augustRevision {
  --dash-typeahead__border-radius: 12px;
}

.overlay {
  max-height: 0;
  overflow-y: hidden;
  border-bottom-left-radius: var(--dash-typeahead__border-radius);
  border-bottom-right-radius: var(--dash-typeahead__border-radius);
  border: 1px solid var(--dig-color__border__subtle);
  border-top: 0px;
  box-sizing: border-box;

  background-color: var(--dig-color__background__base);
  box-shadow: 0 4px 8px 0 #0000001a;
  border-top: 0;
  z-index: 52;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track-piece {
    margin-bottom: 4px;
    margin-top: 4px;
  }

  &::-webkit-scrollbar-track {
    border-bottom: 10000vh solid transparent;
  }

  &::-webkit-scrollbar-corner {
    border-bottom: 10000vh solid transparent;
    border-bottom-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--dig-color__border__subtle);
    border-radius: 4px;
    background-color: unset;
    border-top-color: transparent;
    border-bottom-color: transparent;
    opacity: 0;
  }

  &:hover,
  &:focus-within {
    scrollbar-color: var(--dig-color__border__base) transparent;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      background-color: unset;
      border-top-color: var(--dig-color__border__base);
      border-bottom-color: transparent;
    }
  }

  &.opened {
    overflow-x: hidden;
    max-height: 450px;
    animation:
      slideOpen 0.4s,
      hideScroll 0.4s;
  }

  &.fullWidthSearchbar {
    border-bottom-left-radius: var(--dig-spacing__micro__small);
    border-bottom-right-radius: var(--dig-spacing__micro__small);
  }
}

.activationOverlay {
  max-height: 550px;
}

.listContainer {
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  width: 100%;
  margin: 0px;
  margin-bottom: 41px;
  padding: 0px;
}

.listContainer.isDualModeLauncher {
  max-height: 477px;
}

.animateFadeIn {
  animation: fadeIn 0.5s;
}

.scrollbar {
  /* chrome 118 in Electron 27 seems to like this */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-bottom: 10000vh solid transparent;
  }

  &::-webkit-scrollbar-corner {
    border-bottom: 10000vh solid transparent;
    border-bottom-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: unset;
    border-top-color: transparent;
    border-bottom-color: transparent;
    opacity: 0;
  }

  &:hover,
  &:focus-within {
    scrollbar-color: var(--dig-color__border__base) transparent;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      background-color: unset;
      border-top-color: var(--dig-color__border__base);
      border-bottom-color: transparent;
    }
  }
}

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--banner-height);
  background-color: var(--dig-color__background__base);
}
