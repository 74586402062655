.container {
  --multi-answer-card__max-height: calc(100vh - 160px);
  --multi-answer-card__desktop__max-height: calc(100vh - 215px);
}

@media screen and (min-width: 889px) {
  .container {
    overflow: hidden;
    max-height: var(--multi-answer-card__max-height) !important;
    overflow-y: auto;
  }

  .container.isDesktop {
    max-height: var(--multi-answer-card__desktop__max-height) !important;
  }
}

.container.isMobile {
  margin: 0 var(--dig-spacing__dimension__16);
}

.badge {
  margin-left: var(--dig-spacing__dimension__12);
}

.divider {
  height: 1px;
  background-color: var(--dig-color__border__subtle);
  margin: var(--dig-spacing__dimension__12) 0;
}

.progressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popUp {
  0% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

.cardContent {
  animation-name: popUp, fadeIn;
  animation-duration: 500ms, 250ms;
  animation-timing-function: cubic-bezier(0.01, 0, 0, 1), linear;
}

.experimentalNoticeText {
  display: block;
  margin: 0;
  padding: 0;
}

.annotationContainer {
  background-color: var(--dig-color__warning__surface--state-2);
}
