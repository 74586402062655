.tableContainer {
  overflow-x: auto;
}

.table {
  height: auto;
  border-collapse: separate !important;
  border-radius: var(--dig-radius__medium);
  margin: var(--dig-spacing__macro__xlarge) 0;

  border-collapse: separate;
  vertical-align: top;
  min-width: 120dvw;
}

.contentRow {
  height: 84px;
}

.headerRow {
  height: 40px;
}

.titleContainer {
  display: flex;
  align-items: center;
  height: 100%;
  gap: var(--dig-spacing__dimension__12);
}

.verifiedByColumn {
  display: flex;
  flex-direction: column;
}

.stickyHeader,
.stickyCell {
  position: sticky;
  z-index: 10;
  background: var(--dig-color__background__base);
  width: 100px;
  right: 0;
  border-left: 1px solid var(--dig-color__border__subtle);
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
