.event {
  padding-left: var(--dig-spacing__micro__large);
  border-left: var(--dig-spacing__dimension__2) solid
    var(--dig-color__primary__surface--state-1);
  width: 100%;
}

.event.bright {
  border-left-color: var(--dig-color__primary__base);
}

.proximate {
  color: var(--dig-color__primary__base);
}

.expandableContent {
  overflow: hidden;
}

.eventDescription {
  position: relative;
}

.eventDescription:not(.expanded) .eventDescriptionText.collapsible {
  display: block;

  /**
    * Use a gradient mask to fade out the text at the bottom right
    * corner of the description to allow the More/Less button to be
    * visible.
    */
  mask-image: linear-gradient(155deg, white 50%, transparent 85%);
}

/**
  * More/Less button. Using a <Button> for accessibility, but need to
  * restyle it to match regular text, mainly needs to reset the line
  * height to match the text.
  */
.eventExpandButton {
  position: absolute;
  right: 0;
  inset-block-end: 0;
  height: 1lh;
  line-height: unset;
}

.eventExpandButton > span {
  line-height: unset;
  height: 1lh;
}

.eventExpandButton span {
  text-decoration: underline;
}

/**
  * Reset positioning when description is expanded so that it renders
  * inline at the end of hte text block, left aligned
  */
.eventDescription.expanded .eventExpandButton {
  position: static;
  padding-left: 0;
  backdrop-filter: none;
  mask-image: none;
}

.bodyStack {
  padding-top: var(--dig-spacing__micro__xlarge);
}

/* Firefox-specific styles */
@-moz-document url-prefix() {
  .attachmentLink {
    /* Fix erroneous padding/margin expansion on focused links (DASHWEB-4769) */
    display: inline-block;
  }
}

.attendeesContainer {
  padding-top: var(--dig-spacing__micro__xsmall);
}

.facepileContainer {
  margin-left: var(--dig-spacing__micro__small);
}

.linkContainer {
  overflow: hidden;
}

.buttonContainer {
  padding-top: var(--dig-spacing__micro__xlarge);
  padding-bottom: var(--dig-spacing__micro__xsmall);
}

.attendeesOverlay {
  max-width: min(267px, 75%);
  background: var(--dig-color__background__raised);
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__medium);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  animation: floatIn var(--duration__surface) var(--easing__transition);
}

@keyframes floatIn {
  0% {
    margin-top: 5px;
    opacity: 0.5;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}

.overlayTitle {
  display: inline-block;
  padding: var(--dig-spacing__micro__xlarge) var(--dig-spacing__micro__xlarge)
    var(--dig-spacing__micro__small);
}

.overlayListContainer {
  max-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: var(--dig-spacing__micro__small);
  padding-bottom: var(--dig-spacing__micro__small);

  /**
   * Slightly fade out attendees at the bottom of the list to
   * indicate scrollability.
   */
  mask-image: linear-gradient(180deg, white 91%, transparent);
}

.overlayListItem {
  padding-left: var(--dig-spacing__micro__medium);
  padding-right: var(--dig-spacing__micro__medium);
}

.overlayListItemContent {
  overflow: hidden;
}
