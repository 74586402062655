.suggestionContainer {
  padding: var(--dig-spacing__micro__xlarge) var(--dig-spacing__micro__xsmall);
}

.suggestionTitle {
  padding-bottom: var(--dig-spacing__micro__medium);
}

.suggestions {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__medium);
  font-weight: var(--dig-type__weight__base);
}
