.listItem {
  border-radius: 12px;
  border: 1px solid var(--dig-color__border__subtle);
  cursor: pointer;
  word-break: break-word;
  background-color: var(--dig-color__background__base);
  margin: 0;
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__macro__small);
  /* Using fixed height so that height is the same with and without description */
  height: 80px;
}

.listItem:not(.connected):hover {
  border: 1px solid var(--dig-color__border__bold);
}

.flexCenter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing__unit--2);
}

.iconMarginRight {
  display: flex;
  margin-right: var(--spacing__unit--1);
}

.onboardingVariantListItem {
  margin: var(--dig-spacing__micro__xsmall) 0;
  cursor: pointer;
}

.onboardingVariantListItem:hover {
  border-radius: var(--dig-spacing__micro__xsmall);
  border: 1px solid var(--dig-color__opacity__surface--state-1);
}

.connectedSubtitle {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);
}

.connectedText {
  /* Need to push text down to make it look center-aligned. */
  transform: translateY(1px);
}

.successColor {
  color: var(--dig-color__success__base--state-1);
}

.errorColor {
  color: var(--dig-color__alert__base--state-1);
}

.iconButton {
  background-color: var(--dig-color__opacity__surface--state-1);
}

.iconButton.connected {
  background-color: var(--dig-color__success__surface--state-2);
}
