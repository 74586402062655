.feedbackTypeLine {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
}

.feedbackTypeDropdown {
  min-width: 240px;
}

.feedbackFormBody {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__medium);
  padding: var(--dig-spacing__macro__xsmall) var(--dig-spacing__macro__small);
}

.feedbackModalFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--dig-spacing__micro__small);
  height: 82px;
}

.feedbackFormCTA {
  display: flex;
  align-items: center;
  gap: 8px;
}

.screenshotAttachment {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1);
  width: fit-content;
}

.screenshotMini {
  border-radius: 4px;
  border: 1px solid var(--dig-color__border__base);
  width: var(--dig-spacing__macro__medium);
  height: var(--dig-spacing__macro__medium);
}

.fileInput {
  display: none;
}
