@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popupContainer {
  padding: 16px 20px 20px;
  background-color: var(--dig-color__background__base);
  border-radius: 16px;
  box-shadow: var(--dig-utilities__boxshadow__floating);
  min-width: 260px;
  width: fit-content;
  opacity: 0;
  animation: 0.075s linear 0.05s fadeIn both;
  cursor: default;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__small);
  margin-bottom: var(--dig-spacing__micro__xsmall);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);
}

.verifiedIcon {
  color: var(--dig-color__primary__base);
}

.subtitle {
  display: flex;
  align-items: center;
}

.metadataContainer {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.blurb {
  display: inline-block;
  width: 0;
  min-width: 100%;
}

.metadataContainer > *:not(:last-child)::after {
  content: '•';
  margin: 0 8px;
}

.footerContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--dig-spacing__micro__medium);
}
