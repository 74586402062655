.ConversationMessagesScroller {
  flex: 1 1 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 18px 20px 0px 20px;
  width: 100%;
  box-sizing: border-box;
}

.ConversationMessages {
  display: flex;
  flex-direction: column;
  max-width: 720px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: 24px;
}

.ConversationMessageSourceRow {
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  max-width: 100%;
  padding-bottom: var(--dig-spacing__micro__small);
}

.ConversationMessageTitle {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: fit-content;
}

.ConversationMessageSourceInfo {
  overflow: hidden;
  width: 192px;
  margin-left: var(--dig-spacing__micro__large);
}

.ConversationMessageSourceMetadata {
  display: flex;
  align-items: center;
  min-width: 100%;
  width: 0;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.ConversationMessageSourceTitle {
  min-width: 0;
  flex: 1 1 auto;
}

.ComposeSourceActionButton {
  width: 24px;
  height: 24px;
  visibility: hidden;
}

.ConversationMessageSourceRow:hover .ComposeSourceActionButton {
  visibility: visible;
}

.SourcesFromSettingsConversationPane {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ContextInputSourcesChipRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--dig-spacing__micro__medium);
}

.ContextInputSourcesChip {
  max-width: 320px;
}
