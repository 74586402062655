.SourcesFromSettingsOneContent {
  padding: 32px;
  padding-bottom: 0;
  height: 694px;
  overflow: scroll;
}

.SourcesFromSettingsOneMobile {
  height: calc(100vh - 82px);
}

.SourcesFromSettingsOneTitle {
  margin: 0;
  margin-bottom: 20px;
}

.SourcesFromSettingsOneDescription {
  margin-bottom: 20px;
}

.SourcesFromSettingsOneControls {
  margin-bottom: 32px;
}

.SourcesFromSettingsOneSourcesLabel {
  padding: 8px 4px var(--dig-spacing__micro__xsmall) 4px;
  margin: var(--dig-spacing__micro__small) 0;
  margin-left: var(--dig-spacing__micro__xsmall);
}

.SourcesFromSettingsOneSourcesInputLabel {
  padding: 8px 4px var(--dig-spacing__micro__xsmall) 4px;
  margin: var(--dig-spacing__micro__medium) 0 var(--dig-spacing__micro__small);
}
