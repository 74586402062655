.overlay {
  width: 387px;
  background-color: var(--dig-color__background__raised);
  border-radius: var(--spacing__unit--1_5);
  box-shadow: var(--dig-utilities__boxshadow__raised);
}

.graphic {
  background: #134936;
  text-align: center;
  border-top-left-radius: var(--spacing__unit--1_5);
  border-top-right-radius: var(--spacing__unit--1_5);
  padding: var(--spacing__unit--1_5);
  padding-bottom: var(--spacing__unit--0_5);
}

.text {
  padding: var(--spacing__unit--3);
  padding-top: var(--spacing__unit--0_5);
}

.tooltipContent {
  display: inline-block;
  transform: translateY(-2px);
  cursor: default;
}

.ol {
  margin-block: 0;
  padding-inline: var(--spacing__unit--3);
}

.tooltipArrowContainer {
  height: 0;
  display: flex;
  justify-content: center;
  transform: translateY(-6px);
}

.tooltipArrowContainer::after {
  content: '';
  background-color: var(--dig-color__background__raised);
  height: 12px;
  transform: rotate(45deg);
  width: 12px;
}
