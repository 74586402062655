.horizontalSeparator {
  height: 1px;
  border-width: 0;
  background-color: var(--dig-color__border__subtle);
}

.emptyState {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1);
  padding: var(--spacing__unit--5);
  padding-bottom: var(--spacing__unit--4);
}

.emptyState span {
  color: var(--dig-color__text__subtle);
}
