.emptyStateBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(2 * var(--spacing__unit--4));
  text-align: center;
  border-radius: var(--spacing__unit--2);
  border: 1px dashed var(--dig-color__border__subtle);
}

.emptyStateText {
  color: var(--dig-color__text__subtle);
  margin-bottom: var(--spacing__unit--2);
}

.emptyStateImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing__unit--2);
}

.emptyStateImg img {
  width: 80px;
  filter: drop-shadow(0 4px 10px #00000008);
}
