.container {
  opacity: 0;
  margin: var(--dig-spacing__micro__small) var(--dig-spacing__micro__xsmall)
    var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__xsmall);
}

.container.active {
  opacity: 1;
}

.buttons {
  margin-top: var(--dig-spacing__micro__xsmall);
}

.leftSide {
  display: flex;
  flex-direction: row;
  gap: var(--dig-spacing__micro__xsmall);
  width: 100%;
}

.copyButton {
  margin-left: auto;
}
