.iconContainer {
  --size: 36px;
  width: var(--size);
  height: var(--size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--dig-spacing__micro__xsmall);
}

.applicationFavicon {
  max-width: 100%;
}
