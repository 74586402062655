.EmptyChatInstructions {
  margin: auto 0;
  height: calc(100vh - 250px);
  align-content: center;
}

.EmptyChatInstructions.Desktop {
  height: unset;
}

.TitleContainer {
  margin-top: calc(
    -1 * (var(--dig-spacing__micro__large) +
          var(--dig-type__lineheight__title__small))
  );
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.EmptyChatInstructions.Desktop .TitleContainer,
.TitleContainerMobile {
  margin-top: 0;
}

.Title {
  max-width: 425px;
  text-align: center;
}

.EmptyChatInstructionsCards {
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}

.EmptyChatInstructionCard {
  flex: 0 0 auto;
  width: 222px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: var(--dig-radius__large);
  border: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
  user-select: none;
  position: relative;
  transition: box-shadow var(--duration__surface) var(--easing__transition);
}

.EmptyChatInstructionCard.Clickable {
  cursor: pointer;
}

.BorderVariantNormal {
}

.BorderVariantDotted {
  border-style: dashed;
  border-width: 2px;
}

.BorderVariantComingSoon {
  opacity: 0.5;
}

.ComingSoonBadge {
  position: absolute;
  right: 20px;
  top: 20px;
}

.EmptyChatInstructionCard.Clickable:hover {
  box-shadow: 0px 4px 12px 0px var(--dig-color__shadow__elevated);
}

.CardTitle {
  margin-top: 2px;
  font-family: var(--dig-type__family__action);
  font-size: var(--dig-type__size__action__xlarge);
}

.CardDescription {
  margin-top: 4px;
}

.Mobile {
  transform: scale(0.9);
}

.MobileCards {
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.MobileCard {
  width: 100%;
  max-width: 340px;
}

.MobileCardIcon {
  position: absolute;
  right: 15px;
  top: 15px;
}

.MobileCardDescription {
  width: 75%;
}

.Mobile .Title {
  margin-top: 0;
}
