.menuWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.menuWrapper > div {
  width: 100%;
}

.menuWrapper > div > div {
  display: flex;
  width: 100%;
}

.menuContent > div {
  width: 300px;
}

.stacksList {
  max-height: 160px;
  overflow-y: auto;
}

.stackRowContainer > div {
  padding-top: 2px;
  padding-bottom: 2px;
}

.stackRow {
  display: flex;
  gap: var(--spacing__unit--1);
  align-items: center;
  width: 100%;
}

.stackButton,
.stackButton > span {
  width: 100%;
  display: flex;
}

.stackText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.createStackButton {
  width: 100%;
  margin-top: var(--spacing__unit--1_5);
}

.headerRow {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--2);
  padding-left: 2px;
  padding-right: 2px;
}

.accessIcon {
  flex-shrink: 0;
}
