.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.placeholder {
  /* Since the image still exists in the document flow, setting the placeholder
   * to 0 height avoids any vertical jitter when the image replaces the text
   */
  height: 0;
  display: none;
}

.spinner {
  /* visibility instead of display so the browser knows this asset actually exists
   * and it still needs to retrieve the asset
   */
  visibility: hidden;
}

.visible {
  display: unset;
  visibility: unset;
}
