.sharingModalOverlay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sharingModal {
  position: relative;
}

.sharingModal > div {
  border-radius: 8px;
  padding: var(--spacing__unit--2);
  gap: 12px;
  display: flex;
  flex-flow: column;
}

.sharingModalAll {
  perspective: 1px;
}

.sharingModalHeader {
  padding: 0;
  padding-bottom: 0px !important;
}

.sharingModalHeader h2 {
  margin: 0;
}

.sharingModalHeaderDfb {
  padding: var(--dig-spacing__dimension__20);
  background-color: var(--dig-color__primary__surface);
  border-top-left-radius: var(--dash-radius__level-1);
  border-top-right-radius: var(--dash-radius__level-1);
}

.sharingModalTitle {
  margin: 0;
}

.sharingModalBody {
  padding: 0;
}

.sharingModalBodyDfb {
  height: 336px;
}

.sharingModalBodyDfb :global(.dig-Modal-full-width) {
  height: 100%;
}

/* The Stack component injects an additional div between itself and its content that isn't directly targetable */
.sharingModalBodyDfbStack > div {
  height: 100%;
}

/* Not sure why this is needed, but we don't get full width from Modal.Fullbleed without it */
.sharingModalBodyDfb :global(.dig-Modal-full-width) > div {
  width: 100%;
}

.permissionContainer {
  /* Modal.Body */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.permissionContainer2 {
  /* Modal.Body */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
}

.permissionContainer2 > *:first-child {
  flex-grow: 1;
}

.smallRightMargin {
  margin-right: var(--spacing__unit--1);
}

.permissionContainer .permissionIconContainer {
  width: 40px;
  height: 40px;
  background: var(--dig-color__secondary__base);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--dig-color__secondary__surface);
}

.permissionContainer .permissionDescriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dividerLine {
  height: 1px;
  background-color: var(--color__faint__border);
  margin: var(--spacing__unit--1_5) 0;
}

.stackMemberWarningContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.warningIcon {
  width: 16px;
  height: 16px;
  color: var(--color__warning__text);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactsList {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: var(--boxshadow__elevation2);
  padding: 4px;
  position: absolute;
  top: 4px;
  width: 100%;
  background-color: var(--dig-color__background__base);
  z-index: 10000000;
}

.contactsListContainer {
  position: relative;
}

.sharingMembersContainer {
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: var(--spacing__unit--2) 0;
}

.sharingMemberRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: var(--spacing__unit--1);
}

.sharingMemberRow + .sharingMemberRow {
  margin-top: var(--spacing__unit--0_5);
}

.sendButtonParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.notificationToggleContainer {
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing__unit--1);
  margin-bottom: var(--spacing__unit--1);
  gap: var(--spacing__unit--1);
  align-items: center;
}

.textSharePermissionsContainer {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.textSharePermissionsContainer > span,
.noteTextArea,
.sharingPermissionsButton {
  border-radius: 4px;
  background-color: var(--color__opacity--1);
  border: 0px;
}

.faintText {
  color: var(--color__faint__text);
}

.standardText {
  color: var(--color__standard__text);
}

.accessLevelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.snackbarContainer {
  position: absolute;
  bottom: 12px;
  width: calc(100% - 24px);
  white-space: normal;
  z-index: 100;
}

.snackbarContainer div {
  border-radius: 4px;
}

.snackbarContainerDfb {
  position: absolute;
  bottom: calc(-1 * var(--dig-spacing__dimension__44));
  left: 20px;
  width: 436px;
  white-space: normal;
  z-index: 100;
}

.snackbarContainerDfb div {
  border-radius: var(--dig-radius__small);
}

.whoCanAccess {
  margin-bottom: var(--spacing__unit--0_5);
}

.footerBlock {
  padding: 0;
  margin: 0;
  position: relative;
}

.footerBlockDfb {
  padding: var(--dig-spacing__micro__xlarge);
  border-top: 1px solid var(--dig-color__border__subtle);
}

.copyLinkButton {
  border-radius: var(--spacing__unit--0_5);
  margin-left: 0 !important;
}

.menu {
  width: 100%;
}

.menu > div {
  width: 100%;
}

.button {
  width: 100%;
}

.button > * {
  display: flex;
  gap: var(--spacing__unit--1);
  padding: 0 var(--spacing__unit--2) !important;
  width: 100%;
}

.leftButton,
.leftButton > * {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  justify-content: flex-start;
}

.rightButton,
.rightButton > * {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  justify-content: flex-end;
}

.tinyIcon,
.tinyIcon > svg {
  width: var(--spacing__unit--2);
  height: var(--spacing__unit--2);
}

.userPermissionMenu {
  transform: translate(var(--spacing__unit--2), -41px);
}

.permissionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accessMenu {
  transform: translate(-1px, -53px);
  border: 1px solid transparent;
}

.accessMenuRow {
  gap: var(--spacing__unit--1);
}

.accessMenuRow > div:first-child {
  margin: 0px var(--spacing__unit--1);
}

.permissionMenu {
  transform: translate(1px, -52px);
}

.searchIcon {
  margin-right: var(--spacing__unit--1);
}

/* The typeahead portion of the share modal should take up no more than 70% of the body */
.typeaheadContainer {
  border-bottom: 1px solid var(--dig-color__border__subtle);
  max-height: 70%;
  overflow: hidden;
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large);
}

/* Needed to pass height through from typeaheadContainer to typeaheadInputContainer */
.typeaheadContainer :global(.dig-Typeahead),
.typeaheadContainer :global(.dig-ClickOutside) {
  height: 100%;
}

.typeahedSplitInputContainer {
  flex-grow: 1;
}

.typeaheadInputContainer {
  border-radius: var(--dig-radius__xsmall);
  height: 100%;
  overflow: auto;
  padding-left: calc(var(--dig-spacing__micro__small) - 1px);
  padding-right: calc(var(--dig-spacing__micro__small) - 1px);
}

/* Keep the search icon and permissions container fixed while allowing the chips section to scroll */
.typeaheadInputContainer :global(.dig-TextInputAccessory),
.typeaheadPermissionsMenuContainer {
  align-self: flex-start;
  position: sticky;
  top: 0;
}

.typeaheadInputContainer :global(.dig-TextInput-chips-container) {
  height: 100%;
  margin: 0;
}

.typeaheadContainer :global(.dig-Chip) {
  background-color: var(--dig-color__opacity__surface);
}

.membersContainer {
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0;

  height: 336px;
  padding: 0 var(--dig-spacing__micro__small);
}

.membersContainer.canShare {
  height: 240px;
  padding: var(--dig-spacing__micro__small);
}

.shareButton {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.typeaheadPermissionsMenuContainer {
  align-self: start;
}

.typeaheadPermissionsMenuContainer.hidden {
  visibility: hidden;
}

.typeaheadContainerContainer :global(.dig-Typeahead-container) {
  border-bottom-left-radius: var(--dig-radius__small);
  border-bottom-right-radius: var(--dig-radius__small);
}

.typeaheadContainerContainer :global(.dig-Overlay) {
  top: 2px !important;
}

.typeaheadContainerHeading {
  padding: var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__medium) 0
    var(--dig-spacing__micro__medium);
}

/* Message container fills whatever space is left available from the typeahead module */
.messageInputContainer {
  flex: 1 1 auto;
  padding: var(--dig-spacing__micro__medium);
  margin-top: 0;
}

.messageInputTextArea {
  width: 100%;
  border: none;
  height: 100%;
  padding: var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__small);
}

.messageInputTextArea:focus {
  box-shadow: none;
}

.disabledTextColor {
  color: var(--dig-color__disabled__base);
}
