.EditorContainer {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
}

.placeholderWrapper {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  color: var(--dig-color__disabled__base);
  user-select: none;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.placeholder {
  max-width: 700px;
  padding: var(--dig-spacing__macro__xlarge);
}

.contentEditableScroller {
  flex: 1 1 260px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-left: var(--dig-spacing__macro__xlarge);
  padding-right: var(--dig-spacing__macro__xlarge);
  box-sizing: border-box;
}

.contentEditable {
  width: 100%;
  max-width: 700px;
  padding-top: var(--dig-spacing__macro__xlarge);
  padding-bottom: 96px;
  box-sizing: border-box;
  color: var(--dig-color__text__base);
  font-family: var(--type__body__standard--fontfamily);
  font-size: var(--type__body__standard--fontsize);
  font-weight: var(--type__body__standard--fontweight);
  line-height: var(--type__body__standard--lineheight);
  margin: 0 auto;
  cursor: text;
}

.contentEditable:global(.focus-visible) {
  outline: none !important;
  box-shadow: none;
  border: none !important;
}

.contentEditable p {
  margin-top: 0;
  margin-bottom: 0;
}

.contentEditable ul,
.contentEditable ol {
  padding-inline-start: 14px;
}

.placeholder h1,
.contentEditable h1 {
  font-family: var(--dig-type__family__text);
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
}

.placeholder h2,
.contentEditable h2 {
  font-family: var(--dig-type__family__text);
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
}

.placeholder h3,
.contentEditable h3 {
  font-family: var(--dig-type__family__text);
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
}

.placeholder p,
.contentEditable p {
  font-family: var(--dig-type__family__text);
  font-size: var(--type__body__standard--fontsize);
  font-weight: var(--type__body__standard--fontweight);
  line-height: var(--type__body__standard--lineheight);
}

/* Remove margin-top if doc started with a header element */

.placeholder h1:first-child,
.contentEditable h1:first-child,
.placeholder h2:first-child,
.contentEditable h2:first-child,
.placeholder h2:first-child,
.contentEditable h3:first-child {
  margin-top: 0;
}

.editorTextBold {
  font-weight: 500;
}

.editorTextItalic {
  font-style: italic;
}

.editorTextStrikethrough {
  text-decoration: line-through;
}

.editorTextUnderline {
  text-decoration: underline;
}

.editorTextUnderlineStrikethrough {
  text-decoration: underline line-through;
}

.spacer {
  flex: 1 1 0;
}

.divider {
  width: 1px;
  background-color: var(--dig-color__border__subtle);
  margin: 0 16px;
  height: 32px;
}

.editorExportButton {
  align-self: center;
  margin-right: 8px;
}

.WaitingForResponseIndicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: color-mix(
    in srgb,
    var(--dig-color__background__base) 60%,
    transparent
  );
}
