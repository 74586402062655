.root {
  --metadata-gap-multiple: 1;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, max-content);
  gap: calc(var(--metadata-gap) * var(--metadata-gap-multiple));
}

.hasBullet {
  --metadata-gap-multiple: 2;
}

.rootItem {
  position: relative;
}

.sizeSmall {
  min-height: 16px;

  --metadata-gap: var(--dig-spacing__dimension__6);
}

.sizeMedium {
  min-height: 20px;

  --metadata-gap: var(--dig-spacing__dimension__8);
}
