.allDayHeader {
  padding: var(--dig-spacing__micro__xsmall) 0;
  margin: 0 0 var(--dig-spacing__micro__xsmall) 0;
}

.allDayEventListItem {
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__small)
    var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__small);
  border-radius: var(--dig-radius__small);
}

.allDayHeader .split {
  flex-grow: 1;
}

.allDayHeader .splitItem {
  margin-top: 0;
}

.allDayHeader .iconButton {
  color: var(--dig-color__text__subtle);
}

.textContainer {
  padding-left: var(--dig-spacing__micro__large);
  border-left: var(--dig-spacing__dimension__2) solid
    var(--dig-color__primary__surface--state-1);
  width: 100%;
}

.divider {
  padding: 0 var(--dig-spacing__micro__small);
}

.divider div {
  height: 1px;
  background-color: var(--dig-color__border__subtle);
}

.seeAllButtonContainer {
  padding: 0 var(--dig-spacing__micro__small) var(--dig-spacing__micro__small)
    var(--dig-spacing__micro__small);
}
