.container {
  position: relative;
}

.container.size-small {
  width: 45px;
  height: 24px;
}

.container.size-medium {
  width: 45px;
  height: 50px;
}

.avatar {
  margin: 0;
  position: absolute;
}

/* First avatar shows on top of others */
.container.size-medium .avatar:nth-child(1) {
  z-index: 3;
}

.container.size-medium .avatar:nth-child(2) {
  z-index: 2;
}

.container.size-medium .avatar:nth-child(3) {
  z-index: 1;
}

/* Default / Medium size container */

/* Two avatars, arrange them in an offset with one in top left one bottom right */
.container.size-medium .avatar.two:nth-child(1) {
  top: 0;
  left: -6px;
}

.container.size-medium .avatar.two:nth-child(2) {
  bottom: 5px;
  right: -6px;
}

/* Three avatars, arrange them in an upside down skewed triangle with 1 on the bottom middle, 2 on the upper left, and 3 upper on the right
    3
2
  1
*/
.container.size-medium .avatar.three:nth-child(1) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.container.size-medium .avatar.three:nth-child(2) {
  top: 8px;
  left: 0;
}

.container.size-medium .avatar.three:nth-child(3) {
  top: 4px;
  right: 4px;
}

/* Small container */

/* One avatar */

.container.size-small .avatar.one:nth-child(1) {
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0);
}

/* Two Avatars */

.container.size-small .avatar.two:nth-child(1) {
  top: 0;
  left: 0;
}

.container.size-small .avatar.two:nth-child(2) {
  top: 0;
  right: 0;
}

/* Three Avatars */

.container.size-small .avatar.three:nth-child(1) {
  top: 0;
  left: 27%;
}

.container.size-small .avatar.three:nth-child(2) {
  bottom: 0;
  left: 0;
}

.container.size-small .avatar.three:nth-child(3) {
  bottom: 0;
  right: 0;
}
