.continueButton {
  width: 342px;
}

/* Need to use global dig class here to override style */
.continueButton:global(.dig-Button) {
  border-radius: var(--spacing__unit--2);
}

button.extensionUpsellButton {
  margin: var(--dig-spacing__macro__xlarge) 0;
  height: var(--dig-spacing__macro__xlarge);
  border-radius: var(--dig-radius__xlarge);
}

.extensionUpsellVideo {
  width: 570px;
  height: 405px;
  max-width: 100%;
  border-radius: var(--dig-radius__xlarge);
}
