.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  /* this is needed as the normal Layout forces the entire page to be scrollable */
  height: 100vh;
  overflow-y: hidden;
}

.wrapper.isDesktop {
  height: calc(100vh - var(--app-top-nav__height) - 4px);
}

.ComposeAssistantGrid {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.ComposeAssistantOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999; /* Below the toggle button */
}

.ComposeAssistantGridSideBar {
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0 0 auto;
}

.ComposeAssistantGridSidePane {
  position: relative;
  box-sizing: border-box;
  min-width: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.ComposeAssistantGridSidePaneNoResize {
  flex: 1 1 auto;
}

.ComposeAssistantResizeHandleContainer {
  position: absolute;
  height: var(--dig-spacing__macro__xlarge);
  right: 4px;
  top: 50%;
  width: 12px;
  transform: translate(0, -50%);
  right: 8px;
  border-radius: 4px;
  cursor: col-resize;
}

.ComposeAssistantResizeHandle {
  position: absolute;
  height: var(--dig-spacing__macro__xlarge);
  right: 4px;
  top: 50%;
  width: 4px;
  transform: translate(0, -50%);
  background: var(--dig-color__disabled__surface);
  right: 4px;
  border-radius: 4px;
  transition: opacity 0.3s ease;
  cursor: col-resize;
}

.ComposeAssistantResizeHandleFadeOut {
  opacity: 0;
}

/* Right pane styles */
.ComposeAssistantGridRightPane {
  border-left: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-width: 0;
  flex: 0 0 auto;
  width: 56px;
  transform: translateX(calc(100% - 56px));
  transition: transform var(--duration__surface) var(--easing__transition);
}

.ComposeAssistantGridRightPaneDark {
  background: var(--dig-color__background__raised);
}

.ComposeAssistantGridRightPaneExpanded {
  width: 100%;
  min-width: 320px;
  flex: 1 1 auto;
  box-shadow: 0px 4px 12px 0px var(--dig-color__shadow__elevated);
  transform: translateX(0);
}

.RightPaneToggleContainer {
  padding: 16px 12px;
}

.ComposeAssistantMobileContainer {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.ComposeAssistantMobilePane {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.ComposeAssistantMobileToggleButton {
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 1001;
  background: none;
  border: none;
  cursor: pointer;
}
