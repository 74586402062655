.banners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing__unit--2_5);
}

.banner {
  width: 100%;
  max-width: min(1100px, 100%);
  margin: var(--spacing__unit--1) auto;
}

.showBackground {
  background-color: var(--dig-color__primary__surface);
}
