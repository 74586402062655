.container {
  display: flex;
  text-align: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  width: 150px;
  height: 150px;
}

.title {
  margin-top: var(--spacing__unit--2);
  margin-bottom: var(--spacing__unit--1);
}

.actionButton {
  margin-top: var(--spacing__unit--2);
}
