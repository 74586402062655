.ComposeSourceRow {
  display: flex;
  align-items: center;
  border-radius: var(--dig-radius__medium);
  position: relative;
  padding: var(--dig-spacing__micro__medium) 0;
  padding-right: var(--dig-spacing__micro__large);
}

.ComposeSourceRowSmall {
  padding: 8px 0;
  padding-right: 8px;
  margin: 0 4px;
}

.ComposeSourceRowDisabled {
  opacity: 0.6;
}

.ComposeSourceIcon {
  padding: 0 var(--dig-spacing__micro__xlarge);
}

.ComposeSourceRowSmall .ComposeSourceIcon {
  padding-left: var(--dig-spacing__micro__large);
  padding-right: var(--dig-spacing__micro__xlarge);
}

.ComposeSourceLabels {
  flex: 1 1 auto;
  min-width: 0;
}

.ComposeSourceTitle {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: fit-content;
}

.ComposeSourceSubtitle {
  display: flex;
  align-items: center;
  min-width: 100%;
  width: 0;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.ComposeSourceSubtitle > div {
  display: flex;
  align-items: center;
  position: relative;
}

.ComposeSourceSubtitle > div:not(:last-child)::after {
  content: '·';
  margin-left: var(--spacing__unit--0_5); /* spacing before the dot */
  margin-right: var(--spacing__unit--0_5); /* spacing after the dot */
}

.ComposeSourceActionButton {
  flex: 0 0 auto;
  margin-left: 8px;
}

.ComposeSourceLoadProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
