.stackContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 301px;
  height: 289px;
  gap: 0;
  border-radius: var(--spacing__unit--1_5);
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}

.glyphContainer {
  height: 201px;
  width: 301px;
  background: var(
    --teal-BG,
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 100%
    ),
    linear-gradient(90deg, #78cad4 -9.02%, rgba(120, 202, 212, 0) 100%),
    lightgray 50% / cover no-repeat
  );
}

.footerContainer {
  padding: var(--spacing__unit--2);
  width: calc(301px - var(--spacing__unit--4));
  height: calc(88px - var(--spacing__unit--4));
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--2);
  align-items: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}
