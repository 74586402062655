.welcomeModalTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--dig-spacing__dimension__8);
  padding: var(--spacing__unit--0_5) 0;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: 500;
}

.welcomeModalBody {
  padding-bottom: 0;
}
