.contents {
  --gridFullWidth: full-width;
  --gridContent: content;
  --gridXsmallBreakout: xsmall-breakout;
  --gridSmallBreakout: small-breakout;
  --gridStandardBreakout: standard-breakout;
  --gridLargeBreakout: large-breakout;
  --gridXlargeBreakout: xlarge-breakout;

  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns:
    [full-width-start] var(--dig-spacing__micro__xsmall)
    [xlarge-breakout-start] var(--dig-spacing__micro__xsmall)
    [large-breakout-start] var(--dig-spacing__micro__xsmall)
    [standard-breakout-start] var(--dig-spacing__micro__xsmall)
    [small-breakout-start] var(--dig-spacing__micro__xsmall)
    [xsmall-breakout-start] var(--dig-spacing__micro__xsmall)
    [content-start] minmax(0, 1fr) [content-end]
    var(--dig-spacing__micro__xsmall) [xsmall-breakout-end]
    var(--dig-spacing__micro__xsmall) [small-breakout-end]
    var(--dig-spacing__micro__xsmall) [standard-breakout-end]
    var(--dig-spacing__micro__xsmall) [large-breakout-end]
    var(--dig-spacing__micro__xsmall) [xlarge-breakout-end]
    var(--dig-spacing__micro__xsmall) [full-width-end];
}

/** Sub-contents should span the full width. */
.contents > .contents {
  grid-column: var(--gridFullWidth);
}

.contents > :not(.contents) {
  grid-column: var(--gridContent);
}

.xsmallBreakout > :not(.contents) {
  grid-column: var(--gridXsmallBreakout);
}

.smallBreakout > :not(.contents) {
  grid-column: var(--gridSmallBreakout);
}

.standardBreakout > :not(.contents) {
  grid-column: var(--gridStandardBreakout);
}

.largeBreakout > :not(.contents) {
  grid-column: var(--gridLargeBreakout);
}

.xlargeBreakout > :not(.contents) {
  grid-column: var(--gridXlargeBreakout);
}

/** Exported to be targeted by the Card's CSS */
.xsmallBottomBreakout {
}

/** Exported to be targeted by the Card's CSS */
.smallBottomBreakout {
}

/** Exported to be targeted by the Card's CSS */
.standardBottomBreakout {
}

/** Exported to be targeted by the Card's CSS */
.largeBottomBreakout {
}

/** Exported to be targeted by the Card's CSS */
.xlargeBottomBreakout {
}
