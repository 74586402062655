.onboardingHeaderContainer {
  margin-top: 65px;
}

.onboardingHeaderContainer h2 {
  margin-bottom: 0;
}

.atlasGrotesk {
  font-family: 'Atlas Grotesk Web';
}

.bottomContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dig-color__background__base);
}

.textCentered {
  text-align: center;
}
