.SessionsListContent {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  flex: 1 1 auto;
  min-height: 0;
}

.SessionsListTitle {
  padding: 0 var(--dig-spacing__micro__large);
  margin: 0;
  margin-bottom: 16px;
}

.SessionsRows {
  flex: 1 1 auto;
  overflow-y: auto;
}

.SessionsRowsGroup {
  margin-bottom: var(--dig-spacing__micro__xlarge);
}

.SessionsRowsGroupTitle {
  padding: 8px 16px 0px 16px;
  margin: 0;
}

.NewSessionButton {
  margin: 0 var(--dig-spacing__micro__large);
  flex: 0 0 auto;
}

.SessionsRow {
  padding: var(--dig-spacing__micro__small) var(--dig-spacing__micro__large);
  cursor: pointer;
  margin: 6px 0;
  color: var(--dig-color__text__subtle);
}

.SessionsRow:hover,
.SessionsRowSelected {
  border-radius: var(--dig-radius__large);
  background-color: var(--dig-color__opacity__surface);
  color: var(--dig-color__text__base);
}

.SessionRowHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.SessionRowHeaderActions {
  display: none;
  height: 24px;
}

.SessionsRow:hover .SessionRowHeaderActions,
.SessionRowHeaderActions.SessionRowHeaderActionsOpened {
  display: block;
}

.SessionRowIcon {
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
}

.SessionRowTitle {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}

.SessionRowSubtitle {
  margin-bottom: 8px;
}

.SessionsListLoadingState {
  display: flex;
  flex-direction: column;
  padding: 0 var(--dig-spacing__micro__xlarge);
}

.SessionsListSkeletonRow {
  display: flex;
  align-items: center;
  margin-bottom: var(--dig-spacing__micro__small);
  gap: var(--dig-spacing__micro__small);
}
