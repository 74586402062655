.conversationContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  position: relative;
}

.fullPageContainer {
  grid-template-rows: 1fr auto;
}

.header {
  border-bottom: 1px solid var(--dig-color__border__subtle);
  width: var(--flyout-panel-width, 100%);
  min-width: var(--flyout-panel-min-width);
  max-width: var(--flyout-panel-max-width);
}

.contentWrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.queryInputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: var(--dig-spacing__micro__large) 0;
  border-top: 1px solid var(--dig-color__border__subtle);
}

.resetContainer {
  display: flex;
  margin-top: var(--spacing__unit--2);
  margin-left: var(--spacing__unit--3);
}

.spinnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__xlarge);
}

.spinner {
  margin-right: var(--spacing__unit--1);
}

.contentText {
  word-break: break-word;
}

.fullPageContentWrapper {
  width: calc(100% - 48px);
}
