div.container {
  padding-top: 0;
  padding-bottom: 0;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--spacing__unit--1_5);
  padding-right: var(--spacing__unit--1_5);
}

.headerContainer [class^='dig']:where(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.buttonContainer {
  display: flex;
  gap: var(--spacing__unit--1);
}
