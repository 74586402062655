.tabHeaderContainer {
  height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dig-spacing__micro__small);
  padding-left: var(--dig-spacing__micro__xsmall);
  padding-right: var(--dig-spacing__micro__medium);
}

.tabLabelWrapper {
  padding: 0px var(--dig-spacing__micro__small);
  &:hover {
    cursor: pointer;
  }
}

.tabLabelUnderline {
  border-bottom: 2px solid transparent;
  padding-bottom: 2px;
  &.isSelected {
    border-bottom: 2px solid var(--dig-color__text__base);
  }
}
