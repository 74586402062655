.radioListItem {
  width: 24px;
  height: 24px;
  margin: 0 16px 4px -1px;
  padding-right: 1px;
  display: flex;
  align-items: center;
}

.radioButton {
  cursor: default;
}
