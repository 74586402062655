.overlayContainer {
  position: relative;
}

.overlay {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.barrier {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0);
}

.showOverlay,
.overlayContainer:hover .hoverShowOverlay {
  visibility: visible;
}

.blur,
.overlayContainer:hover .hoverBlur {
  filter: blur(3px);
}
