.suggestionsContainer,
.suggestionsContainer:focus {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1_5);
  align-items: center;
  padding: var(--dig-spacing__micro__medium)
    calc(16px - var(--add-item-focus-ring-border-size-half))
    var(--dig-spacing__micro__medium) 10px;
  margin: var(--add-item-focus-ring-border-size-half);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  justify-content: space-between;
  width: calc(100% - var(--add-item-focus-ring-border-size));
  --dash-radius__component: var(--dash-radius__level-3);
}

.suggestionsContainer:hover {
  background-color: var(--dig-color__secondary__surface);
}

.suggestionsLeftContent {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
  padding: var(--spacing__unit--0_5) 0;
  flex: 1;
}

.suggestionsButton {
  display: none;
}

.noHover .suggestionsButton,
.suggestionsContainer:hover .suggestionsButton,
.suggestionsContainer:focus-within .suggestionsButton {
  display: unset;
}

.suggestionText {
  word-break: break-word;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
}

.linkFavicon {
  width: var(--spacing__unit--3);
  height: var(--spacing__unit--3);
}

.openSuggestionIcon {
  flex-shrink: 0;
}
