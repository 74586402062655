.pageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contentContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  padding: 0 35px;
  max-width: min(1100px, 100%);
  margin: 0 auto;
}

.settingsTitle {
  margin: 0;
  padding: var(--spacing__unit--5) 0 var(--spacing__unit--4) 0;
}

.settingsTabs {
  padding: 0;
  flex: 1 1 auto;
}

.overflowContainer {
  padding-top: var(--spacing__unit--0_5);
  overflow: auto;
}

.tabName {
  user-select: none;
}

.settingsContainer {
  flex: 1 1 auto;
  padding-top: var(--spacing__unit--4);
  margin-bottom: var(--spacing__unit--4);
}

.manageAppsInBrowserContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  justify-items: center;
}

.manageAppsMessageTitle {
  margin-bottom: 5px;
}

.iconContainer {
  width: 64px;
  height: 64px;
}

.manageAppsButton {
  margin-top: 35px;
}

.internalTabName {
  color: var(--dig-color__primary__base);
}

.internalTabName:hover {
  color: var(--dig-color__primary__base--state-2);
}
