.dragIconContainer {
  position: absolute;
  top: 0px;
  width: 20px;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  visibility: hidden;
}

.overlayContainer:hover .dragIconContainer {
  visibility: visible;
}

.overlayContainer {
  position: relative;
}
