.menuItem {
  min-width: 240px;
}

.leftAccessory {
  display: flex;
  gap: 8px;
}

.activeFilterIconContainer {
  display: flex;
  gap: 8px;
  margin-right: var(--spacing__unit--1);
}
