.pinnedWrapper {
  --dash-tile-card-grid__card-height: 168px;
  position: relative;
}

.infoRow {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__dimension__4);
}

.title {
  margin-top: var(--dig-spacing__dimension__8);
}

.accessIcon {
  padding-top: var(--dig-spacing__dimension__2);
}

.interactiveAccessory {
  top: var(--dig-spacing__micro__xlarge);
}

.pinnedCard .interactiveAccessory {
  opacity: 0;
  transition: opacity var(--easing-linear) var(--duration__non-motion);
}

.pinnedCard:hover .interactiveAccessory,
.pinnedCard:focus-within .interactiveAccessory {
  opacity: 1;
}

.pinnedCardMetadata {
  margin-top: var(--dig-spacing__dimension__10);
  gap: var(--dig-spacing__dimension__2);
}

.pinButtonIcon {
  line-height: 0;
  display: block;
}

.tileGridWithDraggablePinnedCards {
  column-gap: 0;
  margin-left: calc(var(--dig-spacing__dimension__8) * -1);
  margin-right: calc(var(--dig-spacing__dimension__8) * -1);
}
