.errorBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.errorBoxWrapper {
  display: flex;
  flex-direction: column;
}

.iconContainer {
  width: 160px;
  height: 160px;
}
