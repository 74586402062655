.SourcesFromSettingsThreeContent {
  padding: 32px;
  padding-bottom: 0;
  height: 694px;
}

.SourcesFromSettingsThreeHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
  align-self: stretch;
}

.SourcesFromSettingsThreeMobile {
  height: calc(100vh - 82px);
}

.SourcesFromSettingsThreeTitle {
  margin: 0;
}

.SourcesFromSettingsThreeInputLabel {
  margin-top: 32px;
  padding: 4px 0px 8px;
}
