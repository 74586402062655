.wrapper {
  margin-bottom: 64px;
}

.centerX {
  display: flex;
  justify-content: center;
}

.horizontalSeparator {
  height: 1px;
  border-width: 0;
  background-color: var(--dig-color__border__subtle);
  margin-top: 0;
}
