.container {
  display: grid;
  grid-template-columns:
    1fr var(--dig-spacing__macro__medium)
    var(--dig-spacing__macro__medium);
  gap: var(--dig-spacing__micro__xsmall);
  align-items: center;
  padding: var(--dig-spacing__micro__large);
}

.titleContainer {
  display: flex;
  align-items: center;
  color: var(--dig-color__text__base);
  gap: var(--dig-spacing__micro__medium);

  grid-column: 1;
  justify-self: left;
}
