.titleContainer {
  display: flex;
  gap: var(--spacing__unit--1);
}

.titleContainer > h2 {
  margin: 0;
}

.titleSizeXSmall {
  font-size: var(--dig-type__size__text__medium);
}

.sortTitleLabel {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--0_5);
}
