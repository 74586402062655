.banner {
  margin-bottom: var(--spacing__unit--2);
}

.checkmarkIcon {
  position: relative;
}

.checkmarkIcon::before {
  /* Give checkmark white background */
  content: '';
  background-color: var(--dig-color__primary-cta__on-base);
  display: block;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 5px;
  left: 2px;
  border-radius: var(--dash-radius__component);
  z-index: -1;
}

.primaryAsset {
  max-height: 140px;
}
