.icon {
  margin-right: 2px;
  display: block;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.pathOverflowIcon {
  color: var(--dig-color__text__subtle);
  fill: var(--dig-color__text__subtle);
}
