/* Placeholder style indicating that hover is not supported. */
.noHover {
  padding: 0;
}

.wrapper {
  max-width: min(411px, 100vw);
  min-width: min(411px, 100vw);

  /* Define vars for use in other places. */
  --add-item-box-border-radius: 10px;
  --add-item-focus-ring-border-size: 2px;
  --add-item-focus-ring-border-size-half: calc(
    var(--add-item-focus-ring-border-size) / 2
  );
  --add-item-box-shadow-size: 12px;
  --add-item-box-margin: calc(var(--add-item-box-shadow-size) / 2);

  --dash-radius__component: var(--dash-radius__level-1);
}

@media (max-width: 450px) {
  .wrapper {
    max-width: calc(100vw - 96px); /* 24px (left) + 8px (right) + 64px (icon) */
    min-width: calc(100vw - 96px);
  }
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-TextInputContainer):has(input:focus),
.wrapper :global(.dig-Link):focus,
.wrapper :global(.dig-IconButton):focus {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-TextInputContainer):has(input:focus-visible) {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-Link):focus-visible,
.wrapper :global(.dig-IconButton):focus-visible {
  box-shadow: 0 0 0 var(--add-item-focus-ring-border-size)
    var(--dig-color__border__base);
}

/* Make container box transparent. */
.wrapper > * {
  background: transparent;
  box-shadow: none;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 6px;

  /* Allow the box shadow to not get cut off. */
  padding: var(--add-item-box-shadow-size);
  margin: calc(-1 * var(--add-item-box-shadow-size));
}

.box {
  border-radius: var(--dash-radius__component);
  border: 1px solid var(--dig-color__border__base);
  background-color: var(--dig-color__background__base);
  box-shadow: var(--dig-utilities__boxshadow__raised);
}

.searchTextInputWrapper {
  box-sizing: border-box;
  padding: var(--spacing__unit--1_5)
    calc(18px - var(--add-item-focus-ring-border-size-half));
  margin: var(--add-item-focus-ring-border-size-half);
  width: calc(100% - var(--add-item-focus-ring-border-size));

  --dash-radius__component: var(--dash-radius__level-3);
}

.searchIcon,
.pasteIcon {
  margin-right: var(--spacing__unit--1_5);
}

.linkFavicon {
  width: var(--spacing__unit--3);
  height: var(--spacing__unit--3);
}

.openSuggestionIcon {
  flex-shrink: 0;
}

.suggestionText {
  word-break: break-word;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
}

.suggestionsContainer,
.suggestionsContainer:focus {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1_5);
  align-items: center;
  padding: calc(8px - var(--add-item-focus-ring-border-size-half)) 9px
    calc(8px - var(--add-item-focus-ring-border-size-half)) 14px;
  margin: var(--add-item-focus-ring-border-size-half);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  justify-content: space-between;
  width: calc(100% - var(--add-item-focus-ring-border-size));

  --dash-radius__component: var(--dash-radius__level-3);
}

.suggestionsLeftContent {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
  padding: var(--spacing__unit--0_5) 0;
}

.suggestionsAddButton {
  display: none;
}

.noHover .suggestionsAddButton,
.suggestionsContainer:hover .suggestionsAddButton,
.suggestionsContainer:focus-within .suggestionsAddButton {
  display: unset;
}

.suggestionsContainer:last-child {
  border-bottom-left-radius: var(--add-item-box-border-radius);
  border-bottom-right-radius: var(--add-item-box-border-radius);
}

.suggestionsContainer:hover,
.suggestionsContainer:active,
.suggestionsContainer:focus-within {
  background-color: var(--dig-color__background__subtle);
}

.noSearchResults {
  display: flex;
  height: 48px;
  align-items: center;
  margin-left: 48px;
}

.pasteLinkInputWrapper {
  box-sizing: border-box;
  padding: calc(16px - var(--add-item-focus-ring-border-size-half));
  margin: var(--add-item-focus-ring-border-size-half);
  border-radius: var(--add-item-box-border-radius);
  width: calc(100% - var(--add-item-focus-ring-border-size));
  padding-right: 11px;

  --dash-radius__component: var(--dash-radius__level-3);
}

.pasteLinkAddButton {
  display: none;
}

.noHover .pasteLinkAddButton,
.pasteLinkInputWrapper:hover .pasteLinkAddButton,
.pasteLinkInputWrapper:focus-within .pasteLinkAddButton {
  display: unset;
}
