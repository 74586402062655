.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.root {
  --line-height: 0.75;

  text-transform: uppercase;
}

.rootSizeSmall {
  --named-day-font-size: 7px;
  --numeric-day-font-size: 12px;
  --gap: 2px;
}

.rootSizeMedium {
  --numeric-day-font-size: 14px;
  --named-day-font-size: 8px;
  --gap: 3px;
}

.rootSizeLarge {
  --numeric-day-font-size: 16px;
  --named-day-font-size: 9px;
  --gap: 4px;
}

.rootSizeXLarge {
  --numeric-day-font-size: 20px;
  --named-day-font-size: 11px;
  --gap: 5px;
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date > * + * {
  margin-top: var(--gap);
}

.numericDay {
  font-size: var(--numeric-day-font-size);
  line-height: var(--line-height);
}

.namedDay {
  font-size: var(--named-day-font-size);
  line-height: var(--line-height);
}
