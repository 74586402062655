.container {
  /* responsive flyout panel expansion */
  /* set these raw numbers to the min and max width and breakpoints */
  --flyout-panel-raw-min-width: 350;
  --flyout-panel-raw-max-width: 500;
  --flyout-panel-raw-width-transform-start: 1140;
  --flyout-panel-raw-width-transform-end: 1380;

  --flyout-panel-width-transform-end: calc(
    var(--flyout-panel-raw-width-transform-end) * 1px
  );
  --flyout-panel-width-transform-start: calc(
    var(--flyout-panel-raw-width-transform-start) * 1px
  );

  --flyout-panel-max-width: calc(var(--flyout-panel-raw-max-width) * 1px);
  --flyout-panel-min-width: calc(var(--flyout-panel-raw-min-width) * 1px);

  --flyout-panel-width: calc(
    var(--flyout-panel-min-width) +
      (var(--flyout-panel-raw-max-width) - var(--flyout-panel-raw-min-width)) *
      (
        (100vw - var(--flyout-panel-width-transform-start)) /
          (
            var(--flyout-panel-raw-width-transform-end) -
              var(--flyout-panel-raw-width-transform-start)
          )
      )
  );
  /* end responsive flyout panel expansion */

  --flyout-panel-scroll-offset: 20px;
  --flyout-panel-height: calc(
    100vh - var(--dash-win32-app-titlebar-height, 0px)
  );
}

.container {
  --panel-easing: var(--easing__leave);
  position: fixed;
  z-index: 150;
  right: calc(-1 * var(--flyout-panel-width));
  top: var(--dash-win32-app-titlebar-height, 0px);
  height: var(--flyout-panel-height);
  width: var(--flyout-panel-width);
  min-width: 0;
  max-width: var(--flyout-panel-max-width);
  transition: right var(--duration__333) var(--panel-easing);
  border-radius: 0;
}

.container.isOpen {
  --panel-easing: var(--easing__enter);
  min-width: var(--flyout-panel-min-width);
  right: 0;
  box-sizing: border-box;
  background: var(--dig-color__background__base);
  overflow-y: auto;
  display: flex;
  justify-content: flex-end;
  box-shadow: var(--boxshadow__elevation2);
}

.inner {
  height: var(--flyout-panel-height);
  box-sizing: border-box;
  overflow-x: hidden;
  background: var(--dig-color__background__base);
}

.isOpen .inner {
  width: var(--flyout-panel-width);
  min-width: var(--flyout-panel-min-width);
  max-width: var(--flyout-panel-max-width);
  width: 100%;
}

.isOpenMobile .inner {
  min-width: 100%;
}

.container.isOpen.isOpenMobile {
  transition: none;
  margin-left: 0;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 150;
  border-radius: 0;

  display: flex;
  justify-content: center;
}

.container.isOpen.isOpenMobile .inner {
  padding: var(--spacing__unit--2);
}
