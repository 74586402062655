/* Placeholder style indicating that hover is not supported. */
.noHover {
  padding: 0;
}

.wrapper {
  max-width: min(411px, 100vw);
  min-width: min(411px, 100vw);

  /* Define vars for use in other places. */
  --add-item-box-border-radius: 10px;
  --add-item-focus-ring-border-size: 2px;
  --add-item-focus-ring-border-size-half: calc(
    var(--add-item-focus-ring-border-size) / 2
  );
  --add-item-box-shadow-size: 12px;
  --add-item-box-margin: calc(var(--add-item-box-shadow-size) / 2);

  /* Move to cover the icon */
  transform: translate(calc(-1 * (32px + 4px + 10px)), -4px);
  position: absolute;
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-TextInputContainer):has(input:focus),
.wrapper :global(.dig-Link):focus,
.wrapper :global(.dig-IconButton):focus {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-TextInputContainer):has(input:focus-visible) {
  box-shadow: none;
}

/* Make container box transparent. */
.wrapper > * {
  background: transparent;
  box-shadow: none;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 6px;

  /* Allow the box shadow to not get cut off. */
  padding: var(--add-item-box-shadow-size);
  margin: calc(-1 * var(--add-item-box-shadow-size));
}

.box {
  border-radius: 8px;
  border: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  overflow: hidden;
  padding: 0;
}

.searchInputContainer {
  padding: calc(8px - 5px) var(--spacing__unit--2);
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
}

.searchTextInputWrapper {
  padding-left: 0px;
}

.searchIcon,
.pasteIcon {
  margin-right: var(--spacing__unit--1_5);
}

.resultText {
  word-break: break-all;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
}

.timeAgoText {
  white-space: nowrap;
  display: inline;
}

.resultContent {
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
  padding-right: var(--spacing__unit--1);
}

.suggestionsAddButton {
  display: none;
}

.resultContainer,
.resultContainer:focus {
  font-family: var(--dig-type__bodyfontstack);
  width: 100%;
  margin: var(--add-item-focus-ring-border-size-half);
  height: 48px;
  box-sizing: border-box;
  padding: var(--spacing__unit--1) var(--spacing__unit--2);
  overflow-x: hidden;
  overflow: hidden;
  flex-wrap: nowrap;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--dig-color__text__base);
  background-color: var(--dig-color__background__base);
}

.resultContainer.selected {
  background-color: var(--dig-color__secondary__on-base);
}

.resultContainer.darkMode.selected {
  background-color: var(--dig-color__secondary__surface);
}
