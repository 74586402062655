.listItem {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.listItem:nth-child(even) {
  background-color: var(--dig-color__background__subtle);
}

.eventListContent {
  width: 100%;
  cursor: pointer;
  padding: 10px;

  &:hover,
  &:active {
    background-color: var(--dig-color__opacity__surface);
  }
}

.listItemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
}

.pre {
  white-space: pre-wrap;
  word-break: break-all;
  padding-left: 1em;
}

.uuid {
  color: var(--dig-color__primary__base);
  font-weight: bold;
}

.string {
  color: var(--dig-color__success__base);
  font-weight: bold;
}

.number {
  color: var(--dig-color__alert__base--state-1);
  font-weight: bold;
}

.boolean {
  color: blue;
  color: var(--dig-color__primary__base);
  font-weight: bold;
}

.null {
  color: magenta;
  font-weight: bold;
}

.key {
  color: red;
}
