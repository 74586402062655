.name {
  color: var(--dig-color__identity__on-yellow);
  font-size: 36.45px;
  font-style: normal;
  font-weight: 500;
  line-height: 48.6px; /* 133.333% */
  letter-spacing: -0.729px;
  padding: 16px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  height: 59px;
  justify-content: center;
  align-items: center;
}
