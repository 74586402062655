.emptyState {
  height: 268px;
  gap: var(--spacing__unit--1);
}

.emptyStateTitle,
.emptyStateMessage {
  color: var(--dig-color__text__subtle);
}

.emptyStateTitle {
  margin-top: var(--spacing__unit--2);
}

.iconContainer {
  width: 64px;
  height: 64px;
}
