.SourcesFromSettingsModalStepperWindow :global(.dig-Modal-close-btn) {
  border-radius: var(--dig-radius__small);
  right: 12px;
  top: 12px;
}

.SourcesFromSettingsModalStepperBody {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px;
}

.SourcesFromSettingsModalStepperFooter {
  align-items: center;
  background: var(--dig-color__background__base);
  border-top: 1px solid var(--dig-color__border__subtle);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  gap: var(--dig-spacing__macro__small);
  justify-content: space-between;
  padding-left: 20px;
  padding: var(--dig-spacing__micro__small);
  position: sticky;
  border-radius: 0 0 var(--dig-radius__large) var(--dig-radius__large);
}

.SourcesFromSettingsModalStepperFooterCta {
  margin-right: var(--dig-spacing__micro__small);
}

.SourcesFromSettingsModalStepperFooterCount {
  margin-right: var(--dig-spacing__micro__xlarge);
}

.SourcesFromSettingsModalStepperLabel {
  padding-left: var(--dig-spacing__micro__medium);
  position: sticky;
}
