.modalWindow :global .dig-Modal-content {
  border-radius: var(--dash-radius__component);
}

.modalBody {
  width: 100%;
  box-sizing: border-box;
  padding: 0px var(--dig-spacing__micro__medium)
    var(--dig-spacing__macro__medium) var(--dig-spacing__macro__small);
  overflow-y: auto;
  scrollbar-color: var(--dig-color__border__base) transparent;
}

.modalTitle {
  padding-top: var(--dig-spacing__micro__large);
}

.modalSpotlight {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  border-top-right-radius: var(--dash-radius__component);
  border-top-left-radius: var(--dash-radius__component);
}
