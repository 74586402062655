.divider {
  height: 1px;
  margin: var(--spacing__unit--2_5) var(--spacing__unit--0_5);
  background-color: var(--dig-color__border__subtle);
  flex-shrink: 0;
}

.header {
  margin-left: var(--spacing__unit--1_5);
  margin-right: var(--spacing__unit--1_5);
}

.meetingTimeContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}
