.container {
  padding-top: var(--spacing__unit--1);
  background-color: var(--dig-color__background__raised);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  color-scheme: var(--dig-color-scheme);
  outline: 1px solid var(--dig-color__border__subtle);
  overflow-y: auto;
  width: 360px;
  border-radius: var(--dig-radius__medium);
}

.input {
  padding: 0px 6px var(--dig-spacing__micro__small) 6px;
  border-bottom: solid 1px var(--dig-color__border__subtle);
}

.input .dig-TextInputContainer {
  border: none;
}
