.cardContainer {
  padding-top: var(--spacing__unit--2);
  padding-left: var(--spacing__unit--2);
  padding-bottom: var(--spacing__unit--2);
  padding-right: var(--spacing__unit--5);
  height: calc(100% - var(--spacing__unit--4));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
}

.cardContainerMarch {
  padding-right: var(--spacing__unit--2);
  justify-content: space-between;
  gap: var(--spacing__unit--1_5);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: var(--spacing__unit--0_5);
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
  margin: 0px;

  max-width: 100%;
  word-break: break-word;
}

.itemCountContainer {
  position: absolute;
  left: var(--spacing__unit--2);
  top: var(--spacing__unit--2);
  background-color: var(--dig-color__background__base);
  border-radius: 100px;
  border: 1px solid var(--dig-color__border__subtle);
  padding: 3px var(--spacing__unit--1);
}

.infoRow {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--0_5);
  white-space: nowrap;
}

.tooltipContent {
  display: inline-block;
}

.pinIcon {
  margin-bottom: -3px;
}

.accessIcon {
  margin-bottom: -3px;
  margin-right: 3px;
}

.tooltipContainer {
  width: 100%;
}

.facepile {
  margin-left: 8px;
}

.stackLinks {
  margin-left: calc(-1 * var(--dash-link-list__link__padding-left));
  margin-right: calc(-1 * var(--dash-link-list__link__padding-right));

  justify-content: flex-end;
}
