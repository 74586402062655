.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--spacing__unit--1);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--2);
}

.text {
  margin: 0;
  padding: 0;
  display: block;
}
