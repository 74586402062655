.question {
  cursor: pointer;
}

/* TODO share with conversation module */
.sourcesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  user-select: none;
  overflow: hidden;
  padding-top: var(--dig-spacing__dimension__20);
}

.sourcesContainer > div {
  /* remove padding because the answers container already has padding */
  padding: 0;
}
