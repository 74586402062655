.container {
  position: fixed;
  bottom: var(--spacing__unit--4);
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 15;
}

.snackbar {
  border-radius: var(--dash-radius__level-2);
  pointer-events: initial;
}

.message {
  min-width: 200px;
}
