.stepperContainer {
  display: flex;
  gap: var(--spacing__unit--0_5);
}

.step,
.step:after {
  border-radius: 20px;
  height: 4px;
  min-width: 32px;
  max-width: 120px;
  flex: 1 1 auto;
}

.step {
  background: var(--dig-color__secondary__surface--state-1);
  position: relative;
  overflow: hidden;
}

.step:after {
  content: '';
  background: var(--dig-color__text__base);
  position: absolute;
  width: 100%;
  transform: translateX(-100%);
  transition: transform 750ms;
}

.step.filled:after {
  transform: translateX(0);
}
