.eventList {
  overflow: hidden;
}

/* Apply divider to bottom of each .eventList that has an immediately following sibling */
.eventList:has(+ div):after {
  content: '';
  display: block;
  margin: 0 var(--dig-spacing__micro__small) 0;
  border-bottom: 1px solid var(--dig-color__border__subtle);
  padding-top: var(--dig-spacing__micro__large);
  margin-bottom: var(--dig-spacing__micro__large);
}

.eventList + div {
  margin-top: 0;
}

.eventListItem {
  padding: var(--dig-spacing__micro__small);
  border-radius: var(--dash-radius__component);
  overflow-wrap: break-word;
}
