.SourcesFromSettingsSharingToggleHeader {
  margin-top: var(--dig-spacing__macro__medium);
}

.SourcesFromSettingsSharingToggleLabel {
  margin-bottom: var(--dig-spacing__micro__medium);
  color: var(--dig-color__text__subtle);
}

.SourcesFromSettingsSharingToggleContainer {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__dimension__6);
}

.SourcesFromSettingsSharingToggleRadio {
  margin: 0;
}
