.magicFlagActions {
  text-align: right;
  display: flex;
  gap: 15px;
  justify-content: end;
}

.magicFlagTable {
  display: grid;
  grid-template-columns: 2fr 1fr 75px;
  align-items: center;
  gap: var(--spacing__unit--1);
  padding: var(--spacing__unit--1) 0;
}
