.pageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--dig-color__background__subtle);
  overflow: hidden;
}

.tileContainer {
  position: absolute;
  height: 120px;
  width: 120px;
  background-color: var(--dig-color__background__raised);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  border-radius: var(--dig-spacing__micro__medium);

  display: flex;
  justify-content: center;
  align-items: center;
}

.fileIcon {
  width: 120px;
  height: 120px;
}

.dbxTile {
  z-index: 10;
}
