.keyCapContainer {
  font-family: var(--type__body__monospace--fontfamily);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  color: var(--color__faint__text);
  background-color: var(--color__elevated__background);
  border-color: var(--color__faint__border);
}

.separateKeys {
  border-radius: var(--dig-spacing__dimension__4);
  min-width: var(--dig-spacing__dimension__24);
  min-height: var(--dig-spacing__dimension__24);
  border: 1px solid var(--dig-color__border__subtle);
  padding: 0px var(--dig-spacing__dimension__4);
}

/* casing */

.lowercase {
  text-transform: lower;
}

.uppercase {
  text-transform: upper;
}

.capitalize {
  text-transform: capitalize;
}

/* symbol */

.symbol {
  font-size: 18px;
  margin-top: 1px;
}
