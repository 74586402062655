:root {
  --global-nav-collapsed-width: 64px;
  --global-nav-expanded-width: 260px;
}

.globalNav {
  width: var(--global-nav-collapsed-width);
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow-x: hidden;
  overflow-y: auto;
  transition:
    box-shadow var(--duration__surface) var(--easing__transition),
    width var(--duration__surface) var(--easing__transition);
  height: 100%;
  position: absolute;
  z-index: 100;
  background-color: var(--dig-color__background__base);
  /* Line up nav logo with center of search header on web */
  padding-top: var(--spacing__unit--2);
}

.globalNav.alwaysCollapsed {
  background-color: var(--dig-color__background__subtle);
  padding-top: var(--spacing__unit--2);
}

.globalNav.notAlwaysCollapsed {
  border-right: 1px solid var(--dig-color__border__subtle);
}

.globalNav.expanded,
.globalNav.hovered {
  width: var(--global-nav-expanded-width);
}

.globalNav.mobileCollapsed {
  width: 0;
}

.globalNav.hovered {
  box-shadow: var(--dig-utilities__boxshadow__floating);
}

.globalNav.hovered.expanded {
  box-shadow: none;
}

.mobileCloseButton {
  margin-left: var(--spacing__unit--0_5);
}

.globalNavLogoButton {
  margin-left: var(--spacing__unit--0_5);
}

.globalNavLogoParent {
  display: flex;
  max-width: 180px;
  height: var(--dig-spacing__macro__xlarge);
  align-items: center;
  margin-left: var(--spacing__unit--0_5);
  text-decoration: none;
}

.globalNavLogo {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  transition: all var(--duration__surface) var(--easing__transition);
  position: relative;
  width: 32px;
  color: var(--dig-color__text__base);
  align-items: center;
}

.betaLogo {
  user-select: none;
  text-decoration: none;
  transition: opacity var(--duration__surface) var(--easing__transition);
}

.globalNavIcon {
  height: 32px;
  width: 32px;
  transition: opacity var(--duration__surface) var(--easing__transition);
}

.globalNavWordmark {
  display: flex;
  align-items: center;
  transform: translateX(-36px);
  transition: opacity var(--duration__surface) var(--easing__transition);
  opacity: 0;
  color: var(--dig-color__text__base);
}

.globalNav.expanded .betaLogo,
.globalNav.hovered .betaLogo {
  opacity: 0;
}

.globalNav.expanded .globalNavIcon,
.globalNav.hovered .globalNavIcon {
  opacity: 0;
}

.globalNav.expanded .globalNavWordmark,
.globalNav.hovered .globalNavWordmark {
  opacity: 1;
}

.globalNav.expanded .globalNavLogo,
.globalNav.hovered .globalNavLogo {
  max-width: calc(var(--global-nav-expanded-width) - var(--spacing__unit--2_5));
}

.expandButton {
  --dash-radius__component: var(--dash-radius__level-2);
}

.globalNavStacks {
  overflow-y: auto;
  transition: opacity var(--duration__surface) var(--easing__transition);
  min-height: 130px;
  /* Disallow horizontal scrolling to avoid horizontal scrollbar when vertical
   * scrollbar is visible
   */
  overflow-x: hidden;
}

.globalNavStacksLabel {
  padding: var(--dig-spacing__micro__small);
  position: relative;
}

.globalNavStacksLabel * {
  opacity: 0;
  transition: opacity var(--duration__surface) var(--easing__transition);
}

.globalNavStacksLabel:before {
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  left: 8;
  width: 24px;
  height: 1px;
  background-color: var(--dig-color__border__subtle);
  transition: opacity var(--duration__surface) var(--easing__transition);
}

.showOnHover {
  opacity: 0;
  transition: opacity var(--duration__surface) var(--easing__transition);
}

.globalNav.expanded .showOnHover,
.globalNav.hovered .showOnHover {
  opacity: 1;
}

.globalNav.expanded .globalNavStacks .globalNavListItem *,
.globalNav.hovered .globalNavStacks .globalNavListItem * {
  opacity: 1;
}

.globalNav.expanded .globalNavStacks .globalNavStacksLabel:before,
.globalNav.hovered .globalNavStacks .globalNavStacksLabel:before {
  opacity: 0;
}

.globalNav.expanded .globalNavStacks .globalNavStacksLabel *,
.globalNav.hovered .globalNavStacks .globalNavStacksLabel * {
  opacity: 1;
}

.globalNavListItem {
  display: block;
  overflow: hidden;
  border-radius: var(--dig-radius__medium);
  width: auto;
  transition: max-width var(--duration__surface) var(--easing__transition);
  max-width: 40px;
  padding: 0;
  margin-bottom: 2px;
}

.globalNavLinkItem {
  display: flex;
  align-items: center;
  color: var(--dig-color__text__subtle);
  padding: var(--dig-spacing__micro__small);
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  -webkit-user-drag: none;
}

.globalNavLinkItemContent {
  margin-left: calc(
    var(--dig-spacing__micro__small) + var(--dig-spacing__micro__xsmall)
  );
}

.globalNavBoxItem {
  padding: 3px;
  align-items: center;
}

.glob .noHover:hover {
  background-color: none;
}

.globalNavAvatar {
  cursor: default;
  position: relative;
}

.globalNavAvatarLabel {
  display: inline-block;
  text-align: left;
  width: calc(var(--global-nav-expanded-width) - 78px);
}

.globalNavAvatarBadge {
  position: absolute;
  top: 0;
  left: 21px;
  z-index: 1;
}

.globalNavAvatarBadge.expanded {
  top: 10px;
  left: auto;
  right: var(--dig-spacing__micro__small);
}

.globalNav.expanded .globalNavListItem,
.globalNav.hovered .globalNavListItem,
.globalNav.expanded .globalNavAddStackBtn,
.globalNav.hovered .globalNavAddStackBtn {
  max-width: calc(var(--global-nav-expanded-width) - var(--spacing__unit--4));
  width: calc(var(--global-nav-expanded-width) - var(--spacing__unit--4));
}

.globalNavLinkItem.active {
  color: var(--dig-color__text__base);
  background-color: var(--dig-color__opacity__surface);
}

.globalNavLinkItem:hover {
  color: var(--dig-color__text__base);
}

.globalNavListItem:has(> .globalNavLinkItem:focus-visible),
.globalNavAvatarButton:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px var(--dig-color__utility__focus-ring);
}

.globalNavLinkItem.themed {
  background-color: var(--dig-color__primary__surface);
}

.globalNavAddStackBtn {
  padding: var(--dig-spacing__micro__small);
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: var(--dig-radius__medium);
  color: var(--dig-color__text__subtle);
  max-width: 40px;
  overflow: hidden;
  white-space: nowrap;
  transition:
    all var(--duration__non-motion) var(--easing__transition),
    max-width var(--duration__surface) var(--easing__transition);
}

.globalNavAddStackBtn.alwaysCollapsed {
  /* Hover background should not go beyond the add icon.  */
  width: unset;
}

.globalNavAddStackBtn:hover {
  color: var(--dig-color__text__base);
  cursor: pointer;
}

.globalNavAddStackBtnIcon {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--dig-radius__small);
}

.globalNavIconBorder {
  border: solid 1px var(--dig-color__border__subtle);
}

.mobileOverlayFadeIn,
.mobileOverlayFadeOut {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
}

.mobileOverlayFadeIn {
  background-color: var(--dig-color__utility__overlay);
  animation: mobileOverlayFadeInBgColor 0.5s linear;
}

.mobileOverlayFadeOut {
  background-color: transparent;
  animation: mobileOverlayFadeOutBgColor 0.5s linear;
}

@keyframes mobileOverlayFadeInBgColor {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--dig-color__utility__overlay);
  }
}

@keyframes mobileOverlayFadeOutBgColor {
  from {
    background-color: var(--dig-color__utility__overlay);
  }
  to {
    background-color: transparent;
  }
}

.betaBadge {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* this needs a 3-class selector to ensure it overwrites the default styles */
.globalNav.alwaysCollapsed.windowsHornet,
.globalNav.notAlwaysCollapsed.windowsHornet {
  padding-top: var(--spacing__unit--1);
}

.globalNav.alwaysCollapsed.darwinHornet,
.globalNav.notAlwaysCollapsed.darwinHornet {
  padding-top: var(--spacing__unit--3);
}

.globalNavAvatarButton {
  border-radius: var(--dig-spacing__micro__xsmall);
  box-sizing: border-box;
  height: max-content;
  width: 100%;
}

.globalNavAvatarButton > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
  padding: var(--dig-spacing__micro__xsmall);
  height: max-content;
}

.globalNavAvatarSrc {
  margin: 1px 0 1px 1px;
}

.globalNav.disableMouseEvents {
  pointer-events: none;
}
