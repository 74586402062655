.ConversationInput {
  padding: 0px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 760px;
  width: 100%;
  box-sizing: border-box;
}

.ConversationInputContainer {
  display: flex;
  gap: 10px;
  border-radius: var(--dig-radius__xlarge);
  border: 1.5px solid var(--dig-color__border__subtle);
  justify-items: center;
  align-items: center;
  padding-right: 8px;
  &:focus-within {
    box-shadow: var(--dig-utilities__boxshadow__focus-ring),
      var(--dig-utilities__boxshadow__floating);
  }
  position: relative;
  background: var(--dig-color__background__base);
  box-shadow: var(--dig-utilities__boxshadow__raised);
}

.ConversationInputs {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ConversationInputs > * {
  width: 100%;
}

.ConversationInputTextArea {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: var(--dig-spacing__micro__large);
  padding-bottom: var(--dig-spacing__micro__large);
  font-size: var(--dig-type__size__text__large);
  max-height: 260px;
  &:focus {
    box-shadow: none;
  }
}

.ConversationInputTextAreaMinimal {
  padding-bottom: var(--dig-spacing__micro__medium);
}

.ConversationInputSendButton {
  position: absolute;
  right: 8px;
}

.ConversationInputSendButton:disabled {
  color: var(--dig-color__text__base);
  background-color: var(--dig-color__text__subtle);
}

.ConversationInputSendButtonBottomRight {
  bottom: 8px;
}

.ConversationInputContext {
  margin: 12px 0 6px 0;
  padding-left: 26px;
  position: relative;
}

.ConversationInputContext::before {
  content: '';
  position: absolute;
  width: 3px;
  background: var(--dig-color__text__base);
  height: 100%;
  left: 16px;
  top: 0;
}

.ConversationInputSourcesActions {
  margin-bottom: var(--dig-spacing__micro__medium);
  margin-left: var(--dig-spacing__micro__medium);
}

.ConversationInputSourceChipDisabled {
  background: var(--dig-color__background__base);
  color: var(--dig-color__disabled__base);
}

.ConversationInputTextAreaDisabled {
  border: none;
}

.ConversationInputDisclaimer {
  align-self: center;
  font-size: var(--dig-type__size__text__xsmall);
  color: var(--dig-color__text__subtle);
  margin-bottom: var(--dig-spacing__micro__small);
  padding: 0px var(--dig-spacing__micro__medium);
  text-align: center;
}
