body {
  --dash-radius__level-1: var(--dig-radius__xlarge);
  --dash-radius__level-2: var(--dig-radius__medium);
  --dash-radius__level-3: var(--dig-radius__small);

  --dash-radius__favicon: var(--dig-radius__small);
  /**
   * Component level border radius definitions should always use
   * this variable. If it's expected to contain other components
   * as children, then it should redefine the variable with the
   * next highest radius level. Parent would then need to know
   * what layer it should exist at
   *
   * Example:
   * .container {
   *   border-radius: var(--dash-radius__component);
   * }
   *
   * .container * {
   *   --dash-radius__component: var(--dash-radius__level-2);
   * }
  */
  --dash-radius__component: var(--dash-radius__level-1);
}

/* Fixed border radius for all Menu dropdowns */
:global(.dig-Menu-canvas) {
  border-radius: var(--dig-radius__medium, 0);
}

/* Fixed border radius for all icon buttons */
:global(.dig-IconButton--standard:not(.dig-IconButton--circular)) {
  border-radius: var(--dig-radius__xsmall, 0);
}

/* Fixed border radius for all text buttons */
button:global(.dig-Button),
button:global(.dig-Button .dig-Button-content),
a:global(.dig-Button),
a:global(.dig-Button .dig-Button-content) {
  border-radius: var(--dig-radius__small, 0);
}

/* Transparent buttons only show an underline, and cannot have rounded borders */
button:global(.dig-Button.dig-Button--transparent .dig-Button-content),
a:global(.dig-Button.dig-Button--transparent .dig-Button-content) {
  border-radius: 0;
}

:global(.dig-Facepile) > svg {
  top: 0;
}

:global(.dig-Tooltip--basic .dig-Tooltip-content),
:global(.dig-Tooltip--basic .dig-Tooltip-inner),
:global(.dig-Tooltip--rich .dig-Tooltip-content),
:global(.dig-Tooltip--rich .dig-Tooltip-inner) {
  border-radius: var(--dig-radius__medium);
}
