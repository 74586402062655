.container {
  gap: var(--spacing__unit--1);
}

.browserIconContainer {
  width: var(--spacing__unit--3);
  height: var(--spacing__unit--3);
  text-align: center;
  color: var(--dig-color__text__base);
  margin-left: -4px;
}

.title {
  display: flex;
  align-items: center;
  height: var(--spacing__unit--3);
  margin-bottom: 2px;
}

.description {
  margin-bottom: var(--spacing__unit--1);
  color: var(--dig-color__text__subtle);
}
