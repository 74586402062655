.cardWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContainer {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 20px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.cardContainerHardBorder {
  background-color: var(--dig-color__background__subtle);
}

.cardContainerDashedBorder {
  border: 1px dashed var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
}

.cardContainerHardBorder:hover,
.cardContainerDashedBorder:hover {
  background-color: var(--dig-color__opacity__surface);
}

.cardButton {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cardButton * {
  --dash-radius__component: var(--dash-radius__level-2);
}

.accessoryButtonWrapper {
  position: absolute;
  top: var(--spacing__unit--2);
  right: var(--spacing__unit--1_5);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accessoryButtonWrapperAutoSize {
  width: auto;
}

/*
 * The following styles are used to visually hide the accessory button when the
 * card is not focused or hovered. It also hides only if the accessory button
 * is not open. This is necessary to allow the button to be read by screen
 * readers when the user focuses into the card.
 */
.cardWrapper.hoverOk:not(:focus-within):not(:hover)
  .accessoryButton:not(.accessoryButtonOpen) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  width: 1px;
}
