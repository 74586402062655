.atlasGrotesk {
  font-family: 'Atlas Grotesk Web';
}

.privacyLinkButton {
  color: var(--dig-color__text__subtle);
  font-size: 14px;
  font-weight: 400;
}

/* Without use of global dig class here, we cannot override style because dig has more specific css targeting  */
.privacyLinkButton > span:global(.dig-Button-content) {
  border-bottom-color: var(--dig-color__text__subtle);
}

.continueButton {
  width: 342px;
}

/* Need to use global dig class here to override style */
.continueButton:global(.dig-Button) {
  border-radius: var(--spacing__unit--2);
}

.textCentered {
  text-align: center;
}
