.horizontalSeparator {
  height: 1px;
  border-width: 0;
  background-color: var(--dig-color__border__subtle);
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  gap: var(--spacing__unit--2);
}
