:root {
  /* Default tokens for links in a link list regardless of where
   * they are rendered. Can be overridden as needed.
   */
  --dash-link-list__list__gap: 10px;
  --dash-link-list__small-list__gap: 4px;

  --dash-link-list__link__padding-top: 8px;
  --dash-link-list__link__padding-right: 8px;
  --dash-link-list__link__padding-bottom: 8px;
  --dash-link-list__link__padding-left: 8px;
}

.list {
  /**
     * This makes sure only the links we have full room to show will be visible. Rather than
     * overflowing the bottom, flex wrapping means links that don't fit will overflow the right. Since
     * we can ensure individual links match the width of the links container we can guarantee that
     * _columns_ after the first one will be completely hidden. We don't have the same power
     * vertically which could result in clipped links.
     */
  display: flex;
  flex-flow: column wrap;
  overflow: hidden;
  /**
     * At least 8px gap to account for our padding expanding with :focus-within. We keep the gap on at
     * all times — if we only add it on :focus-within we get a bunch of weirdness around tabbing in
     * and out of elements on the last page of links. The links beyond the gap are out of view anyway
     * so the gap can really be any number >= 8px.
     */
  column-gap: 8px;

  /**
     * Selecting an item seems to somehow have an effect on the padding and margin on the list itself. 
     * This should protect us from any unwanted padding and margin.
     *
     * TODO: Verify if this is needed anymore??
     */
  /* padding: 0 !important;
  margin: 0 !important; */
}

.listWithGap {
  gap: var(--spacing__unit--0_5);
}

.marchListWithGap {
  gap: var(--dash-link-list__list__gap);
}

.smallListWithGap {
  gap: var(--dash-link-list__small-list__gap);
}

.overFlowGoToStack {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: var(--spacing__unit--4);
  align-items: center;
}

.linkTitle {
  overflow: hidden;
  line-height: var(--spacing__unit--3);
}

.overFlowText {
  overflow: hidden;
  line-height: var(--spacing__unit--3);
  margin-left: var(--spacing__unit--4);
}

.linkRow {
  width: 100%;
}
