.fullPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 2 * var(--spacing__unit--4));
  padding: var(--spacing__unit--4);
  text-align: center;
}

.iconContainer {
  width: 200px;
  height: 200px;
}
