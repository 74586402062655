.featuresWrapper {
  display: grid;
  grid-template-columns: 2fr minmax(110px, 1fr) 1fr 1fr;
  align-items: center;
  gap: var(--spacing__unit--1);
}

.centerX {
  display: flex;
  justify-content: center;
}

.horizontalSeparator {
  height: 1px;
  border-width: 0;
  background-color: var(--dig-color__border__subtle);
  margin-top: 0;
}

.selectButton {
  min-width: 96px;
}

.buttonWithOverride {
  background-color: var(--dig-color__primary__surface);
}

.overrideAsterisk {
  display: inline-block;
  padding: 0 2px;
}

.serverDisplayValue {
  display: inline-block;
  white-space: pre;
}

.serverDisplayFormatObject {
  font-family: monospace;
  font-size: var(--dig-type__size__text__small);
}

.featureFilterContainer {
  display: flex;
  flex-direction: row;
  width: 400px;
}

.featureFilterInputContainer {
  padding-left: 8px;
  flex-grow: 1;
}

.textSelectable {
  user-select: text;
}
