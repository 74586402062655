.actionRow {
  display: flex;
  flex-direction: row;
  padding-top: var(--spacing__unit--1);
  gap: var(--spacing__unit--1);
  align-items: center;
}

.stepCount {
  flex: 1 1 auto;
}

.stepCount,
.backBtn {
  color: var(--dig-color__text__subtle);
}

.tourTooltip {
  position: relative;
}

.dismissButton {
  position: absolute;
  top: var(--spacing__unit--2);
  right: var(--spacing__unit--2);
  color: var(--dig-color__text__subtle);
}
