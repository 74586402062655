.root {
  display: block;
  overflow: hidden;
}

.root .overflowEllipsis {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
}

.root .isMultiLineTruncate {
  display: -webkit-box;

  /* This CSS var has to be kept in sync with the css var that SimpleTruncate uses. */
  -webkit-line-clamp: var(--truncate-multiple-lines, 2);
  -webkit-box-orient: vertical;
  text-wrap: wrap;
}
