.StaticToolbar {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: var(--dig-spacing__micro__small);
  vertical-align: middle;
}

.Divider {
  width: 1px;
  background-color: var(--dig-color__border__subtle);
  margin: 0 8px 0 10px;
  height: 32px;
}
