.SourcesFromSettingsPreviewPaneContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.SourcesFromSettingsPreviewPaneHeader {
  display: flex;
  justify-content: space-between;
  background: var(--dig-color__background__subtle);
  padding: var(--dig-spacing__micro__medium) 0 0
    var(--dig-spacing__micro__medium);
  width: 100%;
}

.SourcesFromSettingsPreviewPaneSelect {
  align-items: center;
  width: fit-content;
  padding-right: var(--dig-spacing__micro__xlarge);
  display: flex;
}

.SourcesFromSettingsPreviewPaneText {
  overflow: scroll;
  height: 400px;
  padding: var(--dig-spacing__macro__xlarge);
}
