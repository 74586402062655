/* Placeholder style indicating that hover is not supported. */
.noHover {
  padding: 0;
}

.wrapper {
  max-width: min(411px, 100vw);
  min-width: min(411px, 100vw);

  /* Define vars for use in other places. */
  --add-item-box-border-radius: 10px;
  --add-item-focus-ring-border-size: 2px;
  --add-item-focus-ring-border-size-half: calc(
    var(--add-item-focus-ring-border-size) / 2
  );
  --add-item-box-shadow-size: 12px;
  --add-item-box-margin: calc(var(--add-item-box-shadow-size) / 2);
}

.wrapperAugRev {
  max-width: min(546px, 100vw);
  min-width: min(546px, 100vw);

  /* Define vars for use in other places. */
  --add-item-box-shadow-size: 12px;
  --add-item-box-margin: calc(var(--add-item-box-shadow-size) / 2);
}

.wrapper > * {
  overflow: hidden;
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-TextInputContainer):has(input:focus),
.wrapper :global(.dig-Link):focus,
.wrapper :global(.dig-IconButton):focus {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-TextInputContainer):has(input:focus-visible) {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.wrapper :global(.dig-Link):focus-visible,
.wrapper :global(.dig-IconButton):focus-visible {
  box-shadow: 0 0 0 var(--add-item-focus-ring-border-size)
    var(--dig-color__border__base);
}

/* Make container box transparent. */
.wrapper > * {
  background: transparent;
  box-shadow: none;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 6px;

  /* Allow the box shadow to not get cut off. */
  padding: var(--add-item-box-shadow-size);
  margin: calc(-1 * var(--add-item-box-shadow-size));
}

/* Make container box transparent. */
.wrapperAugRev > * {
  background: transparent;
  box-shadow: none;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 6px;

  /* Allow the box shadow to not get cut off. */
  padding: var(--add-item-box-shadow-size);
  margin: calc(-1 * var(--add-item-box-shadow-size));
}

.box {
  border-radius: var(--add-item-box-border-radius);
  border: 1px solid var(--dig-color__border__base);
  background-color: var(--dig-color__background__base);
  box-shadow: var(--dig-utilities__boxshadow__raised);
}

.searchResults {
  border-top: 1px solid var(--dig-color__border__subtle);
  overflow-x: hidden;
  overflow-y: scroll;
}

.searchTextInputWrapper {
  box-sizing: border-box;
  padding: calc(16px - var(--add-item-focus-ring-border-size-half));
  margin: var(--add-item-focus-ring-border-size-half);
  border-top-left-radius: var(--add-item-box-border-radius);
  border-top-right-radius: var(--add-item-box-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: calc(100% - var(--add-item-focus-ring-border-size));

  --dash-radius__component: var(--dash-radius__level-3);
}

.searchIcon,
.pasteIcon,
.paperdocIcon {
  margin-right: var(--spacing__unit--1_5);
}

.noSearchResults {
  display: flex;
  height: 48px;
  align-items: center;
  margin-left: 48px;
}

.inputWrapper {
  box-sizing: border-box;
  padding: calc(16px - var(--add-item-focus-ring-border-size-half));
  margin: var(--add-item-focus-ring-border-size-half);
  border-radius: var(--add-item-box-border-radius);
  width: calc(100% - var(--add-item-focus-ring-border-size));
  padding-right: 11px;

  --dash-radius__component: var(--dash-radius__level-3);
}

.addButton {
  display: none;
}

.noHover .addButton,
.inputWrapper:hover .addButton,
.inputWrapper:focus-within .addButton {
  display: unset;
}
