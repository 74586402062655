.keyboardShortcutFormContainer {
  min-width: 300px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.keyboardShortcutForm {
  display: flex;
  align-items: center;
  gap: 8px;
}

.keyboardShortcutTextInput {
  height: 30px;
  min-width: 200px;
}

.keyboardShortcutButton {
  border-radius: var(--dig-spacing__micro__small);
  height: 40px;
  min-width: 40px;
  background: none;
  border: none;
  color: var(--dig-color__text__subtle);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing__unit--0_5);
}

.keyboardShortcutButton:hover {
  background: var(--dig-color__secondary__surface);
  color: var(--dig-color__text__base);
}

.keyboardShortcutButton:focus-visible {
  background: var(--dig-color__secondary__surface--state-1);
  color: var(--dig-color__text__base);
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
}

.prettyShortcutWrapper {
  display: flex;
  justify-content: flex-end;
  min-width: 60px;
}

/* This component uses Emotion, so !important is required to override the initial styles via a class name */
.listItemRow {
  min-height: 72px !important;
}
