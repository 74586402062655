.connectedAppsIndicatorContainer {
  display: flex;
  gap: var(--dig-spacing__micro__small);
  align-items: center;
  padding: var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__medium);
  transition: 0.3s;
}

.connectedAppsIndicatorContainer.isMobile {
  padding: var(--dig-spacing__micro__xsmall);
}

/* Active state */
.connectedAppsIndicatorContainer:hover,
.connectedAppsIndicatorContainer:focus,
.connectedAppsIndicatorContainer.isMobile {
  background-color: var(--dig-color__background__subtle);
  border-radius: var(--dig-radius__large);
  cursor: pointer;
}
