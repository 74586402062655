.container {
  width: 100%;
  border-radius: 12px;
  background: var(--dig-color__text__base);
  padding: var(--spacing__unit--1) var(--spacing__unit--2);
  margin-top: var(--spacing__unit--0_5);

  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1);
  cursor: initial;
  box-sizing: border-box;
}

.text {
  color: var(--dig-color__background__base);
}
