.actionButton {
  border-radius: var(
    --dig-radius__xlarge
  ) !important /* important is required in order to override dig overrides */;
}

.actionButtonLabel {
  padding-left: var(--spacing__unit--2);
  padding-right: var(--spacing__unit--2);
}

.actionButtonSpacers {
  flex: 1;
}
