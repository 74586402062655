.fancyInlineEdit {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--1);
  width: 100%;
}

.fancyInlineEditInput {
  width: 100%;
}
