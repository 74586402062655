/* stylelint-disable selector-class-pattern */
.root {
  --font-size: var(--dig-type__size__title__small);

  font-family: var(--dig-type__family__title);
  font-size: var(--font-size);
  line-height: 1;
  font-weight: 500;
  overflow: clip;
  white-space: nowrap;
}

/**
 * Safari scales emoji at a different rate than every other browser. 
 * According to https://codepen.io/ticky/full/LyqyQK it scales emoji at around 131% of the font size.
 * So we detect if we're running in Safari and adjust the font size accordingly.
 * This @supports block was found in https://wojtek.im/journal/targeting-safari-with-css-media-query
 */
@supports (hanging-punctuation: first) and (font: -apple-system-body) and
  (-webkit-appearance: none) {
  .root-variant-emoji {
    font-size: calc(var(--font-size) / 1.3125);
  }
}

.root-size-small {
  --font-size: var(--dig-type__size__text__large);
}

.root-size-large,
.root-size-medium {
  --font-size: var(--dig-type__size__text__xlarge);
}

/**
 * Thats right! Emoji's render differently based on screen density
 */
@media (max-resolution: 100dpi) {
  .root-variant-emoji.root-size-small {
    --font-size: 13px;
  }

  .root-variant-emoji.root-size-medium {
    --font-size: 16px;
  }
}

.root-size-xlarge {
  --font-size: 24px;
}

.root-variant-emoji {
}

.root-variant-text {
}
