.SideBarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 0;
  background: rgba(247, 246, 245, 0.5);
  transition: width var(--duration__surface) var(--easing__transition);
}

.SideBarContainerMobileSize {
  position: absolute;
  z-index: 1000;
  width: 270px;
  background-color: var(--dig-color__background__base);
  top: 0;
  left: 0;
  height: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.SideBarContainerDark {
  background: var(--dig-color__background__raised);
}

.SideBarContainerExpanded {
  width: 270px;
  border-right: 1px solid var(--dig-color__border__subtle);
}

.SideBarContainerDarkAndRightPaneExpanded {
  background: var(--dig-color__background__base);
}

.SideBarHeader {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 8px 20px;
}

.SideBarContent {
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
}
