.colorPicker {
  --circle-size: 20px;
  --circle-container-size: calc(
    var(--circle-size) + 2 * var(--dig-spacing__dimension__2)
  );
  --circle-hover-border-width: 2px;
  --circle-hover-position: -2px;
  flex-wrap: wrap;
}

.colorCircleCheck {
  display: none;
}

.colorCircleCheckSelectedState {
  display: block;
}

.colorCircleContainerInput:hover .colorCircle:after,
.colorCircleContainerInput:focus .colorCircle:after {
  content: '';
  border: var(--circle-hover-border-width) solid
    var(--dig-color__primary__surface--state-2);
  border-radius: 50%;
  position: absolute;
  left: var(--circle-hover-position);
  top: var(--circle-hover-position);
  right: var(--circle-hover-position);
  bottom: var(--circle-hover-position);
}

.colorCircleContainerInput {
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--dig-spacing__dimension__2);
}

.colorCircleContainerInput:not([aria-disabled='true']):hover {
  background-color: transparent;
}

.colorCircle {
  border-radius: 50%;
  width: var(--circle-size);
  height: var(--circle-size);
  display: flex;
  cursor: pointer;
  background-color: var(--dig-color__primary__surface);
  border: 1px solid var(--dig-color__border__subtle);
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
