.container {
  background: white;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--4);
  left: 50%;
  max-width: 600px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header {
  align-items: center;
  display: flex;
  gap: var(--spacing__unit--1);
  justify-content: center;
}

.assetContainer {
  height: 128px;
  width: 128px;
}

.noMargin {
  margin: 0;
}

.line {
  background: var(--dig-color__border__subtle);
  border: none;
  height: 1px;
  width: 64px;
}

.footer {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1);
}
