.overlayList {
  max-width: min(300px, 75%);
  background: var(--dig-color__background__raised);
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__medium);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  animation: floatIn var(--duration__surface) var(--easing__transition);
}

@keyframes floatIn {
  0% {
    margin-top: 5px;
    opacity: 0.5;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}

.overlayTitle {
  display: inline-block;
  padding: var(--dig-spacing__micro__xlarge) var(--dig-spacing__micro__xlarge)
    var(--dig-spacing__micro__small);
}

.overlayListContainer {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 var(--dig-spacing__micro__small);

  /**
   * Slightly fade out attendees at the bottom of the list to
   * indicate scrollability.
   */
  mask-image: linear-gradient(180deg, white 91%, transparent);
}

.overlayListItem {
  padding-left: var(--dig-spacing__micro__medium);
  padding-right: var(--dig-spacing__micro__medium);
}

.overlayListItemContent {
  overflow: hidden;
}
