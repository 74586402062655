.avatarRoot {
}

/**
 * This adjusts the medium size of the avatar to better fit in the meta data item.
 * Avatars carry 2px of margin on all sides, so we adjust the size down to 24x24
 * which creates a 20x20 inner icon with 2px of margin on all sides.
 */
.sizeMedium {
  width: 24px;
  height: 24px;
}
