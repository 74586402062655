.privacyModalHeader {
  margin-bottom: 0;
  padding-bottom: 0;
}

.privacyModalBody {
  padding: 0 0 var(--dig-spacing__dimension__16) 0;
}

.accordion {
  gap: var(--dig-spacing__micro__large);
  height: 520px;
  overflow-y: scroll;
  padding: 0 var(--dig-spacing__dimension__24);
}

.accordionItemContainer {
  background-color: var(--dig-color__background__subtle);
  border-radius: var(--dig-spacing__micro__large);
  border: none;
}

.accordionHeader:global(.dig-Accordion-header) > button {
  border-top: none;
  padding: var(--dig-spacing__dimension__8) var(--dig-spacing__dimension__20);
}

.accordionTextContent {
  display: flex;
  flex-direction: column;
  margin: 14px 0;
}

.accordionTextContent:first-child {
  margin-top: 0;
}

.accordionTextContent:last-child {
  margin-bottom: 0;
}

.unorderedList {
  margin: 0;
}
