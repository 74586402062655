.quickLinksContainer {
  margin-bottom: 24px;
}

.quickLinksTitle {
  line-height: 30px;
}

.flexContainer {
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
}

.button {
  border-color: var(--dig-color__border__subtle);
}

.placeHolder {
  width: 40px;
  height: 40px;
}

.faviconImg {
  height: 20px;
  width: 20px;
}
