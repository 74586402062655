.avatar {
  margin: 0;
}

.container {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--0_5);
}

.button {
  text-decoration: underline;
  padding: -0;
  border: none;
  background: transparent;
  cursor: pointer;
}
