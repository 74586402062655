.root {
  display: grid;
  gap: var(--label-group-start-gap, var(--dig-spacing__micro__large));
  grid-template-areas: "start main";
  grid-template-columns: min-content minmax(0, 1fr);
  align-items: start;
}

.startAccessory {
  grid-area: start;
}

.main {
  grid-area: main;
}

.vAlignCenter .startAccessory,
.vAlignCenter .main {
  align-self: center;
}

.main:has(.horizontal) {
  align-self: center;
}

.vAlignOffsetTop .main:not(:has(.horizontal)) {
  align-self: top;
  margin-top: var(--dig-spacing__dimension__4);
}

.labels + .children {
  margin-top: var(--dig-spacing__micro__medium);
}

.labels.vertical > * + * {
  margin-top: var(
    --label-group-accessory-gap,
    var(--dig-spacing__micro__xsmall)
  );
}

.labels.horizontal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.labels.horizontal > * + * {
  margin-left: var(
    --label-group-accessory-gap,
    var(--dig-spacing__micro__medium)
  );
}

.label a {
  color: inherit;
}

.labelText {
  overflow-wrap: break-word;
}
