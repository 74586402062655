.container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  width: 440px;
  background-color: var(--dig-color__background__base);
  box-shadow: 0 0 var(--dig-spacing__dimension__12) #0000001a;
  border-radius: var(--dig-radius__medium) 0 0 0;
  border-left: 1px solid var(--dig-color__border__subtle);
  border-top: 1px solid var(--dig-color__border__subtle);
  padding: var(--dig-spacing__dimension__12);
}

.title {
  display: flex;
  align-items: center;
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--dig-spacing__dimension__8);
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

.buttonGroup {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__dimension__8);
}

.gridContainer {
  display: grid;
  grid-template-columns: 100px auto 24px;
  align-items: center;
  padding-top: var(--dig-spacing__dimension__4);
}

.gridItem {
  user-select: text;
}

.gridItemWithBorder {
  border: 1px solid #f0f0f0;
  margin-bottom: 5px;
}

.gridItemHeader {
  font-weight: bold;
}

.gridItemHeaderAlignTop {
  align-self: start;
}
