.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
}

.qrCode {
  height: 92px;
  width: 92px;
}
