.container {
  display: flex;
  flex-direction: row-reverse;
  border-radius: var(--dash-radius__component);
  border: 1px solid var(--dig-color__border__subtle);
  height: 546px;
  overflow: hidden;
}

.textContentContainer,
.assetContainer {
  flex: 0 0 50%;
}

.assetContainer {
  background-color: var(--dig-color__background__subtle);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.iconContainer {
  width: 200px;
  height: 200px;
}

.textContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 64px;
  box-sizing: border-box;
}

p.message {
  color: var(--dig-color__text__subtle);
  margin: 0;
  padding-bottom: var(--spacing__unit--3);
}

.asset {
  width: 100%;
}

@media (max-width: 599px) {
  .container {
    display: block;
    height: auto;
  }

  .assetContainer {
    padding: var(--spacing__unit--2);
  }

  .textContentContainer {
    padding: var(--spacing__unit--3);
    padding-top: var(--spacing__unit--2);
    justify-content: inherit;
  }

  p.message {
    padding-bottom: var(--spacing__unit--2);
  }
}
