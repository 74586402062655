.gridContainer {
  --dash-grid__col_gap: var(--spacing__unit--2);
  --dash-grid__row_gap: var(--spacing__unit--2);
  --dash-grid__max-width: 1080px;
  --dash-grid__sidebar-min-width: 320px;
  --dash-grid__sidebar-max-width: 320px;
  --dash-grid__sidebar-sticky-width: 27vw;
  --dash-grid-card__row_gap: var(--dash-grid__row_gap);

  display: flex;
  column-gap: var(--dash-grid__col_gap);
  row-gap: var(--dash-grid__row_gap);
  max-width: min(var(--dash-grid__max-width), 100%);
  width: 100%;
  min-width: 0;
  flex-grow: 1;
  flex-direction: column;

  /* Grid Card Specific Tokens */
  --dash-grid-card__left-col__padding-top: 20px;
  --dash-grid-card__left-col__padding-right: 10px;
  --dash-grid-card__left-col__padding-bottom: 15px;
  --dash-grid-card__left-col__padding-left: 10px;

  --dash-grid-card__right-col__padding-top: 20px;
  --dash-grid-card__right-col__padding-right: 8px;
  --dash-grid-card__right-col__padding-bottom: 10px;
  --dash-grid-card__right-col__padding-left: 8px;

  --dash-grid-card__left-col__header-padding-left: var(--spacing__unit--1);
  --dash-grid-card__left-col__header-padding-right: var(--spacing__unit--1);

  --dash-grid-card__right-col__header-padding-left: var(--spacing__unit--2);
  --dash-grid-card__right-col__header-padding-right: var(--spacing__unit--2);

  --dash-grid-card__right-col__link-padding-top: 12px;
  --dash-grid-card__right-col__link-padding-right: 16px;
  --dash-grid-card__right-col__link-padding-bottom: 12px;
  --dash-grid-card__right-col__link-padding-left: 16px;

  --dash-grid-card__left-col__link-list-gap: 10px;
  --dash-grid-card__right-col__link-list-gap: 6px;

  /* Column agnostic variables to use in components, defaults to Left Col */
  --dash-grid-card__padding-top: var(--dash-grid-card__left-col__padding-top);
  --dash-grid-card__padding-right: var(
    --dash-grid-card__left-col__padding-right
  );
  --dash-grid-card__padding-bottom: var(
    --dash-grid-card__left-col__padding-bottom
  );
  --dash-grid-card__padding-left: var(--dash-grid-card__left-col__padding-left);

  --dash-grid-card__header-padding-left: var(
    --dash-grid-card__left-col__header-padding-left
  );
  --dash-grid-card__header-padding-right: var(
    --dash-grid-card__left-col__header-padding-right
  );
}

.gridContainer.augustLayout {
  --dash-grid__col_gap: var(--dig-spacing__macro__medium);
  --dash-grid__row_gap: var(--dig-spacing__macro__large);
  --dash-grid__max-width: 1100px;
  --dash-grid__sidebar-min-width: 280px;
  --dash-grid__sidebar-max-width: 340px;
  --dash-grid-card__row_gap: var(--dig-spacing__micro__large);
  row-gap: var(--dash-grid-card__row_gap);
}

.columnGroup {
  display: flex;
  gap: var(--dash-grid__col_gap);
  flex-direction: row-reverse;
  width: 100%;
}

.singleColumnGroup {
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
}

.columnGap {
  column-gap: var(--dash-grid__col_gap);
  row-gap: var(--dash-grid-card__row_gap);
}

.column.singleColumn {
  min-width: 0;
  width: auto;
  max-width: unset;

  --dash-link-list__list__gap: var(--dash-grid-card__left-col__link-list-gap);
}

.headerRow {
  display: flex;
  flex-direction: column;
  column-gap: var(--dash-grid__col_gap);
  row-gap: var(--dash-grid__row_gap);
}

.sidebarColumn {
  min-width: var(--dash-grid__sidebar-min-width);
  max-width: var(--dash-grid__sidebar-max-width);
  /* Force the left column to be as wide as possible */
  width: var(--dash-grid__max-width);
  flex-shrink: 65;

  --dash-link-list__list__gap: var(--dash-grid-card__left-col__link-list-gap);
}

.mainColumn {
  min-width: 0;
  /* Force the right column to be as wide as possible */
  width: var(--dash-grid__max-width);
  flex-shrink: 35;

  /* Card overrides for right column */
  --dash-grid-card__padding-top: var(--dash-grid-card__right-col__padding-top);
  --dash-grid-card__padding-right: var(
    --dash-grid-card__right-col__padding-right
  );
  --dash-grid-card__padding-bottom: var(
    --dash-grid-card__right-col__padding-bottom
  );
  --dash-grid-card__padding-left: var(
    --dash-grid-card__right-col__padding-left
  );

  --dash-grid-card__header-padding-left: var(
    --dash-grid-card__right-col__header-padding-left
  );
  --dash-grid-card__header-padding-right: var(
    --dash-grid-card__right-col__header-padding-right
  );

  /* Link overrides for right column */
  --dash-link-list__link__padding-top: var(
    --dash-grid-card__right-col__link-padding-top
  );
  --dash-link-list__link__padding-right: var(
    --dash-grid-card__right-col__link-padding-right
  );
  --dash-link-list__link__padding-bottom: var(
    --dash-grid-card__right-col__link-padding-bottom
  );
  --dash-link-list__link__padding-left: var(
    --dash-grid-card__right-col__link-padding-left
  );

  --dash-link-list__list__gap: var(--dash-grid-card__right-col__link-list-gap);
}

.sidebarColumnSticky {
  display: inherit;
  flex-direction: inherit;
  gap: inherit;
  row-gap: inherit;
  max-height: inherit;
}

@media screen and (min-width: 888px) {
  .sidebarColumnSticky {
    position: fixed;
    width: var(--dash-grid__sidebar-sticky-width);
    max-width: var(--dash-grid__sidebar-max-width);
  }
}

@media screen and (min-width: 848px) {
  .sidebarColumnSticky.isDesktop {
    position: fixed;
    width: var(--dash-grid__sidebar-sticky-width);
    max-width: var(--dash-grid__sidebar-max-width);
  }
}
