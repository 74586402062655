.container {
  display: flex;
  justify-content: center;
  padding: var(--dig-spacing__micro__small) var(--dig-spacing__micro__large);
  align-items: flex-end;
  gap: var(--dig-type__size__text__xsmall);
  align-self: stretch;
  height: 32px;
}

.withBorder {
  border-top: 1px solid var(--dig-color__border__subtle);
}
