.rightIcon {
  color: var(--dig-color__text__base);
}

.suggestionPreviewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing__unit--1);
  flex-grow: 1;
}

.suggestionPreviewContainerLoading {
  gap: calc(var(--spacing__unit--1) + 3px);
}
