.resultPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: var(--spacing__unit--2) var(--spacing__unit--3);
  width: 100%;
}

.resultPageContainer.isMobile {
  padding: var(--spacing__unit--2);
}
