/**
 * Note that this file needs to be '.module.css', and not just '.css', so that
 * it will work with the electron esbuild.
 *
 * If named '*.css', then we will get this error when building hornet:
 * Two output files share the same path but have different contents: dist/renderer/index.css
 */

/* Works on Firefox */
* {
  transition:
    scrollbar-color 200ms,
    border-top-color 200ms,
    border-bottom-color 200ms;

  /** Firefox */
  scrollbar-color: transparent transparent;

  /** Webkit Thumb */
  border-top-color: transparent;

  /** Webkit Track and corner */
  border-bottom-color: transparent;
}

:hover,
:focus-within {
  scrollbar-color: var(--dig-color__border__base) transparent;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    background-color: unset;
    border-top-color: var(--dig-color__border__base);
    border-bottom-color: transparent;
  }
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;

  /* Hide when not focused */
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border-top: 10000vh solid transparent;
  border-top-color: inherit;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  border-bottom: 10000vh solid transparent;
  border-bottom-color: inherit;
}

*::-webkit-scrollbar-corner {
  border-bottom: 10000vh solid transparent;
  border-bottom-color: inherit;
}
