.pageContainer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(155deg, #1a191899 60%, #1cb0e299),
    linear-gradient(200deg, #1a191899 60%, #f80bdd99 100%),
    linear-gradient(190deg, #1a191899 70%, #0025a899),
    linear-gradient(100deg, #1a191899 80%, #f80bdd99);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing__unit--4);
  box-sizing: border-box;
}

.mainGrid {
  display: grid;
  row-gap: 20px;
  column-gap: 10px;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.mainGrid > *:first-child {
  grid-column: 1 / span 2;
}

.textInput {
  width: 100px;
}

.urlInput {
  width: 300px;
}
