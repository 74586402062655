.noContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--dig-radius__large, 12px);
  border: 1px dashed var(--dig-color__border__subtle, rgba(0, 0, 0, 0.14));
  padding: var(--dig-spacing__macro__medium) 0px;
  gap: var(--dig-spacing__micro__medium);
}

.iconContainer {
  width: 56px;
  height: 56px;
}
