.coldStartBanner {
  align-items: center;
}

.coldStartBanner > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.bannerContents {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
}

.bannerContents > button {
  flex: 0 0 140px;
}
