@keyframes fadeInFrames {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  /* Note that there is no way to get the right value of animation-duration
     because it depends on the speed of the user's device. 0.5s is a good
     middle ground for something not too fast (no visible transition), and
     not too slow (frustrates user with delay). */
  animation: fadeInFrames 0.5s var(--easing__transition);
}
