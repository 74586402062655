.modalTextInputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: var(--spacing__unit--1);
}

.modalTextInput {
  min-height: 34px;
}
