.searchContainer {
  max-width: var(--dash-typeahead__max-width);
  width: 100%;
  min-width: 160px;
  pointer-events: auto;
  border-color: rgb(0, 0, 0, 0);
}

.searchContainer.augustRevision {
  position: sticky;
  top: 0;
  z-index: 99;
}

.searchContainer.fullWidthSearchbar {
  max-width: none;
  padding: 0 var(--spacing__unit--1);
  width: 100%;
  box-sizing: border-box;

  background-color: var(--dig-color__background__base);
}

.searchContainer.fullWidthSearchbar.isDualModeLauncher {
  padding: 0 0 10px;
}

.searchContainer.typeaheadOpen {
  /* This is just enough to cover the right side drawer */
  z-index: 155;
}

.container.showBackground,
.searchContainer.showBackground,
.searchDesktopSpacer.showBackground {
  background-color: var(--dig-color__primary__surface);
}

.searchDesktopSpacer {
  background-color: var(--dig-color__background__base);
  min-height: 10px;
}

.scrollingBorder {
  border-bottom: 0.5px solid var(--dig-color__border__subtle);
  transition: border-color 0.5s;
}
