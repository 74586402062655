.ConversationMessagesScroller {
  flex: 1 1 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 18px 20px 0px 20px;
  width: 100%;
  box-sizing: border-box;
}

.ConversationMessagesScrollerCondensed {
  padding: 18px 20px 0px 16px;
}

.ConversationMessages {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 720px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: var(--dig-spacing__macro__medium);
}

.LoadingMessageRow {
  border-radius: var(--dig-radius__large);
  display: flex;
  gap: 12px;
  width: max-content;
  color: var(--dig-color__text__base);
}

.ConversationMessageRow {
  margin: 6px 0;
  animation: bubbleAppear 0.4s ease forwards;
}

.ConversationMessageRowCondensed {
  max-width: 100%;
}

.ConversationMessageRowUser {
  align-self: flex-end;
  border-radius: var(--dig-radius__xlarge);
  background: var(--dig-color__opacity__surface);
  max-width: 75%;
  padding: 8px 16px;
  overflow: scroll;
}

.ConversationMessageSourcesUser {
  text-align: right;
}

.ConversationMessageSourceRow {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__large);
  background-color: var(--dig-color__background__base);
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__small)
    var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large);
  width: 272px;
  gap: 12px;
}

.ConversationMessageSourcePadBottom {
  margin-bottom: var(--dig-spacing__micro__large);
}

.ConversationMessageTitle {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: fit-content;
}

.ConversationMessageSourceInfo {
  overflow: hidden;
  flex: 1;
  margin-left: 0;
}

.ConversationMessageSourceMetadata {
  display: flex;
  align-items: center;
  min-width: 100%;
  width: 0;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.ConversationMessageSourceMetadata > div {
  display: flex;
  align-items: center;
  position: relative;
}

.ConversationMessageSourceMetadata > div:not(:last-child)::after {
  content: '·';
  margin-left: var(--spacing__unit--0_5);
  margin-right: var(--spacing__unit--0_5);
}

.ComposeSourceActionButton {
  width: 24px;
  height: 24px;
  visibility: hidden;
}

.ConversationMessageSourceRow:hover .ComposeSourceActionButton {
  visibility: visible;
}

.ConversationMessageSourceTitle {
  min-width: 0;
  flex: 1 1 auto;
}

.ConversationMessageRowMessageActionText {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  transition: all 0.3s ease;
}

.ConversationMessageRowMessageActionIconContainer {
  background: var(--dig-color__background__base);
  border-radius: 50%;
  width: 24px;
  border: 1px solid var(--dig-color__border__subtle);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.ConversationMessageRowMessageActionIconContainerDone {
  background: var(--dig-color__primary-cta__base);
}

.ConversationMessageRowMessageActionIconEmptyContainer {
  width: 26px;
  height: 26px;
  flex: 0 0 auto;
}

.ConversationMessageRowMessageActionIcon {
  flex: 0 0 auto;
  color: var(--dig-color__text__subtle);
}

.ConversationMessageRowMessageActionIconDone {
  color: var(--dig-color__primary__on-base);
}

.ConversationMessageRowPlainText {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ConversationMessageRowMarkdown {
  margin-top: -6px;
}

@keyframes bubbleAppear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ConversationMessageRowMessageAnimation {
  opacity: 0;
  animation: bubbleAppear 0.4s ease forwards;
}

.SearchQueryChip,
.SearchResultChip {
  margin-right: 8px;
  margin-bottom: 8px;
  height: 28px;
  padding: 0px 12px;
}

.ChipContent {
  color: var(--dig-color__text__subtle);
  font-size: var(--dig-type__size__text__small);
  font-style: normal;
  font-weight: 400;
  line-height: var(--dig-type__lineheight__label__small);
}

.SearchResultChipIcon {
  transform: scale(0.8);
  width: 16px;
  margin-left: -4px;
}

.ConversationMessageRowSubMessageTitle {
  margin-bottom: 8px;
}

.ConversationMessageRowSubMessageTitleSearch {
  margin-top: 8px;
}

.ComposeSelectionEditContext {
  gap: 8px;
  margin-top: 8px;
  /* TODO:(rich) enable this once the UI is ready */
  display: none;
}

.ComposeSelectionEditContextIcon {
  flex: 0 0 auto;
  color: var(--dig-color__text__subtle);
}
