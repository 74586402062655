.styledText {
  word-break: break-word;
  width: 100%;
  white-space: break-spaces;
  word-wrap: break-word;
}

.container,
.spinnerContainer {
  display: flex;
  flex-direction: column;
}

.feedbackContainer {
  margin-top: var(--spacing__unit--1);
  padding: 0 3px 3px 3px;
}

.message,
.spinnerMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--spacing__unit--2);
  padding: var(--spacing__unit--2);
  flex: 1 0 0;

  border-radius: var(--spacing__unit--1_5) var(--spacing__unit--1_5)
    var(--spacing__unit--1_5) 2px;
  border: 1px solid var(--dig-color__border__subtle, rgba(0, 0, 0, 0.14));
  background-color: var(--dig-color__background__base);
}

.spinnerContainer {
  align-items: flex-start;
}

.spinnerMessage {
  padding: 7px;
}

.messageText p {
  margin: 0;
}
