.box {
  /* Please sync this value with AddStackItemMenuContent.tsx */
  height: 262px;
}

.loadingSuggestionRow {
  height: calc(48px - var(--add-item-focus-ring-border-size));
  margin: var(--add-item-focus-ring-border-size-half) 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 14px;
  gap: 13px;
}

.emptyIconWrapper {
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
}

.emptyIcon {
  width: 24px;
  height: 23px;
  border-radius: 2px;
}

.loadingRectangleWrapper {
  display: flex;
  align-items: center;
}

.loadingRectangle {
  width: 253px;
  height: 12px;
  border-radius: 6px;
}
