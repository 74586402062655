.keycapContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.keycapContainer.combinedKeys {
  gap: unset;
  border-radius: var(--dig-spacing__dimension__4);
  min-width: var(--dig-spacing__dimension__16);
  min-height: var(--dig-spacing__dimension__20);
  border: 1px solid var(--dig-color__border__subtle);
  padding: 0px var(--dig-spacing__dimension__4);

  color: var(--color__faint__text);
  background-color: var(--color__elevated__background);
  border-color: var(--color__faint__border);
}

.privateButtonOverride > span {
  padding: 0;
}
