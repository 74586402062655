.addRow {
  display: flex;
  gap: var(--spacing__unit--1_5);
  align-items: center;
  cursor: pointer;
  margin: var(--spacing__unit--1_5) 0;
}

.URLInputRow {
  display: flex;
  gap: 8px;
  margin: var(--spacing__unit--1) 0;
}
