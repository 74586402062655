.title {
  margin: 0;
}

.dropdown {
  /* TODO: style this element */
  padding: 10px 0;
  display: flex;
  height: 40px;
  padding: var(--spacing__unit--2);
  align-items: flex-start;
  align-self: stretch;
}

.dropdownItem {
  display: flex;
  padding: 0 var(--spacing__unit--2) 0 var(--spacing__unit--1);
  align-items: center;
  align-self: stretch;
}

.answerButtonHorizontalContainer {
  display: flex;
  padding: var(--spacing__unit--1_5) var(--spacing__unit--3);
  align-items: center;
  gap: var(--dig-spacing__micro__large);
}

.answerButton {
  height: 48px;
}

.answerButton > input {
  position: absolute;
  opacity: 0;
}

.selectedAnswerColor {
  color: var(--dig-color__primary__base);
}

.answerButtonLabel {
  display: flex;
  align-self: center;
  gap: var(--dig-spacing__micro__medium);
  height: 50px;
  border-radius: 16px;
  border: 1px solid var(--dig-color__border__subtle);
  background: #fff;
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__macro__small);
}

.answerButtonLabel:hover {
  border: 1px solid var(--dig-color__border__bold);
}

.answerButton > input:checked + label {
  border: 1px solid var(--dig-color__primary__base);
  background: var(--dig-color__primary__on-base);
}

.answerButtonIcon {
  width: 24px;
  height: 24px;
}

/* step 3 styles */

.featureNumberBox {
  width: 48px;
  height: 48px;
}

.rightPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 66px;
}
