.answerBoxContainer {
  transition: height 1s;
  padding: 0 var(--dig-spacing__micro__xsmall);
  width: 100%;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-1em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.outerWrapper {
  width: 100%;
  max-width: 100%;
  word-break: break-word;
  display: flex;
}

.outerWrapper.smoothInText {
  animation: fadeInDown 0.5s linear;
}

.answerBoxLabel {
  padding: var(--spacing__unit--1) 0;
  user-select: text;
}
