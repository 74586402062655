.modalBody {
  padding: var(--spacing__unit--2);
}

.titleRow {
  margin: 0 var(--spacing__unit--1) 0 var(--spacing__unit--2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 14px;
  margin: 0;
}

.closeButtonWrapper > * {
  border: none;
}

.line {
  margin: var(--spacing__unit--2);
  border: 0.5px solid var(--dig-color__border__subtle);
}

.configRows {
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1);
}

.configRow {
  padding: var(--spacing__unit--1) var(--spacing__unit--2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 54px;
  cursor: move;
}

.configRow.loading {
  justify-content: center;
}

.configRow:hover,
.isDragging .configRow {
  background-color: var(--dig-color__opacity__surface);
  border-radius: var(--spacing__unit--1);
}

.isDragging {
  /* Dragged element might go out of position */
  top: auto !important;
  left: auto !important;
}

.icon {
  box-sizing: border-box;
  width: 30px;
  min-width: 30px; /* stretch it */
  height: 30px;
  padding: 6px;
  border-radius: var(--spacing__unit--1);
  background-color: var(--dig-color__secondary__surface);
  filter: grayscale(1);
}

.configLabel {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 var(--spacing__unit--2_5);
}

.toggleWrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing__unit--1);
}

.toggleLabel {
  font-weight: normal;
  cursor: pointer;
}

.toggleLabel.disabled {
  color: var(--dig-color__text__subtle);
  cursor: default;
}
