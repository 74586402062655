.paramContainer {
  align-items: center;
  background: var(--dig-color__background__subtle);
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 0px var(--dig-spacing__micro__small);
  border-radius: var(--dig-radius__medium);
}

.selectedContainer {
  background: var(--dig-color__background__base);
}

.paramContainer.matched {
  font-weight: bold;
}
