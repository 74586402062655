.header {
  text-align: center;
  margin-bottom: var(--spacing__unit--2);
}

.subHeader {
  margin-bottom: var(--spacing__unit--5);
  font-family: 'Atlas Grotesk Web';
  text-align: center;
}

.actionButton {
  border-radius: var(
    --dig-radius__xlarge
  ) !important /* important is required in order to override dig overrides */;
}

.actionButtonLabel {
  padding-left: var(--spacing__unit--2);
  padding-right: var(--spacing__unit--2);
}
