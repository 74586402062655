.wrapper {
  --create-stack-row-height: 64px;
  --small-box-side: 240px;
  --gap: var(--spacing__unit--2);

  max-width: var(--max-width);
  min-width: var(--small-box-side);
}

.wrapperMarch {
  --small-box-side: 294px;
  --gap: var(--spacing__unit--1_5);

  max-width: min(1080px, 100%);
}

.grid {
  gap: var(--gap);
}

.grid {
  display: grid;
  grid-template-rows: var(--create-stack-row-height) var(--small-box-side);
  grid-template-columns: repeat(3, var(--small-box-side));
}

.onlyCardGrid .grid {
  grid-template-rows: var(--small-box-side);
}

.twoColumns .grid {
  grid-template-columns: repeat(2, var(--small-box-side));
}

.oneColumn .grid {
  max-width: 380px;
  width: calc(100vw - var(--spacing__unit--4));
  display: flex;
  flex-direction: column;
  grid-template-columns: var(--small-box-side);
}

.controlBar {
  height: var(--create-stack-row-height);
  grid-column: span 3;
}

.twoColumns .controlBar {
  grid-column: span 2;
}

.oneColumn .controlBar {
  grid-column: span 1;
}

.emptyState {
  grid-row: span 30;
  grid-column: span 3;
}

.twoColumns .emptyState {
  grid-column: span 2;
}

.oneColumn .emptyState {
  grid-column: span 1;
}
