.cardContainer {
  box-sizing: border-box;
  padding: var(--dash-grid-card__padding-top)
    var(--dash-grid-card__padding-right) var(--dash-grid-card__padding-bottom)
    var(--dash-grid-card__padding-left);
  background-color: var(--dig-color__background__subtle);
  display: flex;
  flex-direction: column;
  gap: var(--spacing__unit--1_5);
  border-radius: var(--dash-radius__component);
}

.themedCardContainer {
  background-color: var(
    --dig-color__primary__surface,
    --dig-color__background__subtle
  );
}

.cardContainer.cardCollapsed {
  /* When collapsed, use the same value for top/bottom */
  padding-top: var(--dash-grid-card__padding-top);
  padding-bottom: var(--dash-grid-card__padding-top);
}

.cardContainer > * {
  --dash-radius__component: var(--dash-radius__level-2);
}

/* If no header is present, then render padding-top with the same
 * value as padding-bottom to present an even gap
 */
.cardContainer.noHeader {
  padding-top: var(--dash-grid-card__padding-bottom);
}

.divider {
  height: 1px;
  background-color: var(--dig-color__border__subtle);
  margin-left: var(--dash-grid-card__header-padding-left);
  margin-right: var(--dash-grid-card__header-padding-right);
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--dash-grid-card__header-padding-left);
  padding-right: var(--dash-grid-card__header-padding-right);
}

.headerContainer > * {
  flex-shrink: 0;
}

.titleContainer {
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
  margin-right: var(--spacing__unit--1);
  overflow: hidden;
  flex-shrink: 1;
}

.titleContainer [class^='dig']:where(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.primaryTitle {
  overflow: hidden;
}

.titleSizeXSmall {
  font-size: var(--dig-type__size__text__medium);
}

.exampleCardContainer {
  height: 200px;
}

.collapseBtn svg {
  transform: rotate(0deg);
}

.expandBtn svg {
  transform: rotate(180deg);
}

.actionsContainer {
  display: flex;
  gap: 10px;
}
