@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popUp {
  0% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

.contentContainer {
  width: 100%;
}

.loadingResultcontainer {
  animation-name: popUp, fadeIn;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.01, 0, 0, 1), linear;
  animation-fill-mode: both;
}

.headerContainer {
  display: flex;
  gap: 20px;
}

.headerContainer.desktop {
  gap: 12px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  margin-left: 68px;
}

.textContainer.desktop {
  margin-top: 12px;
  margin-left: 44px;
}
