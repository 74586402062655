.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  margin-left: -2px;
  color: var(--dig-color__text__base);
}

.iconContainerOffsetPosition {
  align-self: flex-start;
  margin-top: var(--dig-spacing__micro__small);
}

.title {
  margin-left: 0px;
  white-space: nowrap;
}

.rowContentContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
