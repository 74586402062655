.styledSegmentedControl {
  margin: 0 16px;
  gap: 8px;
}

.styledSegmentedControl > button {
  border-radius: 8px;
  cursor: default;
}

.styledSegmentedControl > span {
  border-radius: 8px;
}
