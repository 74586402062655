.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--dig-spacing__macro__medium);
  margin: var(--spacing__unit--3) 0;
  padding: 0 var(--spacing__unit--2);
  box-sizing: border-box;
}

.cannedResultRow {
  display: flex;
  align-items: center;
  padding: var(--spacing-unit-15, 12px) var(--spacing__unit--3);
  gap: var(--spacing__unit--2);
  width: 100%;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
}

.cannedResultRow:hover {
  background-color: var(--dig-color__opacity__surface);
  cursor: pointer;
}

.moreActions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing__unit--1);
}
