.iconText {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-left: 5px;
}

/* This forces the warning icon to be red instead of default yellow, which is not modifiable via DIG params */
.icon > path:first-child {
  fill: var(--dig-color__alert__base);
}

.listItem {
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
}

.listItem:hover {
  border-color: var(--color__faint__border);
  box-shadow: var(--boxshadow__elevation1);
}

.flexCenter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing__unit--2);
}

.iconExtraMargin {
  margin-right: var(--spacing__unit--1);
}

button.onboardingVariantAddButton {
  background: var(--dig-color__primary-cta__base);
  border-radius: 50%;
  color: var(--dig-color__background__base);
}

.onboardingVariantListItem {
  border: 1px solid transparent;
  margin: var(--dig-spacing__micro__small) 0;
  cursor: pointer;
}

.onboardingVariantListItem:hover {
  border-radius: var(--dig-spacing__micro__xsmall);
  border: 1px solid var(--dig-color__opacity__surface--state-1);
}

.connectedSubtitle {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);
}

.connectedText {
  /* Need to push text down to make it look center-aligned. */
  transform: translateY(1px);
}

.inputRow {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
}

.successColor {
  color: var(--dig-color__success__base--state-1);
}

.errorColor {
  color: var(--dig-color__alert__base--state-1);
}

.connectionErrors {
  font-weight: bold;
  font-size: large;
  color: var(--dig-color__alert__base--state-1);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
  width: 100%;
}

.modal {
  padding: 12px;
}

.header {
  margin-bottom: 48px;
}
