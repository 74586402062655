.container {
  border-top: 1px solid var(--dig-color__border__subtle);
  padding: var(--dig-spacing__micro__small) var(--dig-spacing__micro__xlarge);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--dig-spacing__macro__small);
}

.text {
  color: var(--dig-color__text__subtle);
  line-height: var(--dig-type__lineheight__text__large);
}

.shortcut {
  display: flex;
  gap: var(--dig-spacing__micro__small);
}

.container .left {
  margin-right: auto;
}
