/* stylelint-disable selector-class-pattern */
.root {
  /**
   * Since we default the size to "xlarge" these values are the default for the xlarge size of icon.
   */
  --layout-inner-dimension: 24px;
  --layout-radius: var(--dig-radius__small);
  --layout-grid-template: minmax(0, 1fr) var(--layout-inner-dimension)
    minmax(0, 1fr);
  --layout-border-color: var(--dig-color__border__subtle);

  width: var(--layout-outer-dimension);
  height: var(--layout-outer-dimension);
  border-radius: var(--layout-radius);
  display: grid;
  grid-template:
    "tl t tr"
    "ml m mr"
    "bl b br";
  grid-template-rows: var(--layout-grid-template);
  grid-template-columns: var(--layout-grid-template);
}

.root-shape-variant-circle.root {
  --layout-radius: var(--dig-radius__circular);
}

.root-has-border {
  box-shadow: inset 0 0 0 1px var(--layout-border-color);
}

.root-hover-effect {
  grid-area: tl-start / ml-start / br-end / mr-end;
  width: 100%;
  height: 100%;
  border-radius: var(--layout-radius);
}

.root-size-xlarge {
  --layout-badge-offset: 6px;
  --layout-outer-dimension: 48px;
}

.root-size-large {
  --layout-outer-dimension: 40px;
  --layout-badge-offset: 8px;
}

.root-size-medium {
  --layout-outer-dimension: 32px;
  --layout-inner-dimension: 20px;
  --layout-radius: var(--dig-radius__xsmall);
  --layout-badge-offset: 8px;
}

.root-size-small {
  --layout-outer-dimension: 24px;
  --layout-inner-dimension: 16px;
  --layout-radius: var(--dig-radius__xsmall);
}

.content {
  width: 100%;
  height: 100%;
  grid-area: m;
  display: grid;
  place-items: center;
}

/**
 * Used to remove the inner constraint of the icon layout.
 */
.content-remove-inner-constraint.content {
  grid-area: 1 / 1 / -1 / -1;
}

/* Double specificity here incase we need to beat any other 0 1 0 selectors */
.content.content > :is(img, svg) {
  aspect-ratio: 1;
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}
