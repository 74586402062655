.logoDragDrop {
  margin-bottom: var(--dig-spacing__micro__xlarge);
  gap: var(--dig-spacing__micro__large);
  cursor: pointer;
}

.placeholderImage {
  background-color: var(--dig-color__secondary__surface);
  color: var(--dig-color__text__subtle);
  border-radius: var(--dig-radius__large);
  height: 130px;
  transition: all var(--duration__surface) var(--easing__transition);
}

.logoDragDropActive .placeholderImage {
  background-color: var(--dig-color__secondary__surface--state-2);
}

.companyLogo,
.placeholderImage {
  width: 130px;
}

.logoDragDropLabel {
  color: var(--dig-color__text__subtle);
}

.fileInput {
  display: none;
}
