.subtitleContainer {
  display: flex;
  align-items: center;
}

.metadata {
  display: flex;
  align-items: center;
}

.subtitleContainer > *:not(:last-child)::after {
  content: '·';
  margin: 0 4px;
}
