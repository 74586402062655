@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  width: 100%;
}

.container {
  border-radius: var(--dash-radius__component);
  cursor: pointer;
  transition: 100ms ease background-color;
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.3s ease-out;
}

.openContainer,
.container:hover {
  background-color: var(--dig-color__secondary__surface);
}

.openContainer {
  transition: max-height 0.3s ease-out;
  max-height: 100%;
  width: 100%;
}

.checkmarkIcon {
  color: var(--dig-color__primary__base);
  margin-right: var(--spacing__unit--1);
  display: flex;
  justify-self: left;
  align-items: center;
  height: 100%;
}

.titleContainer {
  align-items: center;
  height: 48px;
  padding: var(--spacing__unit--1_5);
}

.title {
  flex: 1 1 auto;
}

.drawer {
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.drawerOpen {
  max-height: 136px;
  transition: max-height 0.3s ease-out;
  gap: var(--spacing__unit--2);
}

.actionsContainer {
  display: none;
  padding-bottom: var(--spacing__unit--2);
}

.drawerOpen .actionsContainer {
  animation: fadeIn 0.4s ease-out forwards;
  display: inline-block;
}

.message,
.actionsContainer {
  padding-left: 43px;
}

.message {
  padding-right: 36px;
}

.primaryButton {
  margin-right: var(--spacing__unit--1);
}

.chevronComplete {
  color: var(--dig-color__text__subtle);
}

/* Shrink content for mobile */
@media (max-width: 599px) {
  .message,
  .title {
    font-size: var(--dig-type__size__text__xsmall);
  }
  .drawerOpen {
    gap: var(--spacing__unit--1);
    max-height: max-content;
  }
  .drawerOpen .actionsContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing__unit--1);
    padding-right: var(--spacing__unit--2);
  }
  .drawerOpen .primaryButton {
    margin-right: 0;
  }
}
